import moment from "moment";
import uniqBy from "lodash/uniqBy";
import capitalize from "lodash/capitalize";

// Libs
import i18n from "@biuwer/core/src/i18n";
import { checkQueryFieldTypes } from "@biuwer/core/src/query-system/get-data-card-queries-metadata";

// Default Date and DateTime values and formats
export const defaultDate = `${new Date().getFullYear()}0130`;
export const defaultDateTime = `${new Date().getFullYear()}0130 14:30:15`;
export const defaultTime = `14:30:15`;

export const defaultDateFormat = `YYYYMMDD`;
export const defaultDateTimeFormat = `YYYYMMDD HH:mm:ss`;
export const defaultTimeFormat = 'HH:mm:ss';

export const dayHoursOptions = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    .map(value => ({ label: value, value }));

export const DEFAULT_DATE_LEVEL = "full_date";
export const DEFAULT_DATE_TIME_LEVEL = "full_date_time";

export const getDateLevelFilterOptions = (t = i18n.t, i18nOptions = {}) => [
    { _id: 1, name: t('filters.dates.year', i18nOptions), value: 'year', date_mask: 'YYYY', regex: /^\d{4}$/ },
    { _id: 2, name: t('filters.dates.quarter', i18nOptions), value: 'quarter', date_mask: 'YYYY-Q', regex: /^\d{4}-([1-4])$/ },
    { _id: 3, name: t('filters.dates.month', i18nOptions), value: 'month', date_mask: 'YYYY-MM', regex: /^\d{4}-(0[1-9]|1[012])$/ },
    { _id: 3, name: t('filters.dates.week', i18nOptions), value: 'week', date_mask: 'YYYY-WW', regex: /^\d{4}-(0[1-9]|1[053])$/ },
    { _id: 4, name: t('filters.dates.day', i18nOptions), value: 'day', date_mask: 'YYYY-MM-DD', regex: /(^(((\d\d)(([02468][048])|([13579][26]))-02-29)|(((\d\d)(\d\d)))-((((0\d)|(1[0-2]))-((0\d)|(1\d)|(2[0-8])))|((((0[13578])|(1[02]))-31)|(((0[1,3-9])|(1[0-2]))-(29|30)))))$)/ },
    { _id: 5, name: t('filters.dates.hourOfDay', i18nOptions), value: 'hour_of_day', date_mask: 'HH', regex: /^\d{2}$/ }
];

export const getDateLevelOptions = (t = i18n.t, i18nOptions = {}) => [
    { _id: 1, name: i18n.t('datasets.dateLevels.year', i18nOptions), value: 'year'},
    { _id: 2, name: i18n.t('datasets.dateLevels.quarter', i18nOptions), value: 'quarter'},
    { _id: 3, name: i18n.t('datasets.dateLevels.month', i18nOptions), value: 'month'},
    { _id: 4, name: i18n.t('datasets.dateLevels.week', i18nOptions), value: 'week'},
    { _id: 5, name: i18n.t('datasets.dateLevels.yearQuarter', i18nOptions), value: 'year_quarter'},
    { _id: 5, name: i18n.t('datasets.dateLevels.yearMonth', i18nOptions), value: 'year_month'},
    { _id: 6, name: i18n.t('datasets.dateLevels.yearWeek', i18nOptions), value: 'year_week'},
    { _id: 7, name: i18n.t('datasets.dateLevels.fullDate', i18nOptions), value: 'full_date'},
    { _id: 8, name: i18n.t('datasets.dateLevels.fullDateTime', i18nOptions), value: 'full_date_time'},
    { _id: 9, name: i18n.t('datasets.dateLevels.hourOfDay', i18nOptions), value: 'hour_of_day'  }
];

export const getDateYearFilterOptions = (t = i18n.t) => [
    {_id: 1, name: t('filters.dates.thisYear'), value: 'thisYear'},
    {_id: 2, name: t('filters.dates.previousYear'), value: 'previousYear'},
    {_id: 3, name: t('filters.dates.yearToDate'), value: 'yearToDate'},
    {_id: 4, name: t('filters.dates.previousTwoYears'), value: 'previousTwoYears'},
    {_id: 5, name: t('filters.dates.twoYearsAgo'), value: 'twoYearsAgo'},
    {_id: 6, name: t('filters.dates.lastTwoYears'), value: 'lastTwoYears'},
    {_id: 7, name: t('filters.dates.lastThreeYears'), value: 'lastThreeYears'}
];

export const getDateQuarterFilterOptions = (t = i18n.t) => [
    { _id: 1, name: t('filters.dates.thisQuarter'), value: 'thisQuarter' },
    { _id: 2, name: t('filters.dates.previousQuarter'), value: 'previousQuarter' },
    { _id: 3, name: t('filters.dates.quarterToDate'), value: 'quarterToDate' },
    { _id: 4, name: t('filters.dates.previousTwoQuarters'), value: 'previousTwoQuarters' },
    { _id: 5, name: t('filters.dates.twoQuartersAgo'), value: 'twoQuartersAgo' },
    { _id: 6, name: t('filters.dates.threeQuartersAgo'), value: 'threeQuartersAgo' },
    { _id: 7, name: t('filters.dates.lastTwoQuarters'), value: 'lastTwoQuarters' },
    { _id: 8, name: t('filters.dates.lastThreeQuarters'), value: 'lastThreeQuarters' },
    { _id: 9, name: t('filters.dates.lastFourQuarters'), value: 'lastFourQuarters' }
];

export const getDateMonthFilterOptions = (t = i18n.t) => [
    { _id: 1, name: t('filters.dates.thisMonth'), value: 'thisMonth' },
    { _id: 2, name: t('filters.dates.previousMonth'), value: 'previousMonth' },
    { _id: 3, name: t('filters.dates.monthToDate'), value: 'monthToDate' },
    { _id: 4, name: t('filters.dates.previousTwoMonths'), value: 'previousTwoMonths' },
    { _id: 5, name: t('filters.dates.twoMonthsAgo'), value: 'twoMonthsAgo' },
    { _id: 6, name: t('filters.dates.threeMonthsAgo'), value: 'threeMonthsAgo' },
    { _id: 7, name: t('filters.dates.lastTwoMonths'), value: 'lastTwoMonths' },
    { _id: 8, name: t('filters.dates.lastThreeMonths'), value: 'lastThreeMonths' },
    { _id: 9, name: t('filters.dates.lastSixMonths'), value: 'lastSixMonths' },
    { _id: 10, name: t('filters.dates.lastTwelveMonths'), value: 'lastTwelveMonths' }
];

export const getDateWeekFilterOptions = (t = i18n.t) => [
    { _id: 1, name: t('filters.dates.thisWeek'), value: 'thisWeek' },
    { _id: 2, name: t('filters.dates.lastWeek'), value: 'lastWeek' },
    { _id: 3, name: t('filters.dates.weekToDate'), value: 'weekToDate' },
    { _id: 4, name: t('filters.dates.previousTwoWeeks'), value: 'previousTwoWeeks' },
    { _id: 5, name: t('filters.dates.twoWeeksAgo'), value: 'twoWeeksAgo' },
    { _id: 6, name: t('filters.dates.threeWeeksAgo'), value: 'threeWeeksAgo' },
    { _id: 7, name: t('filters.dates.lastTwoWeeks'), value: 'lastTwoWeeks' },
    { _id: 8, name: t('filters.dates.lastThreeWeeks'), value: 'lastThreeWeeks' },
    { _id: 9, name: t('filters.dates.lastFourWeeks'), value: 'lastFourWeeks' },
    { _id: 10, name: t('filters.dates.lastSixWeeks'), value: 'lastSixWeeks' },
    { _id: 11, name: t('filters.dates.lastEightWeeks'), value: 'lastEightWeeks' },
    { _id: 12, name: t('filters.dates.lastTwelveWeeks'), value: 'lastTwelveWeeks' }
];

export const getDateDayFilterOptions = (t = i18n.t) => [
    {_id: 1, name: t('filters.dates.today'), value: 'today'},
    {_id: 2, name: t('filters.dates.yesterday'), value: 'yesterday'},
    {_id: 3, name: t('filters.dates.previousTwoDays'), value: 'previousTwoDays'},
    {_id: 4, name: t('filters.dates.twoDaysAgo'), value: 'twoDaysAgo'},
    {_id: 5, name: t('filters.dates.threeDaysAgo'), value: 'threeDaysAgo'},
    {_id: 6, name: t('filters.dates.lastSevenDays'), value: 'lastSevenDays'},
    {_id: 7, name: t('filters.dates.lastFourteenDays'), value: 'lastFourteenDays'},
    {_id: 8, name: t('filters.dates.lastThirtyDays'), value: 'lastThirtyDays'},
    {_id: 9, name: t('filters.dates.lastSixtyDays'), value: 'lastSixtyDays'}
];

export const getDateHourFilterOptions = (t = i18n.t) => [
    { _id: 1, name: t('filters.dates.lastHour'), value: 'lastHour' },
    { _id: 2, name: t('filters.dates.lastTwoHours'), value: 'lastTwoHours' },
    { _id: 3, name: t('filters.dates.lastThreeHours'), value: 'lastThreeHours' },
    { _id: 4, name: t('filters.dates.lastFourHours'), value: 'lastFourHours' },
    { _id: 5, name: t('filters.dates.lastSixHours'), value: 'lastSixHours' },
    { _id: 7, name: t('filters.dates.lastEightHours'), value: 'lastEightHours' },
    { _id: 8, name: t('filters.dates.lastTwelveHours'), value: 'lastTwelveHours' },
    { _id: 9, name: t('filters.dates.lastTwentyFourHours'), value: 'lastTwentyFourHours' }
];

export const getYearFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `YY (${moment(date, dateFormat).format('YY')})`, value: 'YY', date_mask: 'YYYY'},
    { _id: 2, name: `YYYY (${moment(date, dateFormat).format('YYYY')})`, value: 'YYYY', date_mask: 'YYYY'}
];

export const getQuarterFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `Q (${moment(date, dateFormat).format('Q')})`, value: 'Q', date_mask: 'Q'},
    { _id: 2, name: `Qo (${moment(date, dateFormat).format('Qo')})`, value: 'Qo', date_mask: 'Q'}
];

export const getMonthFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `M (${moment(date, dateFormat).format('M')})`, value: 'M', date_mask: 'MM'},
    { _id: 2, name: `Mo (${moment(date, dateFormat).format('Mo')})`, value: 'Mo', date_mask: 'MM'},
    { _id: 3, name: `MM (${moment(date, dateFormat).format('MM')})`, value: 'MM', date_mask: 'MM'},
    { _id: 4, name: `MMM (${moment(date, dateFormat).format('MMM')})`, value: 'MMM', date_mask: 'MM'},
    { _id: 5, name: `MMMM (${moment(date, dateFormat).format('MMMM')})`, value: 'MMMM', date_mask: 'MM'}
];

export const getWeekFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `W (${moment(date, dateFormat).format('W')})`, value: 'W', date_mask: 'WW'},
    { _id: 2, name: `Wo (${moment(date, dateFormat).format('Wo')})`, value: 'Wo', date_mask: 'WW'},
    { _id: 3, name: `WW (${moment(date, dateFormat).format('WW')})`, value: 'WW', date_mask: 'WW'}
];

export const getYearQuarterFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `YY-Q (${moment(date, dateFormat).format('YY-Q')})`, value: 'YY-Q', date_mask: 'YYYY-Q'},
    { _id: 2, name: `Q-YY (${moment(date, dateFormat).format('Q-YY')})`, value: 'Q-YY', date_mask: 'YYYY-Q'},
    { _id: 3, name: `YY/Q (${moment(date, dateFormat).format('YY/Q')})`, value: 'YY/Q', date_mask: 'YYYY-Q'},
    { _id: 4, name: `Q/YY (${moment(date, dateFormat).format('Q/YY')})`, value: 'Q/YY', date_mask: 'YYYY-Q'},

    { _id: 5, name: `YYYY-Q (${moment(date, dateFormat).format('YYYY-Q')})`, value: 'YYYY-Q', date_mask: 'YYYY-Q'},
    { _id: 6, name: `YYYY/Q (${moment(date, dateFormat).format('YYYY/Q')})`, value: 'YYYY/Q', date_mask: 'YYYY-Q'},
    { _id: 7, name: `Q-YYYY (${moment(date, dateFormat).format('Q-YYYY')})`, value: 'Q-YYYY', date_mask: 'YYYY-Q'},
    { _id: 8, name: `Q/YYYY (${moment(date, dateFormat).format('Q/YYYY')})`, value: 'Q/YYYY', date_mask: 'YYYY-Q'},

    { _id: 9, name: `YY-Qo (${moment(date, dateFormat).format('YY-Qo')})`, value: 'YY-Qo', date_mask: 'YYYY-Q'},
    { _id: 10, name: `Qo-YY (${moment(date, dateFormat).format('Qo-YY')})`, value: 'Qo-YY', date_mask: 'YYYY-Q'},
    { _id: 11, name: `YY/Qo (${moment(date, dateFormat).format('YY/Qo')})`, value: 'YY/Qo', date_mask: 'YYYY-Q'},
    { _id: 12, name: `Qo/YY (${moment(date, dateFormat).format('Qo/YY')})`, value: 'Qo/YY', date_mask: 'YYYY-Q'},

    { _id: 13, name: `YYYY-Qo (${moment(date, dateFormat).format('YYYY-Qo')})`, value: 'YYYY-Qo', date_mask: 'YYYY-Q'},
    { _id: 14, name: `Qo-YYYY (${moment(date, dateFormat).format('Qo-YYYY')})`, value: 'Qo-YYYY', date_mask: 'YYYY-Q'},
    { _id: 15, name: `YYYY/Qo (${moment(date, dateFormat).format('YYYY/Qo')})`, value: 'YYYY/Qo', date_mask: 'YYYY-Q'},
    { _id: 16, name: `Qo/YYYY (${moment(date, dateFormat).format('Qo/YYYY')})`, value: 'Qo/YYYY', date_mask: 'YYYY-Q'}
];

export const getYearMonthFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `YY-M (${moment(date, dateFormat).format('YY-M')})`, value: 'YY-M', date_mask: 'YYYY-MM'},
    { _id: 2, name: `YY/M (${moment(date, dateFormat).format('YY/M')})`, value: 'YY/M', date_mask: 'YYYY-MM'},
    { _id: 3, name: `M-YY (${moment(date, dateFormat).format('M-YY')})`, value: 'M-YY', date_mask: 'YYYY-MM'},
    { _id: 4, name: `M/YY (${moment(date, dateFormat).format('M/YY')})`, value: 'M/YY', date_mask: 'YYYY-MM'},

    { _id: 5, name: `YYYY-M (${moment(date, dateFormat).format('YYYY-M')})`, value: 'YYYY-M', date_mask: 'YYYY-MM'},
    { _id: 6, name: `YYYY/M (${moment(date, dateFormat).format('YYYY/M')})`, value: 'YYYY/M', date_mask: 'YYYY-MM'},
    { _id: 7, name: `M-YYYY (${moment(date, dateFormat).format('M-YYYY')})`, value: 'M-YYYY', date_mask: 'YYYY-MM'},
    { _id: 8, name: `M/YYYY (${moment(date, dateFormat).format('M/YYYY')})`, value: 'M/YYYY', date_mask: 'YYYY-MM'},

    { _id: 9, name: `YY-Mo (${moment(date, dateFormat).format('YY-Mo')})`, value: 'YY-Mo', date_mask: 'YYYY-MM'},
    { _id: 10, name: `YY/Mo (${moment(date, dateFormat).format('YY/Mo')})`, value: 'YY/Mo', date_mask: 'YYYY-MM'},
    { _id: 11, name: `Mo-YY (${moment(date, dateFormat).format('Mo-YY')})`, value: 'Mo-YY', date_mask: 'YYYY-MM'},
    { _id: 12, name: `Mo/YY (${moment(date, dateFormat).format('Mo/YY')})`, value: 'Mo/YY', date_mask: 'YYYY-MM'},

    { _id: 13, name: `YYYY-Mo (${moment(date, dateFormat).format('YYYY-Mo')})`, value: 'YYYY-Mo', date_mask: 'YYYY-MM'},
    { _id: 14, name: `YYYY/Mo (${moment(date, dateFormat).format('YYYY/Mo')})`, value: 'YYYY/Mo', date_mask: 'YYYY-MM'},
    { _id: 15, name: `Mo-YYYY (${moment(date, dateFormat).format('Mo-YYYY')})`, value: 'Mo-YYYY', date_mask: 'YYYY-MM'},
    { _id: 16, name: `Mo/YYYY (${moment(date, dateFormat).format('Mo/YYYY')})`, value: 'Mo/YYYY', date_mask: 'YYYY-MM'},

    { _id: 17, name: `YY-MM (${moment(date, dateFormat).format('YY-MM')})`, value: 'YY-MM', date_mask: 'YYYY-MM'},
    { _id: 18, name: `YY/MM (${moment(date, dateFormat).format('YY/MM')})`, value: 'YY/MM', date_mask: 'YYYY-MM'},
    { _id: 19, name: `MM-YY (${moment(date, dateFormat).format('MM-YY')})`, value: 'MM-YY', date_mask: 'YYYY-MM'},
    { _id: 20, name: `MM/YY (${moment(date, dateFormat).format('MM/YY')})`, value: 'MM/YY', date_mask: 'YYYY-MM'},

    { _id: 21, name: `YYYY-MM (${moment(date, dateFormat).format('YYYY-MM')})`, value: 'YYYY-MM', date_mask: 'YYYY-MM'},
    { _id: 22, name: `YYYY/MM (${moment(date, dateFormat).format('YYYY/MM')})`, value: 'YYYY/MM', date_mask: 'YYYY-MM'},
    { _id: 23, name: `MM-YYYY (${moment(date, dateFormat).format('MM-YYYY')})`, value: 'MM-YYYY', date_mask: 'YYYY-MM'},
    { _id: 24, name: `MM/YYYY (${moment(date, dateFormat).format('MM/YYYY')})`, value: 'MM/YYYY', date_mask: 'YYYY-MM'},

    { _id: 25, name: `YY-MMM (${moment(date, dateFormat).format('YY-MMM')})`, value: 'YY-MMM', date_mask: 'YYYY-MM'},
    { _id: 26, name: `YY/MMM (${moment(date, dateFormat).format('YY/MMM')})`, value: 'YY/MMM', date_mask: 'YYYY-MM'},
    { _id: 27, name: `MMM-YY (${moment(date, dateFormat).format('MMM-YY')})`, value: 'MMM-YY', date_mask: 'YYYY-MM'},
    { _id: 28, name: `MMM/YY (${moment(date, dateFormat).format('MMM/YY')})`, value: 'MMM/YY', date_mask: 'YYYY-MM'},

    { _id: 29, name: `YYYY-MMM (${moment(date, dateFormat).format('YYYY-MMM')})`, value: 'YYYY-MMM', date_mask: 'YYYY-MM'},
    { _id: 30, name: `YYYY/MMM (${moment(date, dateFormat).format('YYYY/MMM')})`, value: 'YYYY/MMM', date_mask: 'YYYY-MM'},
    { _id: 31, name: `MMM-YYYY (${moment(date, dateFormat).format('MMM-YYYY')})`, value: 'MMM-YYYY', date_mask: 'YYYY-MM'},
    { _id: 32, name: `MMM/YYYY (${moment(date, dateFormat).format('MMM/YYYY')})`, value: 'MMM/YYYY', date_mask: 'YYYY-MM'},

    { _id: 33, name: `YY-MMMM (${moment(date, dateFormat).format('YY-MMMM')})`, value: 'YY-MMMM', date_mask: 'YYYY-MM'},
    { _id: 34, name: `YY/MMMM (${moment(date, dateFormat).format('YY/MMMM')})`, value: 'YY/MMMM', date_mask: 'YYYY-MM'},
    { _id: 35, name: `MMMM-YY (${moment(date, dateFormat).format('MMMM-YY')})`, value: 'MMMM-YY', date_mask: 'YYYY-MM'},
    { _id: 36, name: `MMMM/YY (${moment(date, dateFormat).format('MMMM/YY')})`, value: 'MMMM/YY', date_mask: 'YYYY-MM'},

    { _id: 37, name: `YYYY-MMMM (${moment(date, dateFormat).format('YYYY-MMMM')})`, value: 'YYYY-MMMM', date_mask: 'YYYY-MM'},
    { _id: 38, name: `YYYY/MMMM (${moment(date, dateFormat).format('YYYY/MMMM')})`, value: 'YYYY/MMMM', date_mask: 'YYYY-MM'},
    { _id: 39, name: `MMMM-YYYY (${moment(date, dateFormat).format('MMMM-YYYY')})`, value: 'MMMM-YYYY', date_mask: 'YYYY-MM'},
    { _id: 40, name: `MMMM/YYYY (${moment(date, dateFormat).format('MMMM/YYYY')})`, value: 'MMMM/YYYY', date_mask: 'YYYY-MM'}
];

export const getYearWeekFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `YY-W (${moment(date, dateFormat).format('YY-W')})`, value: 'YY-W', date_mask: 'YYYY-WW'},
    { _id: 2, name: `YY/W (${moment(date, dateFormat).format('YY/W')})`, value: 'YY/W', date_mask: 'YYYY-WW'},
    { _id: 3, name: `W-YY (${moment(date, dateFormat).format('W-YY')})`, value: 'W-YY', date_mask: 'YYYY-WW'},
    { _id: 4, name: `W/YY (${moment(date, dateFormat).format('W/YY')})`, value: 'W/YY', date_mask: 'YYYY-WW'},

    { _id: 5, name: `YYYY-W (${moment(date, dateFormat).format('YYYY-W')})`, value: 'YYYY-W', date_mask: 'YYYY-WW'},
    { _id: 6, name: `YYYY/W (${moment(date, dateFormat).format('YYYY/W')})`, value: 'YYYY/W', date_mask: 'YYYY-WW'},
    { _id: 7, name: `W-YYYY (${moment(date, dateFormat).format('W-YYYY')})`, value: 'W-YYYY', date_mask: 'YYYY-WW'},
    { _id: 8, name: `W/YYYY (${moment(date, dateFormat).format('W/YYYY')})`, value: 'W/YYYY', date_mask: 'YYYY-WW'},

    { _id: 9, name: `YY-Wo (${moment(date, dateFormat).format('YY-Wo')})`, value: 'YY-Wo', date_mask: 'YYYY-WW'},
    { _id: 10, name: `YY/Wo (${moment(date, dateFormat).format('YY/Wo')})`, value: 'YY/Wo', date_mask: 'YYYY-WW'},
    { _id: 11, name: `Wo-YY (${moment(date, dateFormat).format('Wo-YY')})`, value: 'Wo-YY', date_mask: 'YYYY-WW'},
    { _id: 12, name: `Wo/YY (${moment(date, dateFormat).format('Wo/YY')})`, value: 'Wo/YY', date_mask: 'YYYY-WW'},

    { _id: 13, name: `YYYY-Wo (${moment(date, dateFormat).format('YYYY-Wo')})`, value: 'YYYY-Wo', date_mask: 'YYYY-WW'},
    { _id: 14, name: `YYYY/Wo (${moment(date, dateFormat).format('YYYY/Wo')})`, value: 'YYYY/Wo', date_mask: 'YYYY-WW'},
    { _id: 15, name: `Wo-YYYY (${moment(date, dateFormat).format('Wo-YYYY')})`, value: 'Wo-YYYY', date_mask: 'YYYY-WW'},
    { _id: 16, name: `Wo/YYYY (${moment(date, dateFormat).format('Wo/YYYY')})`, value: 'Wo/YYYY', date_mask: 'YYYY-WW'},

    { _id: 17, name: `YY-WW (${moment(date, dateFormat).format('YY-WW')})`, value: 'YY-WW', date_mask: 'YYYY-WW'},
    { _id: 18, name: `YY/WW (${moment(date, dateFormat).format('YY/WW')})`, value: 'YY/WW', date_mask: 'YYYY-WW'},
    { _id: 19, name: `WW-YY (${moment(date, dateFormat).format('WW-YY')})`, value: 'WW-YY', date_mask: 'YYYY-WW'},
    { _id: 20, name: `WW/YY (${moment(date, dateFormat).format('WW/YY')})`, value: 'WW/YY', date_mask: 'YYYY-WW'},

    { _id: 21, name: `YYYY-WW (${moment(date, dateFormat).format('YYYY-WW')})`, value: 'YYYY-WW', date_mask: 'YYYY-WW'},
    { _id: 22, name: `YYYY/WW (${moment(date, dateFormat).format('YYYY/WW')})`, value: 'YYYY/WW', date_mask: 'YYYY-WW'},
    { _id: 23, name: `WW-YYYY (${moment(date, dateFormat).format('WW-YYYY')})`, value: 'WW-YYYY', date_mask: 'YYYY-WW'},
    { _id: 24, name: `WW/YYYY (${moment(date, dateFormat).format('WW/YYYY')})`, value: 'WW/YYYY', date_mask: 'YYYY-WW'}
];

export const getFullDateFormatOptions = (date = defaultDate, dateFormat = defaultDateFormat) => [
    { _id: 1, name: `YY-MM-DD (${moment(date, dateFormat).format(`YY-MM-DD`)})`, value: `YY-MM-DD`, date_mask: 'YYYY-MM-DD'},
    { _id: 2, name: `DD-MM-YY (${moment(date, dateFormat).format(`DD-MM-YY`)})`, value: `DD-MM-YY`, date_mask: 'YYYY-MM-DD'},
    { _id: 3, name: `MM-DD-YY (${moment(date, dateFormat).format(`MM-DD-YY`)})`, value: `MM-DD-YY`, date_mask: 'YYYY-MM-DD'},

    { _id: 4, name: `YYYY-MM-DD (${moment(date, dateFormat).format(`YYYY-MM-DD`)})`, value: `YYYY-MM-DD`, date_mask: 'YYYY-MM-DD'},
    { _id: 5, name: `DD-MM-YYYY (${moment(date, dateFormat).format(`DD-MM-YYYY`)})`, value: `DD-MM-YYYY`, date_mask: 'YYYY-MM-DD'},
    { _id: 6, name: `MM-DD-YYYY (${moment(date, dateFormat).format(`MM-DD-YYYY`)})`, value: `MM-DD-YYYY`, date_mask: 'YYYY-MM-DD'},

    { _id: 7, name: `YY/MM/DD (${moment(date, dateFormat).format(`YY/MM/DD`)})`, value: `YY/MM/DD`, date_mask: 'YYYY-MM-DD'},
    { _id: 8, name: `DD/MM/YY (${moment(date, dateFormat).format(`DD/MM/YY`)})`, value: `DD/MM/YY`, date_mask: 'YYYY-MM-DD'},
    { _id: 9, name: `MM/DD/YY (${moment(date, dateFormat).format(`MM/DD/YY`)})`, value: `MM/DD/YY`, date_mask: 'YYYY-MM-DD'},

    { _id: 10, name: `YYYY/MM/DD (${moment(date, dateFormat).format(`YYYY/MM/DD`)})`, value: `YYYY/MM/DD`, date_mask: 'YYYY-MM-DD'},
    { _id: 11, name: `DD/MM/YYYY (${moment(date, dateFormat).format(`DD/MM/YYYY`)})`, value: `DD/MM/YYYY`, date_mask: 'YYYY-MM-DD'},
    { _id: 12, name: `MM/DD/YYYY (${moment(date, dateFormat).format(`MM/DD/YYYY`)})`, value: `MM/DD/YYYY`, date_mask: 'YYYY-MM-DD'},

    { _id: 13, name: `YY MMM DD (${moment(date, dateFormat).format('YY MMM DD')})`, value: 'YY MMM DD', date_mask: 'YYYY-MM-DD'},
    { _id: 14, name: `DD MMM YY (${moment(date, dateFormat).format('DD MMM YY')})`, value: 'DD MMM YY', date_mask: 'YYYY-MM-DD'},
    { _id: 15, name: `MMM DD YY (${moment(date, dateFormat).format('MMM DD YY')})`, value: 'MMM DD YY', date_mask: 'YYYY-MM-DD'},

    { _id: 16, name: `YYYY MMM DD (${moment(date, dateFormat).format('YYYY MMM DD')})`, value: 'YYYY MMM DD', date_mask: 'YYYY-MM-DD'},
    { _id: 17, name: `DD MMM YYYY (${moment(date, dateFormat).format('DD MMM YYYY')})`, value: 'DD MMM YYYY', date_mask: 'YYYY-MM-DD'},
    { _id: 18, name: `MMM DD YYYY (${moment(date, dateFormat).format('MMM DD YYYY')})`, value: 'MMM DD YYYY', date_mask: 'YYYY-MM-DD'},

    { _id: 19, name: `YY MMMM DD (${moment(date, dateFormat).format('YY MMMM DD')})`, value: 'YY MMMM DD', date_mask: 'YYYY-MM-DD'},
    { _id: 20, name: `DD MMMM YY (${moment(date, dateFormat).format('DD MMMM YY')})`, value: 'DD MMMM YY', date_mask: 'YYYY-MM-DD'},
    { _id: 21, name: `MMMM DD YY (${moment(date, dateFormat).format('MMMM DD YY')})`, value: 'MMMM DD YY', date_mask: 'YYYY-MM-DD'},

    { _id: 22, name: `YYYY MMMM DD (${moment(date, dateFormat).format('YYYY MMMM DD')})`, value: 'YYYY MMMM DD', date_mask: 'YYYY-MM-DD'},
    { _id: 23, name: `DD MMMM YYYY (${moment(date, dateFormat).format('DD MMMM YYYY')})`, value: 'DD MMMM YYYY', date_mask: 'YYYY-MM-DD'},
    { _id: 24, name: `MMMM DD YYYY (${moment(date, dateFormat).format('MMMM DD YYYY')})`, value: 'MMMM DD YYYY', date_mask: 'YYYY-MM-DD'},

    { _id: 25, name: `dddd, DD MMMM YY (${moment(date, dateFormat).format('dddd, DD MMMM YY')})`, value: 'dddd, DD MMMM YY', date_mask: 'YYYY-MM-DD'},
    { _id: 26, name: `dddd, DD MMMM YYYY (${moment(date, dateFormat).format('dddd, DD MMMM YYYY')})`, value: 'dddd, DD MMMM YYYY', date_mask: 'YYYY-MM-DD'}
];

export const getFullDateTimeFormatOptions = (date = defaultDateTime, dateFormat = defaultDateTimeFormat) => [
    { _id: 1, name: `YY-MM-DD HH:mm:ss (${moment(date, dateFormat).format('YY-MM-DD HH:mm:ss')})`, value: 'YY-MM-DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 2, name: `DD-MM-YY HH:mm:ss (${moment(date, dateFormat).format('DD-MM-YY HH:mm:ss')})`, value: 'DD-MM-YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 3, name: `MM-DD-YY HH:mm:ss (${moment(date, dateFormat).format('MM-DD-YY HH:mm:ss')})`, value: 'MM-DD-YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 4, name: `YYYY-MM-DD HH:mm:ss (${moment(date, dateFormat).format('YYYY-MM-DD HH:mm:ss')})`, value: 'YYYY-MM-DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 5, name: `DD-MM-YYYY HH:mm:ss (${moment(date, dateFormat).format('DD-MM-YYYY HH:mm:ss')})`, value: 'DD-MM-YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 6, name: `MM-DD-YYYY HH:mm:ss (${moment(date, dateFormat).format('MM-DD-YYYY HH:mm:ss')})`, value: 'MM-DD-YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 7, name: `YY/MM/DD HH:mm:ss (${moment(date, dateFormat).format('YY/MM/DD HH:mm:ss')})`, value: 'YY/MM/DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 8, name: `DD/MM/YY HH:mm:ss (${moment(date, dateFormat).format('DD/MM/YY HH:mm:ss')})`, value: 'DD/MM/YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 9, name: `MM/DD/YY HH:mm:ss (${moment(date, dateFormat).format('MM/DD/YY HH:mm:ss')})`, value: 'MM/DD/YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 10, name: `YYYY/MM/DD HH:mm:ss (${moment(date, dateFormat).format('YYYY/MM/DD HH:mm:ss')})`, value: 'YYYY/MM/DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 11, name: `DD/MM/YYYY HH:mm:ss (${moment(date, dateFormat).format('DD/MM/YYYY HH:mm:ss')})`, value: 'DD/MM/YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 12, name: `MM/DD/YYYY HH:mm:ss (${moment(date, dateFormat).format('MM/DD/YYYY HH:mm:ss')})`, value: 'MM/DD/YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 13, name: `YY MMM DD HH:mm:ss (${moment(date, dateFormat).format('YY MMM DD HH:mm:ss')})`, value: 'YY MMM DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 14, name: `DD MMM YY HH:mm:ss (${moment(date, dateFormat).format('DD MMM YY HH:mm:ss')})`, value: 'DD MMM YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 15, name: `MMM DD YY HH:mm:ss (${moment(date, dateFormat).format('MMM DD YY HH:mm:ss')})`, value: 'MMM DD YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 16, name: `YYYY MMM DD HH:mm:ss (${moment(date, dateFormat).format('YYYY MMM DD HH:mm:ss')})`, value: 'YYYY MMM DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 17, name: `DD MMM YYYY HH:mm:ss (${moment(date, dateFormat).format('DD MMM YYYY HH:mm:ss')})`, value: 'DD MMM YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 18, name: `MMM DD YYYY HH:mm:ss (${moment(date, dateFormat).format('MMM DD YYYY HH:mm:ss')})`, value: 'MMM DD YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 19, name: `YY MMMM DD HH:mm:ss (${moment(date, dateFormat).format('YY MMMM DD HH:mm:ss')})`, value: 'YY MMMM DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 20, name: `DD MMMM YY HH:mm:ss (${moment(date, dateFormat).format('DD MMMM YY HH:mm:ss')})`, value: 'DD MMMM YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 21, name: `MMMM DD YY HH:mm:ss (${moment(date, dateFormat).format('MMMM DD YY HH:mm:ss')})`, value: 'MMMM DD YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 22, name: `YYYY MMMM DD HH:mm:ss (${moment(date, dateFormat).format('YYYY MMMM DD HH:mm:ss')})`, value: 'YYYY MMMM DD HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 23, name: `DD MMMM YYYY HH:mm:ss (${moment(date, dateFormat).format('DD MMMM YYYY HH:mm:ss')})`, value: 'DD MMMM YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 24, name: `MMMM DD YYYY HH:mm:ss (${moment(date, dateFormat).format('MMMM DD YYYY HH:mm:ss')})`, value: 'MMMM DD YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},

    { _id: 25, name: `dddd, DD MMMM YY HH:mm:ss (${moment(date, dateFormat).format('dddd, DD MMMM YY HH:mm:ss')})`, value: 'dddd, DD MMMM YY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'},
    { _id: 26, name: `dddd, DD MMMM YYYY HH:mm:ss (${moment(date, dateFormat).format('dddd, DD MMMM YYYY HH:mm:ss')})`, value: 'dddd, DD MMMM YYYY HH:mm:ss', date_mask: 'YYYY-MM-DDTHH:mm:ss'}
];

export const getDateHourFormatOptions = (date = defaultDateTime, dateFormat = defaultDateTimeFormat) => [
    { _id: 1, name: `24H (${moment(date, dateFormat).format('HH')})`, value: 'HH', date_mask: 'HH', label: '24H' },
    { _id: 2, name: `12H (${moment(date, dateFormat).format('hh A')})`, value: 'hh A', date_mask: 'HH', label: '12H' }
];

export const getDateTimeFormatOptions = (t = i18n.t) => [
    { _id: 1, name: `15${i18n.t('timeUnits.second_short')}`, value: 's(s)', inputs: 1},
    { _id: 2, name: `15 ${i18n.t('timeUnits.second_long_plural')}`, value: 's(seconds)', inputs: 1},
    { _id: 3, name: `35${i18n.t('timeUnits.minute_short')} 15${i18n.t('timeUnits.second_short')}`, value: 'm(m) s(s)', inputs: 2},
    { _id: 4, name: `35 ${i18n.t('timeUnits.minute_long_plural')} 15 ${i18n.t('timeUnits.second_long_plural')}`, value: 'm(minutes) s(seconds)', inputs: 2},
    { _id: 5, name: `35:15`, value: 'm: s:', inputs: 2},
    { _id: 6, name: `35${i18n.t('timeUnits.minute_short')}`, value: 'm(m)', inputs: 1},
    { _id: 7, name: `35 ${i18n.t('timeUnits.minute_long_plural')}`, value: 'm(minutes)', inputs: 1},
    { _id: 8, name: `2${i18n.t('timeUnits.hour_short')} 35${i18n.t('timeUnits.minute_short')} 15${i18n.t('timeUnits.second_short')}`, value: 'h(h) m(m) s(s)', inputs: 3},
    { _id: 9, name: `2 ${i18n.t('timeUnits.hour_long_plural')} 35 ${i18n.t('timeUnits.minute_long_plural')} 15 ${i18n.t('timeUnits.second_long_plural')}`, value: 'h(hours) m(minutes) s(seconds)', inputs: 3},
    { _id: 10, name: `2:35:15`, value: 'h: m: s:', inputs: 3},
    { _id: 11, name: `2${i18n.t('timeUnits.hour_short')} 35${i18n.t('timeUnits.minute_short')}`, value: 'h(h) m(m)', inputs: 3},
    { _id: 12, name: `2 ${i18n.t('timeUnits.hour_long_plural')} 35 ${i18n.t('timeUnits.minute_long_plural')}`, value: 'h(hours) m(minutes)', inputs: 2},
    { _id: 13, name: `2:35`, value: 'h: m:', inputs: 2},
    { _id: 14, name: `2${i18n.t('timeUnits.hour_short')}`, value: 'h(h)', inputs: 1},
    { _id: 15, name: `2 ${i18n.t('timeUnits.hour_long_plural')}`, value: 'h(hours)', inputs: 1},
    { _id: 16, name: `4${i18n.t('timeUnits.day_short')} 2${i18n.t('timeUnits.hour_short')} 35${i18n.t('timeUnits.minute_short')}`, value: 'd(d) h(h) m(m)', inputs: 3},
    { _id: 17, name: `4 ${i18n.t('timeUnits.day_long_plural')} 2 ${i18n.t('timeUnits.hour_long_plural')} 35 ${i18n.t('timeUnits.minute_long_plural')}`, value: 'd(days) h(hours) m(minutes)', inputs: 3},
    { _id: 18, name: `4${i18n.t('timeUnits.day_short')} 2:35`, value: 'd(d) h: m:', inputs: 3},
    { _id: 19, name: `4 ${i18n.t('timeUnits.day_long_plural')} 2:35`, value: 'd(days) h: m:', inputs: 3},
    { _id: 20, name: `4${i18n.t('timeUnits.day_short')} 2${i18n.t('timeUnits.hour_short')}`, value: 'd(d) h(h)', inputs: 2},
    { _id: 21, name: `4 ${i18n.t('timeUnits.day_long_plural')} 2 ${i18n.t('timeUnits.hour_long_plural')}`, value: 'd(days) h(hours)', inputs: 2},
    { _id: 22, name: `4${i18n.t('timeUnits.day_short')}`, value: 'd(d)', inputs: 1},
    { _id: 23, name: `4 ${i18n.t('timeUnits.day_long_plural')}`, value: 'd(days)', inputs: 1},
    { _id: 24, name: `3${i18n.t('timeUnits.month_short')} 4${i18n.t('timeUnits.day_short')} 2${i18n.t('timeUnits.hour_short')}`, value: 'M(M) d(d) h(h)', inputs: 3},
    { _id: 25, name: `3 ${i18n.t('timeUnits.month_long_plural')} 4 ${i18n.t('timeUnits.day_long_plural')} 2 ${i18n.t('timeUnits.hour_long_plural')}`, value: 'M(months) d(days) h(hours)', inputs: 3},
    { _id: 26, name: `3${i18n.t('timeUnits.month_short')} 4${i18n.t('timeUnits.day_short')}`, value: 'M(M) d(d)', inputs: 2},
    { _id: 27, name: `3 ${i18n.t('timeUnits.month_long_plural')} 4 ${i18n.t('timeUnits.day_long_plural')}`, value: 'M(months) d(days)', inputs: 2},
    { _id: 28, name: `3${i18n.t('timeUnits.month_short')}`, value: 'M(M)', inputs: 1},
    { _id: 29, name: `3 ${i18n.t('timeUnits.month_long_plural')}`, value: 'M(months)', inputs: 1},
    { _id: 30, name: `2${i18n.t('timeUnits.year_short')} 3${i18n.t('timeUnits.month_short')} 4${i18n.t('timeUnits.day_short')}`, value: 'y(y) M(M) d(d)', inputs: 3},
    { _id: 31, name: `2 ${i18n.t('timeUnits.year_long_plural')} 3 ${i18n.t('timeUnits.month_long_plural')} 4 ${i18n.t('timeUnits.day_long_plural')}`, value: 'y(years) M(months) d(days)', inputs: 3},
    { _id: 32, name: `2${i18n.t('timeUnits.year_short')} 3${i18n.t('timeUnits.month_short')}`, value: 'y(y) M(M)', inputs: 2},
    { _id: 33, name: `2 ${i18n.t('timeUnits.year_long_plural')} 3 ${i18n.t('timeUnits.month_long_plural')}`, value: 'y(years) M(months)', inputs: 2},
    { _id: 34, name: `2${i18n.t('timeUnits.year_short')}`, value: 'y(y)', inputs: 1},
    { _id: 35, name: `2 ${i18n.t('timeUnits.year_long_plural')}`, value: 'y(years)', inputs: 1}
];

export const getTimeLevelOptions = (t = i18n.t) => [
    { name: capitalize(i18n.t('timeUnits.second_long_plural')), value: 'seconds' },
    { name: capitalize(i18n.t('timeUnits.minute_long_plural')), value: 'minutes' },
    { name: capitalize(i18n.t('timeUnits.hour_long_plural')), value: 'hours' },
    { name: capitalize(i18n.t('timeUnits.day_long_plural')), value: 'days' },
    { name: capitalize(i18n.t('timeUnits.month_long_plural')), value: 'months' },
    { name: capitalize(i18n.t('timeUnits.year_long_plural')), value: 'years' }
];

// WARNING!!!!! THIS OBJECT IS A COPY OF ANOTHER ONE IN THE CLIENT APP, MAKE SURE BOTH OBJECTS ARE UP TO DATE
export const defaultDateFormats = {
    year: { format: 'YYYY', date_mask: 'YYYY' },
    quarter: { format: 'Q', date_mask: 'Q' },
    month: { format: 'MMMM', date_mask: 'MM' },
    week: { format: 'WW', date_mask: 'WW' },
    year_quarter: { format: 'YYYY-Q', date_mask: 'YYYY-Q' },
    year_month: { format: 'YYYY-M', date_mask: 'YYYY-MM' },
    year_week: { format: 'YYYY-WW', date_mask: 'YYYY-WW' },
    full_date: { format: `YYYY-MM-DD`, date_mask: 'YYYY-MM-DD' },
    full_date_time: { format: 'YYYY-MM-DD HH:mm:ss', date_mask: 'YYYY-MM-DDThh:mm:ss' },
    hour_of_day: { format: 'HH', date_mask: 'HH' }
};

export const getAllDateFilterFormatOptions = () => {
    return uniqBy([...getFullDateTimeFormatOptions(), ...getFullDateFormatOptions(), ...getYearWeekFormatOptions(), ...getYearMonthFormatOptions(), ...getYearQuarterFormatOptions(), ...getWeekFormatOptions(), ...getMonthFormatOptions(), ...getQuarterFormatOptions(), ...getYearFormatOptions()], 'value')
        .map((option, index) => { return {  ...option, _id: index + 1 } });
};

export function getDateLevelFromQueryField({ date_level, data_type, field_type, aggregation }) {
    const fieldMetadataSendToServer = checkQueryFieldTypes([{ date_level, data_type, field_type, aggregation }])[0];
    return fieldMetadataSendToServer.date_level;
}

export const getDefaultDateLevel = (dataType) => dataType === "date" ? DEFAULT_DATE_LEVEL : DEFAULT_DATE_TIME_LEVEL;

export const getDateLevelFormat = (dateLevel) => {

    switch (dateLevel) {
        case 'year':
            return 'YYYY';
        case 'quarter':
            return 'Q';
        case 'month':
            return 'MM';
        case 'week':
            return 'WW';
        case 'year_quarter':
            return 'YYYY-QQ';
        case 'year_month':
            return 'YYYY-MM';
        case 'year_week':
            return 'YYYY-WW';
        case 'full_date':
            return 'YYYY-MM-DD';
        case 'full_date_time':
            return 'YYYY-MM-DD HH:mm:ss';
        case 'hour_of_day':
            return 'HH';
        case 'raw':
            return '';
        default:
            return 'YYYY-MM-DD';
    }
}

export const getFilterDateLevelFormat = (dateLevel) => {

    switch (dateLevel) {
        case 'year':
            return 'YYYY';
        case 'quarter':
            return 'YYYY-Q';
        case 'month':
            return 'YYYY-MM';
        case 'week':
            return 'YYYY-WW';
        case 'full_date':
            return 'YYYY-MM-DD';
        case 'full_date_time':
            return 'YYYY-MM-DD HH:mm:ss';
        case 'hour_of_day':
            return 'HH';
        case 'raw':
            return '';
        default:
            return 'YYYY-MM-DD';
    }
}

export const getServerDateLevel = (dateLevel) => {
    switch (dateLevel) {
        case 'year':
            return 'year';
        case 'quarter':
        case 'year_quarter':
            return 'quarter';
        case 'month':
        case 'year_month':
            return 'month';
        case 'full_date':
        case 'full_date_time':
        case 'day':
            return 'day';
        case "week":
        case "year_week":
            return "week";
        default:
            break;
    }
}

export const getDateMaxLength = (mask) => {
    switch (mask) {
        case 'M':
        case 'W':
        case 'Qo':
        case 'Mo':
        case 'Wo':
        case 'HH':
            return 2;

        case 'MMM':
        case 'YY-Qo':
        case 'Qo-YY':
        case 'YY/Qo':
        case 'Qo/YY':
            return 4;

        case 'YY-W':
        case 'YY/W':
        case 'W-YY':
        case 'W/YY':
        case 'YY-M':
        case 'YY/M':
        case 'M-YY':
        case 'M/YY':
        case 'YY-Mo':
        case 'YY/Mo':
        case 'Mo-YY':
        case 'Mo/YY':
        case 'YY-Wo':
        case 'YY/Wo':
        case 'Wo-YY':
        case 'Wo/YY':
        case 'hh A':
            return 5;

        case 'YYYY-M':
        case 'YYYY/M':
        case 'M-YYYY':
        case 'M/YYYY':
        case 'YY-MMM':
        case 'YY/MMM':
        case 'MMM-YY':
        case 'MMM/YY':
        case 'YYYY-W':
        case 'YYYY/W':
        case 'W-YYYY':
        case 'W/YYYY':
        case 'YYYY-Mo':
        case 'YYYY/Mo':
        case 'YYYY-Qo':
        case 'Qo-YYYY':
        case 'YYYY/Qo':
        case 'Qo/YYYY':
        case 'YYYY-Wo':
        case 'YYYY/Wo':
        case 'Wo-YYYY':
        case 'Wo/YYYY':
            return 7;

        case 'Mo-YYYY':
        case 'Mo/YYYY':
            return 8;

        case 'YYYY-MMM':
        case 'MMM-YYYY':
        case 'YYYY/MMM':
        case 'MMM/YYYY':
            return 9;

        case 'YY MMM DD':
        case 'DD MMM YY':
        case 'MMM DD YY':
            return 10;

        case 'YYYY MMM DD':
        case 'DD MMM YYYY':
        case 'MMM DD YYYY':
            return 12;

        case 'YY MMM DD HH:mm:ss':
        case 'DD MMM YY HH:mm:ss':
        case 'MMM DD YY HH:mm:ss':
            return 19;

        case 'YYYY MMM DD HH:mm:ss':
        case 'DD MMM YYYY HH:mm:ss':
        case 'MMM DD YYYY HH:mm:ss':
            return 21;

        case 'MMMM':
        case 'YY-MMMM':
        case 'YY/MMMM':
        case 'MMMM-YY':
        case 'MMMM/YY':
        case 'YYYY-MMMM':
        case 'YYYY/MMMM':
        case 'MMMM-YYYY':
        case 'MMMM/YYYY':
            return 25;

        case 'YY MMMM DD':
        case 'DD MMMM YY':
        case 'MMMM DD YY':
        case 'YYYY MMMM DD':
        case 'DD MMMM YYYY':
        case 'MMMM DD YYYY':
        case 'dddd, DD MMMM YY':
        case 'dddd, DD MMMM YYYY':
        case 'YY MMMM DD HH:mm:ss':
        case 'DD MMMM YY HH:mm:ss':
        case 'MMMM DD YY HH:mm:ss':
        case 'YYYY MMMM DD HH:mm:ss':
        case 'DD MMMM YYYY HH:mm:ss':
        case 'MMMM DD YYYY HH:mm:ss':
            return 40;

        case 'dddd, DD MMMM YY HH:mm:ss':
        case 'dddd, DD MMMM YYYY HH:mm:ss':
            return 50;

        default:
            return mask.length;
    }
}


export default {
    getDateLevelFilterOptions,
    getDateLevelOptions,
    getDateYearFilterOptions,
    getDateQuarterFilterOptions,
    getDateMonthFilterOptions,
    getDateDayFilterOptions,
    getDateHourFilterOptions,
    getYearFormatOptions,
    getQuarterFormatOptions,
    getMonthFormatOptions,
    getWeekFormatOptions,
    getYearQuarterFormatOptions,
    getYearMonthFormatOptions,
    getYearWeekFormatOptions,
    getFullDateFormatOptions,
    getFullDateTimeFormatOptions,
    getDateHourFormatOptions,
    getDateTimeFormatOptions,
    getTimeLevelOptions,
    defaultDateFormats,
    getAllDateFilterFormatOptions,
    dayHoursOptions,
    getDateLevelFromQueryField,
    getDefaultDateLevel,
    DEFAULT_DATE_LEVEL,
    DEFAULT_DATE_TIME_LEVEL,
    getDateLevelFormat,
    getFilterDateLevelFormat,
    getServerDateLevel,
    getDateMaxLength
}