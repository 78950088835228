import { combineReducers } from 'redux';

// Biuwer Data reducers
import content from "./content/content-reducer";
import dataConnectionTypes from "./data-connection-types/data-connection-types-reducers";
import dataConnections from "./data-connections/data-connections-reducers";
import datamodels from "./datamodels/datamodels-reducers";
import datasets from "./datasets/datasets-reducers";
import dataQueries from "./data-queries/data-queries-reducers";
import filters from "./filters/filters-reducers";
import datasetsFieldTranslations from "./datasets-field-translations/datasets-field-translations-reducers";
import interactiveDataSystem from './interactive-data-system/interactive-data-system-reducer';

export default combineReducers({
    content,
    dataConnectionTypes,
    dataConnections,
    datamodels,
    datasets,
    dataQueries,
    filters,
    datasetsFieldTranslations,
    interactiveDataSystem
});