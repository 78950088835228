
/*
 * Filters editor dialog action types
 */

export const INITIALIZE_FILTERS_EDITOR_DIALOG = "INITIALIZE_FILTERS_EDITOR_DIALOG";
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SELECTED_FILTER_ID = 'SET_SELECTED_FILTER_ID';
export const SET_PAGE_CARDS_METADATA= 'SET_PAGE_CARDS_METADATA';
export const SET_CARD_METADATA= 'SET_CARD_METADATA';

// Filter field actions

// General
export const SET_NAME = "SET_NAME";
export const SET_FILTER_TYPE = "SET_FILTER_TYPE";
export const SET_STAGE = "SET_STAGE";
export const SET_EDITABLE = "SET_EDITABLE";
export const SET_MANDATORY = "SET_MANDATORY";
export const SET_VISIBLE = "SET_VISIBLE";
export const SET_MULTI_VALUE = "SET_MULTI_VALUE";
export const SET_ORDER = "SET_ORDER";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const SET_FILTERS_GROUPS = "SET_FILTERS_GROUPS"

// Filters groups
export const ADD_FILTER_GROUP = "ADD_FILTER_GROUP"
export const REMOVE_FILTER_GROUP = "REMOVE_FILTER_GROUP"
export const SET_FILTER_GROUP = "SET_FILTER_GROUP"
export const SET_FILTERS_CONFIG = "SET_FILTERS_CONFIG"
export const ENABLE_FILTERS_GROUPS = "ENABLE_FILTERS_GROUPS"
export const SET_OPERATION_BETWEEN_FILTERS_GROUPS = "SET_OPERATION_BETWEEN_FILTERS_GROUPS"

// Custom list
export const SET_USE_CUSTOM_LIST = "SET_USE_CUSTOM_LIST";
export const SET_CUSTOM_LIST = "SET_CUSTOM_LIST";

// Origin
export const SET_DATAMODEL = "SET_DATAMODEL";
export const SET_DATASET = "SET_DATASET";
export const SET_DATA_FIELD = "SET_DATA_FIELD";

// Default expression
export const SET_DEFAULT_EXPRESSION_ALL_VALUES = "SET_DEFAULT_EXPRESSION_ALL_VALUES";
export const SET_DEFAULT_EXPRESSION_VALUES = "SET_DEFAULT_EXPRESSION_VALUES";
export const SET_DEFAULT_EXPRESSION_VALUE = "SET_DEFAULT_EXPRESSION_VALUE";
export const SET_DEFAULT_EXPRESSION_FROM_VALUE = "SET_DEFAULT_EXPRESSION_FROM_VALUE";
export const SET_DEFAULT_EXPRESSION_TO_VALUE = "SET_DEFAULT_EXPRESSION_TO_VALUE";
export const SET_DEFAULT_EXPRESSION_OPERATION = "SET_DEFAULT_EXPRESSION_OPERATION";
export const SET_DEFAULT_EXPRESSION_DATE_LEVEL = "SET_DEFAULT_EXPRESSION_DATE_LEVEL";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_1 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_1";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_2 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_2";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_3 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_3";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_1 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_1";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_2 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_2";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_3 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_3";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_1 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_1";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_2 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_2";
export const SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_3 = "SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_3";
export const SET_DEFAULT_EXPRESSION_OPTION_LIMIT = "SET_DEFAULT_EXPRESSION_OPTION_LIMIT";
export const SET_DEFAULT_EXPRESSION_DATAMODEL = "SET_DEFAULT_EXPRESSION_DATAMODEL";
export const SET_DEFAULT_EXPRESSION_DATASET = "SET_DEFAULT_EXPRESSION_DATASET";
export const SET_DEFAULT_EXPRESSION_DATA_FIELD = "SET_DEFAULT_EXPRESSION_DATA_FIELD";
export const SET_DEFAULT_EXPRESSION_AGGREGATION = "SET_DEFAULT_EXPRESSION_AGGREGATION";
export const SET_DEFAULT_EXPRESSION_TRANSLATIONS = "SET_DEFAULT_EXPRESSION_TRANSLATIONS";

// Time
export const SET_TIME_METRIC = "SET_TIME_METRIC";
export const SET_TIME_FORMAT = "SET_TIME_FORMAT";
export const SET_TIME_LEVEL = "SET_TIME_LEVEL";

// URL Param
export const SET_URL_PARAM_ENABLED = "SET_URL_PARAM_ENABLED";
export const SET_URL_PARAM_PARAM_FIELD = "SET_URL_PARAM_PARAM_FIELD";

// Cards
export const SET_APPLY_TO_ALL_CARDS = "SET_APPLY_TO_ALL_CARDS";
export const CLEAN_ALL_CARDS = "CLEAN_ALL_CARDS";
export const SET_ALL_CARDS_DATAMODEL = "SET_ALL_CARDS_DATAMODEL";
export const SET_ALL_CARDS_DATASET = "SET_ALL_CARDS_DATASET";
export const SET_ALL_CARDS_DATA_FIELD = "SET_ALL_CARDS_DATA_FIELD";
export const ADD_CARD = "ADD_CARD";
export const REMOVE_CARD = "REMOVE_CARD";
export const ADD_CARD_QUERY = "ADD_CARD_QUERY";
export const REMOVE_CARD_QUERY = "REMOVE_CARD_QUERY";
export const SET_CARD_QUERY_DATAMODEL = "SET_CARD_QUERY_DATAMODEL";
export const SET_CARD_QUERY_DATASET = "SET_CARD_QUERY_DATASET";
export const SET_CARD_QUERY_DATA_FIELD = "SET_CARD_QUERY_DATA_FIELD";

// Queries
export const ADD_QUERY = "ADD_QUERY";
export const REMOVE_QUERY = "REMOVE_QUERY";
export const SET_QUERY_DATAMODEL = "SET_QUERY_DATAMODEL";
export const SET_QUERY_DATASET = "SET_QUERY_DATASET";
export const SET_QUERY_DATA_FIELD = "SET_QUERY_DATA_FIELD";

// Affected filters
export const ADD_ALL_AFFECTED_FILTERS = "ADD_ALL_AFFECTED_FILTERS";
export const REMOVE_ALL_AFFECTED_FILTERS = "REMOVE_ALL_AFFECTED_FILTERS";
export const ADD_AFFECTED_FILTER = "ADD_AFFECTED_FILTER";
export const REMOVE_AFFECTED_FILTER = "REMOVE_AFFECTED_FILTER";
export const ADD_AFFECTED_FILTER_AUTO_APPLY = "ADD_AFFECTED_FILTER_AUTO_APPLY";
export const REMOVE_AFFECTED_FILTER_AUTO_APPLY = "REMOVE_AFFECTED_FILTER_AUTO_APPLY";

// Filter use
export const SET_SHOW_SELECTION_TAB = "SET_SHOW_SELECTION_TAB";
export const SET_DATE_LEVEL_SELECTION_TAB = "SET_DATE_LEVEL_SELECTION_TAB";
export const SET_SHOW_EXPRESSION_TAB = "SET_SHOW_EXPRESSION_TAB";
export const SET_DATE_LEVEL_EXPRESSION_TAB = "SET_DATE_LEVEL_EXPRESSION_TAB";
export const SET_OPERATIONS_ALLOWED_EXPRESSION_TAB = "SET_OPERATIONS_ALLOWED_EXPRESSION_TAB";
export const SET_SHOW_PREDEFINED_TAB = "SET_SHOW_PREDEFINED_TAB";
export const SET_DATE_LEVEL_PREDEFINED_TAB = "SET_DATE_LEVEL_PREDEFINED_TAB";
export const SET_SHOW_TOP_BOTTOM_TAB = "SET_SHOW_TOP_BOTTOM_TAB";

/**
 * Initialize filters editor dialog action
 */
 export function initializeFiltersEditorDialog(filters) {
    return (dispatch) => {
        dispatch({type: INITIALIZE_FILTERS_EDITOR_DIALOG});
    };
};

/**
 * Set filters action
 */
export function setFilters(filters) {
    return (dispatch) => {
        dispatch({
            type: SET_FILTERS,
            filters: filters
        });
    };
};

/**
 * Set selected filter id action
 */
export function setSelectedFilterId(selectedFilterId) {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_FILTER_ID,
            selectedFilterId: selectedFilterId
        });
    };
};

/**
 * Set page cards metadata action
 */
 export function setPageCardsMetadata(pageCardsMetadata) {
    return (dispatch) => {
        dispatch({
            type: SET_PAGE_CARDS_METADATA,
            pageCardsMetadata: pageCardsMetadata
        });
    };
};

/**
 * Set card metadata action
 */
 export function setCardMetadata(cardMetadata) {
    return (dispatch) => {
        dispatch({
            type: SET_CARD_METADATA,
            cardMetadata: cardMetadata
        });
    };
};

/**
 * Set filter fields actions
 */

// General
export const setName = value => (dispatch) => dispatch({ type: SET_NAME, value });
export const setFilterType = value => (dispatch) => dispatch({ type: SET_FILTER_TYPE, value });

export const setFiltersGroups = value => (dispatch) => dispatch({ type: SET_FILTERS_GROUPS, filtersGroups: value })
export const setFiltersConfig = value => (dispatch) => dispatch({ type: SET_FILTERS_CONFIG, filtersConfig: value })

export const enableFiltersGroups = value => (dispatch) => dispatch({ type: ENABLE_FILTERS_GROUPS, value })
export const setOperationBetweenFiltersGroups = value => (dispatch) => dispatch({ type: SET_OPERATION_BETWEEN_FILTERS_GROUPS, value })

// Options
export const setStage = value => (dispatch) => dispatch({ type: SET_STAGE, value });
export const setEditable = value => (dispatch) => dispatch({ type: SET_EDITABLE, value });
export const setMandatory = value => (dispatch) => dispatch({ type: SET_MANDATORY, value });
export const setVisible = value => (dispatch) => dispatch({ type: SET_VISIBLE, value });
export const setMultiValue = value => (dispatch) => dispatch({ type: SET_MULTI_VALUE, value });
export const setTranslation = value => (dispatch) => dispatch({ type: SET_TRANSLATIONS, value });

// Order
export const setOrder = value => (dispatch) => dispatch({ type: SET_ORDER, value });

// Custom list
export const setUseCustomList = value => (dispatch) => dispatch({ type: SET_USE_CUSTOM_LIST, value });
export const setCustomList = value => (dispatch) => dispatch({ type: SET_CUSTOM_LIST, value });

// Origin
export const setDatamodel = value => (dispatch) => dispatch({ type: SET_DATAMODEL, value });
export const setDataset = value => (dispatch) => dispatch({ type: SET_DATASET, value });
export const setDataField = value => (dispatch) => dispatch({ type: SET_DATA_FIELD, value });

// Default expression
export const setDefaultExpressionOperation = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_OPERATION, value });
export const setDefaultExpressionDateLevel = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_DATE_LEVEL, value });
export const setDefaultExpressionAllValues = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_ALL_VALUES, value });
export const setDefaultExpressionValues = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_VALUES, value });
export const setDefaultExpressionValue = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_VALUE, value });
export const setDefaultExpressionFromValue = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_FROM_VALUE, value });
export const setDefaultExpressionToValue = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TO_VALUE, value });
export const setDefaultExpressionTimeValue1 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_1, value });
export const setDefaultExpressionTimeValue2 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_2, value });
export const setDefaultExpressionTimeValue3 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_3, value });
export const setDefaultExpressionTimeValueMin1 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_1, value });
export const setDefaultExpressionTimeValueMin2 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_2, value });
export const setDefaultExpressionTimeValueMin3 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_MIN_3, value });
export const setDefaultExpressionTimeValueMax1 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_1, value });
export const setDefaultExpressionTimeValueMax2 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_2, value });
export const setDefaultExpressionTimeValueMax3 = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TIME_VALUE_MAX_3, value });
export const setLimit = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_OPTION_LIMIT, value });
export const setDefaultExpressionDatamodel = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_DATAMODEL, value });
export const setDefaultExpressionDataset = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_DATASET, value });
export const setDefaultExpressionDataField = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_DATA_FIELD, value });
export const setDefaultExpressionAggregation = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_AGGREGATION, value });
export const setDefaultExpressionTranslations = value => (dispatch) => dispatch({ type: SET_DEFAULT_EXPRESSION_TRANSLATIONS, value });

// Time
export const setTimeMetric = value => (dispatch) => dispatch({ type: SET_TIME_METRIC, value });
export const setTimeFormat = value => (dispatch) => dispatch({ type: SET_TIME_FORMAT, value });
export const setTimeLevel = value => (dispatch) => dispatch({ type: SET_TIME_LEVEL, value });

// URL Param
export const setUrlParamEnabled = value => (dispatch) => dispatch({ type: SET_URL_PARAM_ENABLED, value });
export const setUrlParamParamField = value => (dispatch) => dispatch({ type: SET_URL_PARAM_PARAM_FIELD, value });

// Cards
export const setApplyToAllCards = (data, value) => (dispatch) => dispatch({ type: SET_APPLY_TO_ALL_CARDS, data, value });
export const cleanAllCards = () => (dispatch) => dispatch({ type: CLEAN_ALL_CARDS });
export const setAllCardsDatamodel = (value) => (dispatch) => dispatch({ type: SET_ALL_CARDS_DATAMODEL, value });
export const setAllCardsDataset = (data, value) => (dispatch) => dispatch({ type: SET_ALL_CARDS_DATASET, data, value });
export const setAllCardsDataField = (data, value) => (dispatch) => dispatch({ type: SET_ALL_CARDS_DATA_FIELD, data, value });
export const addCard = (value) => (dispatch) => dispatch({ type: ADD_CARD, value });
export const removeCard = (value) => (dispatch) => dispatch({ type: REMOVE_CARD, value });
export const addCardQuery = (value) => (dispatch) => dispatch({ type: ADD_CARD_QUERY, value });
export const removeCardQuery = (cardQueryIndex) => (dispatch) => dispatch({ type: REMOVE_CARD_QUERY, cardQueryIndex });
export const setCardQueryDatamodel = (cardQueryIndex, value) => (dispatch) => dispatch({ type: SET_CARD_QUERY_DATAMODEL, cardQueryIndex, value });
export const setCardQueryDataset = (cardQueryIndex, value) => (dispatch) => dispatch({ type: SET_CARD_QUERY_DATASET, cardQueryIndex, value });
export const setCardQueryDataField = (cardQueryIndex, value) => (dispatch) => dispatch({ type: SET_CARD_QUERY_DATA_FIELD, cardQueryIndex, value });

// Queries
export const addQuery = (value) => (dispatch) => dispatch({ type: ADD_QUERY, value });
export const removeQuery = (queryIndex) => (dispatch) => dispatch({ type: REMOVE_QUERY, queryIndex });
export const setQueryDatamodel = (queryIndex, value) => (dispatch) => dispatch({ type: SET_QUERY_DATAMODEL, queryIndex, value });
export const setQueryDataset = (queryIndex, value) => (dispatch) => dispatch({ type: SET_QUERY_DATASET, queryIndex, value });
export const setQueryDataField = (queryIndex, value) => (dispatch) => dispatch({ type: SET_QUERY_DATA_FIELD, queryIndex, value });

// Affected filters
export const addAllAffectedFilters = () => (dispatch) => dispatch({ type: ADD_ALL_AFFECTED_FILTERS });
export const removeAllAffectedFilters = () => (dispatch) => dispatch({ type: REMOVE_ALL_AFFECTED_FILTERS });
export const addAffectedFilter = value => (dispatch) => dispatch({ type: ADD_AFFECTED_FILTER, value });
export const removeAffectedFilter = value => (dispatch) => dispatch({ type: REMOVE_AFFECTED_FILTER, value });
export const addAffectedFilterAutoApply = value => (dispatch) => dispatch({ type: ADD_AFFECTED_FILTER_AUTO_APPLY, value });
export const removeAffectedFilterAutoApply = value => (dispatch) => dispatch({ type: REMOVE_AFFECTED_FILTER_AUTO_APPLY, value });

// Filter use
export const setShowSelectionTab = () => (dispatch) => dispatch({ type: SET_SHOW_SELECTION_TAB });
export const setDateLevelSelectionTab = value => (dispatch) => dispatch({ type: SET_DATE_LEVEL_SELECTION_TAB, value });
export const setShowExpressionTab = () => (dispatch) => dispatch({ type: SET_SHOW_EXPRESSION_TAB });
export const setDateLevelExpressionTab = value => (dispatch) => dispatch({ type: SET_DATE_LEVEL_EXPRESSION_TAB, value });
export const setOperationsAllowedExpressionTab = value => (dispatch) => dispatch({ type: SET_OPERATIONS_ALLOWED_EXPRESSION_TAB, value });
export const setShowPredefinedTab = () => (dispatch) => dispatch({ type: SET_SHOW_PREDEFINED_TAB });
export const setDateLevelPredefinedTab = value => (dispatch) => dispatch({ type: SET_DATE_LEVEL_PREDEFINED_TAB, value });
export const setShowTopBottomTab = () => (dispatch) => dispatch({ type: SET_SHOW_TOP_BOTTOM_TAB });