import { find, uniqBy, isEmpty, isNil, isNaN, uniq, sortBy, cloneDeep } from 'lodash';
import gql from 'graphql-tag';
import moment from 'moment';
import queryString from 'query-string';

// Libs
import { dayHoursOptions } from "@biuwer/common/src/libs/dates-lib";
import History from "@biuwer/core/src/history";
import { checkDrillThrough } from './data-filter-editor/libs/filters-utils-lib';
import i18n from "@biuwer/core/src/i18n"

export const defaultDate = `${new Date().getFullYear()}0130`;
export const defaultDateTime = `${new Date().getFullYear()}0130 14:30:15`;
export const defaultTime = `14:30:15`;

export const defaultDateFormat = `YYYYMMDD`;
export const defaultDateTimeFormat = `YYYYMMDD HH:mm:ss`;
export const defaultTimeFormat = 'HH:mm:ss';

export const defaultDateLevel = "day";

export const customOrderMaxValues = 100;

export const filterTypeOptions = [
    { _id: 1, name: 'filters.filterType.date', value: 'Date'},
    { _id: 2, name: 'filters.filterType.string', value: 'String'},
    { _id: 3, name: 'filters.filterType.number', value: 'Number'},
    { _id: 4, name: 'filters.filterType.boolean', value: 'Boolean'},
    { _id: 5, name: 'filters.filterType.field', value: 'Field'}
];

export const booleanFilterOptions = [
    { _id: 1, name: 'filters.equals', value: 'equals' },
    { _id: 2, name: 'filters.notEquals', value: 'notEquals' },
    { _id: 3, name: 'filters.isNull', value: 'isNull' },
    { _id: 4, name: 'filters.isNotNull', value: 'isNotNull' }
];

export const booleanMultiFilterOptions = [
    { _id: 1, name: 'filters.inList', value: 'inList' },
    { _id: 2, name: 'filters.notInList', value: 'notInList' }
];

export const booleanFilterValuesOptions = [
    { _id: 1, name: 'common.yes', value: 'true' },
    { _id: 2, name: 'common.no', value: 'false' }
];

export const topBottomFilterOptions = [
    { _id: 1, name: 'filters.top', value: 'top' },
    { _id: 2, name: 'filters.bottom', value: 'bottom' },
    { _id: 3, name: 'filters.interval', value: 'interval' }
];

export const textFilterOptions = [
    { _id: 1, name: 'filters.equals', value: 'equals' },
    { _id: 2, name: 'filters.notEquals', value: 'notEquals' },
    { _id: 3, name: 'filters.strings.contains', value: 'contains' },
    { _id: 4, name: 'filters.strings.notContains', value: 'notContains' },
    { _id: 5, name: 'filters.strings.startsWith', value: 'startsWith' },
    { _id: 6, name: 'filters.strings.notStartsWith', value: 'notStartsWith' },
    { _id: 7, name: 'filters.strings.endsWith', value: 'endsWith' },
    { _id: 8, name: 'filters.strings.notEndsWith', value: 'notEndsWith' },
    { _id: 9, name: 'filters.isNull', value: 'isNull' },
    { _id: 10, name: 'filters.isNotNull', value: 'isNotNull' }
];

export const textFilterFieldOptions = [
    { _id: 1, name: 'filters.equals', value: 'equals' },
    { _id: 2, name: 'filters.notEquals', value: 'notEquals' },
    { _id: 3, name: 'filters.strings.contains', value: 'contains' },
    { _id: 4, name: 'filters.strings.notContains', value: 'notContains' },
    { _id: 5, name: 'filters.strings.startsWith', value: 'startsWith' },
    { _id: 6, name: 'filters.strings.notStartsWith', value: 'notStartsWith' },
    { _id: 7, name: 'filters.strings.endsWith', value: 'endsWith' },
    { _id: 8, name: 'filters.strings.notEndsWith', value: 'notEndsWith' },
    { _id: 9, name: 'filters.top', value: 'top' },
    { _id: 10, name: 'filters.bottom', value: 'bottom' },
    { _id: 11, name: 'filters.interval', value: 'interval' },
    { _id: 12, name: 'filters.isNull', value: 'isNull' },
    { _id: 13, name: 'filters.isNotNull', value: 'isNotNull' }
];

export const textMultiFilterOptions = [
    { _id: 1, name: 'filters.inList', value: 'inList' },
    { _id: 2, name: 'filters.notInList', value: 'notInList' }
];

export const numberFilterOptions =  [
    { _id: 1, name: 'filters.equals', value: 'equals' },
    { _id: 2, name: 'filters.notEquals', value: 'notEquals' },
    { _id: 3, name: 'filters.numbers.greaterThan', value: 'greaterThan' },
    { _id: 4, name: 'filters.numbers.greaterOrEqualThan', value: 'greaterOrEqualThan' },
    { _id: 5, name: 'filters.numbers.smallerThan', value: 'smallerThan' },
    { _id: 6, name: 'filters.numbers.smallerOrEqualThan', value: 'smallerOrEqualThan' },
    { _id: 7, name: 'filters.between', value: 'between' },
    { _id: 8, name: 'filters.notBetween', value: 'notBetween' },
    { _id: 9, name: 'filters.isNull', value: 'isNull' },
    { _id: 10, name: 'filters.isNotNull', value: 'isNotNull' }
];

export const numberFilterFieldOptions =  [
    { _id: 1, name: 'filters.equals', value: 'equals' },
    { _id: 2, name: 'filters.notEquals', value: 'notEquals' },
    { _id: 3, name: 'filters.numbers.greaterThan', value: 'greaterThan' },
    { _id: 4, name: 'filters.numbers.greaterOrEqualThan', value: 'greaterOrEqualThan' },
    { _id: 5, name: 'filters.numbers.smallerThan', value: 'smallerThan' },
    { _id: 6, name: 'filters.numbers.smallerOrEqualThan', value: 'smallerOrEqualThan' },
    { _id: 7, name: 'filters.between', value: 'between' },
    { _id: 8, name: 'filters.notBetween', value: 'notBetween' },
    { _id: 9, name: 'filters.top', value: 'top' },
    { _id: 10, name: 'filters.bottom', value: 'bottom' },
    { _id: 11, name: 'filters.interval', value: 'interval' },
    { _id: 12, name: 'filters.isNull', value: 'isNull' },
    { _id: 13, name: 'filters.isNotNull', value: 'isNotNull' }
];

export const numberMultiFilterOptions =  [
    { _id: 1, name: 'filters.inList', value: 'inList' },
    { _id: 2, name: 'filters.notInList', value: 'notInList' }
];

export const dateFilterOptions = [
    { _id: 1, name: 'filters.equals', value: 'equals' },
    { _id: 2, name: 'filters.notEquals', value: 'notEquals' },
    { _id: 3, name: 'filters.numbers.greaterThan', value: 'greaterThan' },
    { _id: 4, name: 'filters.numbers.greaterOrEqualThan', value: 'greaterOrEqualThan' },
    { _id: 5, name: 'filters.numbers.smallerThan', value: 'smallerThan' },
    { _id: 6, name: 'filters.numbers.smallerOrEqualThan', value: 'smallerOrEqualThan' },
    { _id: 7, name: 'filters.between', value: 'between' },
    { _id: 8, name: 'filters.notBetween', value: 'notBetween' },
    { _id: 9, name: 'filters.dates.predefined', value: 'predefinedRange' },
    { _id: 10, name: 'filters.dates.notPredefined', value: 'notPredefinedRange' },
    { _id: 11, name: 'filters.isNull', value: 'isNull' },
    { _id: 12, name: 'filters.isNotNull', value: 'isNotNull' }
];

export const dateFilterFieldOptions = [
    { _id: 1, name: 'filters.equals', value: 'equals' },
    { _id: 2, name: 'filters.notEquals', value: 'notEquals' },
    { _id: 3, name: 'filters.numbers.greaterThan', value: 'greaterThan' },
    { _id: 4, name: 'filters.numbers.greaterOrEqualThan', value: 'greaterOrEqualThan' },
    { _id: 5, name: 'filters.numbers.smallerThan', value: 'smallerThan' },
    { _id: 6, name: 'filters.numbers.smallerOrEqualThan', value: 'smallerOrEqualThan' },
    { _id: 7, name: 'filters.between', value: 'between' },
    { _id: 8, name: 'filters.notBetween', value: 'notBetween' },
    { _id: 9, name: 'filters.dates.predefined', value: 'predefinedRange' },
    { _id: 10, name: 'filters.dates.notPredefined', value: 'notPredefinedRange' },
    { _id: 11, name: 'filters.top', value: 'top' },
    { _id: 13, name: 'filters.bottom', value: 'bottom' },
    { _id: 15, name: 'filters.interval', value: 'interval' },
    { _id: 16, name: 'filters.isNull', value: 'isNull' },
    { _id: 17, name: 'filters.isNotNull', value: 'isNotNull' }
];

export const dateMultiFilterOptions = [
    { _id: 1, name: 'filters.inList', value: 'inList' },
    { _id: 2, name: 'filters.notInList', value: 'notInList' }
];

export const dateLevelOptions = [
    { _id: 1, name: 'filters.dates.year', value: 'year', date_mask: 'YYYY', regex: /^\d{4}$/ },
    { _id: 2, name: 'filters.dates.quarter', value: 'quarter', date_mask: 'YYYY-Q', regex: /^\d{4}-([1-4])$/ },
    { _id: 3, name: 'filters.dates.month', value: 'month', date_mask: 'YYYY-MM', regex: /^\d{4}-(0[1-9]|1[012])$/ },
    { _id: 3, name: 'filters.dates.week', value: 'week', date_mask: 'YYYY-IW', regex: /^\d{4}-(0[1-9]|1[053])$/ },
    { _id: 4, name: 'filters.dates.day', value: 'day', date_mask: 'YYYY-MM-DD', regex: /(^(((\d\d)(([02468][048])|([13579][26]))-02-29)|(((\d\d)(\d\d)))-((((0\d)|(1[0-2]))-((0\d)|(1\d)|(2[0-8])))|((((0[13578])|(1[02]))-31)|(((0[1,3-9])|(1[0-2]))-(29|30)))))$)/ },
    { _id: 5, name: 'filters.dates.hourOfDay', value: 'hour_of_day', date_mask: 'HH', regex: /^(2[0-3]|[0-1]?[0-9])$/ }
];

export const dateYearFilterOptions = [
    { _id: 1, name: 'filters.dates.thisYear', value: 'thisYear'},
    { _id: 2, name: 'filters.dates.previousYear', value: 'previousYear'},
    { _id: 3, name: 'filters.dates.yearToDate', value: 'yearToDate'},
    { _id: 4, name: 'filters.dates.previousTwoYears', value: 'previousTwoYears'},
    { _id: 5, name: 'filters.dates.twoYearsAgo', value: 'twoYearsAgo'},
    { _id: 6, name: 'filters.dates.lastTwoYears', value: 'lastTwoYears'},
    { _id: 7, name: 'filters.dates.lastThreeYears', value: 'lastThreeYears'}
];

export const dateQuarterFilterOptions = [
    { _id: 1, name: 'filters.dates.thisQuarter', value: 'thisQuarter' },
    { _id: 2, name: 'filters.dates.previousQuarter', value: 'previousQuarter' },
    { _id: 3, name: 'filters.dates.quarterToDate', value: 'quarterToDate'},
    { _id: 4, name: 'filters.dates.previousTwoQuarters', value: 'previousTwoQuarters' },
    { _id: 5, name: 'filters.dates.twoQuartersAgo', value: 'twoQuartersAgo' },
    { _id: 6, name: 'filters.dates.threeQuartersAgo', value: 'threeQuartersAgo' },
    { _id: 7, name: 'filters.dates.lastTwoQuarters', value: 'lastTwoQuarters' },
    { _id: 8, name: 'filters.dates.lastThreeQuarters', value: 'lastThreeQuarters' },
    { _id: 9, name: 'filters.dates.lastFourQuarters', value: 'lastFourQuarters' }
];

export const dateMonthFilterOptions = [
    { _id: 1, name: 'filters.dates.thisMonth', value: 'thisMonth' },
    { _id: 2, name: 'filters.dates.previousMonth', value: 'previousMonth' },
    { _id: 3, name: 'filters.dates.monthToDate', value: 'monthToDate'},
    { _id: 4, name: 'filters.dates.previousTwoMonths', value: 'previousTwoMonths' },
    { _id: 5, name: 'filters.dates.twoMonthsAgo', value: 'twoMonthsAgo' },
    { _id: 6, name: 'filters.dates.threeMonthsAgo', value: 'threeMonthsAgo' },
    { _id: 7, name: 'filters.dates.lastTwoMonths', value: 'lastTwoMonths' },
    { _id: 8, name: 'filters.dates.lastThreeMonths', value: 'lastThreeMonths' },
    { _id: 9, name: 'filters.dates.lastSixMonths', value: 'lastSixMonths' },
    { _id: 10, name: 'filters.dates.lastTwelveMonths', value: 'lastTwelveMonths' }
];

export const dateWeekFilterOptions = [
    { _id: 1, name: 'filters.dates.thisWeek', value: 'thisWeek' },
    { _id: 2, name: 'filters.dates.lastWeek', value: 'lastWeek' },
    { _id: 3, name: 'filters.dates.weekToDate', value: 'weekToDate' },
    { _id: 4, name: 'filters.dates.previousTwoWeeks', value: 'previousTwoWeeks' },
    { _id: 5, name: 'filters.dates.twoWeeksAgo', value: 'twoWeeksAgo' },
    { _id: 6, name: 'filters.dates.threeWeeksAgo', value: 'threeWeeksAgo' },
    { _id: 7, name: 'filters.dates.lastTwoWeeks', value: 'lastTwoWeeks' },
    { _id: 8, name: 'filters.dates.lastThreeWeeks', value: 'lastThreeWeeks' },
    { _id: 9, name: 'filters.dates.lastFourWeeks', value: 'lastFourWeeks' },
    { _id: 10, name: 'filters.dates.lastSixWeeks', value: 'lastSixWeeks' },
    { _id: 11, name: 'filters.dates.lastEightWeeks', value: 'lastEightWeeks' },
    { _id: 12, name: 'filters.dates.lastTwelveWeeks', value: 'lastTwelveWeeks' }
];

export const dateDayFilterOptions = [
    { _id: 1, name: 'filters.dates.today', value: 'today' },
    { _id: 2, name: 'filters.dates.yesterday', value: 'yesterday' },
    { _id: 3, name: 'filters.dates.previousTwoDays', value: 'previousTwoDays' },
    { _id: 4, name: 'filters.dates.twoDaysAgo', value: 'twoDaysAgo' },
    { _id: 5, name: 'filters.dates.threeDaysAgo', value: 'threeDaysAgo' },
    { _id: 6, name: 'filters.dates.lastSevenDays', value: 'lastSevenDays' },
    { _id: 7, name: 'filters.dates.lastFourteenDays', value: 'lastFourteenDays' },
    { _id: 8, name: 'filters.dates.lastThirtyDays', value: 'lastThirtyDays' },
    { _id: 9, name: 'filters.dates.lastSixtyDays', value: 'lastSixtyDays' }
];

export const dateHourFilterOptions = [
    { _id: 1, name: 'filters.dates.lastHour', value: 'lastHour' },
    { _id: 2, name: 'filters.dates.lastTwoHours', value: 'lastTwoHours' },
    { _id: 3, name: 'filters.dates.lastThreeHours', value: 'lastThreeHours' },
    { _id: 4, name: 'filters.dates.lastFourHours', value: 'lastFourHours' },
    { _id: 5, name: 'filters.dates.lastSixHours', value: 'lastSixHours' },
    { _id: 7, name: 'filters.dates.lastEightHours', value: 'lastEightHours' },
    { _id: 8, name: 'filters.dates.lastTwelveHours', value: 'lastTwelveHours' },
    { _id: 9, name: 'filters.dates.lastTwentyFourHours', value: 'lastTwentyFourHours' }
];

export const dateLevelFilterOptions = [
    ...dateYearFilterOptions.map(option => { return { ...option, _id: `year-${option._id}`, level: 'year' } }),
    ...dateQuarterFilterOptions.map(option => { return { ...option, _id: `quarter-${option._id}`, level: 'quarter' }}),
    ...dateMonthFilterOptions.map(option => { return { ...option, _id: `month-${option._id}`, level: 'month' }}),
    ...dateDayFilterOptions.map(option => { return { ...option, _id: `day-${option._id}`, level: 'day' }})
];

export const aggregationNumberFilterOptions = [
    { _id: 1, name: 'datasets.aggregations.sum', value: 'sum' },
    { _id: 2, name: 'datasets.aggregations.avg', value: 'avg' },
    { _id: 3, name: 'datasets.aggregations.count', value: 'count' },
    { _id: 4, name: 'datasets.aggregations.countDistinct', value: 'countDistinct' },
    { _id: 5, name: 'datasets.aggregations.max', value: 'max' },
    { _id: 6, name: 'datasets.aggregations.min', value: 'min' }
];

export const aggregationNumberEmptyFilterOptions = [
    { _id: 1, name: 'common.noneLabel_female', value: '' },
    { _id: 2, name: 'datasets.aggregations.sum', value: 'sum' },
    { _id: 3, name: 'datasets.aggregations.avg', value: 'avg' },
    { _id: 4, name: 'datasets.aggregations.count', value: 'count' },
    { _id: 5, name: 'datasets.aggregations.countDistinct', value: 'countDistinct' },
    { _id: 6, name: 'datasets.aggregations.max', value: 'max' },
    { _id: 7, name: 'datasets.aggregations.min', value: 'min' }
];

export const aggregationTextFilterOptions = [
    { _id: 1, name: 'datasets.aggregations.count', value: 'count' },
    { _id: 2, name: 'datasets.aggregations.countDistinct', value: 'countDistinct' },
    { _id: 3, name: 'datasets.aggregations.max', value: 'max' },
    { _id: 4, name: 'datasets.aggregations.min', value: 'min' }
];

export const aggregationTextEmptyFilterOptions = [
    { _id: 1, name: 'common.noneLabel_female', value: '' },
    { _id: 2, name: 'datasets.aggregations.count', value: 'count' },
    { _id: 3, name: 'datasets.aggregations.countDistinct', value: 'countDistinct' },
    { _id: 4, name: 'datasets.aggregations.max', value: 'max' },
    { _id: 5, name: 'datasets.aggregations.min', value: 'min' }
];

export const getDefaultOrderOptions = (t) => [{
    name: t('cards.chartConfig.order.default'),
    value: 'default'
}, {
    name: t('cards.chartConfig.order.asc'),
    value: 'asc'
}, {
    name: t('cards.chartConfig.order.desc'),
    value: 'desc'
}];

export const allFilterOperationsOptions = uniqBy([
    ...numberMultiFilterOptions, ...numberFilterFieldOptions, ...numberFilterOptions,
    ...dateMultiFilterOptions, ...dateFilterFieldOptions, ...dateFilterOptions, ...textMultiFilterOptions,
    ...textFilterFieldOptions, ...textFilterOptions, ...booleanMultiFilterOptions, ...booleanFilterOptions
], 'value').map((option, index) => { return { ...option, _id: index+1 } });

export const allAggregationsOptions = uniqBy([...aggregationNumberFilterOptions, ...aggregationTextFilterOptions], 'value')
    .map((option, index) => { return { ...option, _id: index+1 } });

/**
 * Get from all filter operations array the i18n string for translate the operation to local language
 * @param operation (string) value of the operation
 * @returns {string}
 */
export function getLanguageStringOperation(operation) {
    let selectedOperation = find(allFilterOperationsOptions, { value: operation});
    if (selectedOperation) return selectedOperation.name;
    return '';
}

/**
 * Get Apollo Props for queryDatamodel. Returns data needed to read a query in Apollo store
 * @param datamodel (number) - Datamodel Id
 * @param dataset (object) - Dataset used in query
 * @param field (object) - Field used in query
 * @param filterId (string) - Filter Object Id
 * @param data (array) - Array of filters from page or card
 * @param source (string) - String with source object calling this function
 * @param dateLevel (string) - String with the date level applied
 * @param language (string) - Language to get data
 */
export function getApolloProps(datamodel, dataset, field, filterId, data, source, dateLevel, language) {

    const history = History.getHistory();

    if (dataset && field) {
        let fieldForQuery = {}, orderFieldForQuery = {};
        fieldForQuery._id = field._id;
        fieldForQuery.dataset = dataset;

        fieldForQuery.field_type = 'dimension';

        if (field.calculated_field && field.field_type === 'measure') {
            fieldForQuery.aggregation = null;
        }

        // used to dateLevel, limit and sort
        const selectedFilter = find(data, { '_id': filterId}) || {};

        // patch date_level
        if (selectedFilter.data_type === 'date' || selectedFilter.data_type === 'datetime') {
            let dateLevelSelected = dateLevel || selectedFilter.default_expression.date_level;

            if (!dateLevel && source === 'filtersArea' && selectedFilter.expression && selectedFilter.expression.date_level) {
                dateLevelSelected = selectedFilter.expression.date_level;
            }

            switch (dateLevelSelected) {
                case 'year':
                    fieldForQuery.date_level = dateLevelSelected;
                    break;
                case 'quarter':
                case 'month':
                case "week":
                    fieldForQuery.date_level = `year_${dateLevelSelected}`;
                    break;
                default:
                    fieldForQuery.date_level = 'full_date';
                    break;

            }
        }

        const { order } = selectedFilter;

        if (order && order.type === 'field' && !!order.order_field && !!order.order_field.data_field) {
            orderFieldForQuery._id = order.order_field.data_field._id;
            orderFieldForQuery.dataset = dataset;
            orderFieldForQuery.field_type = order.order_field.data_field.field_type;

            if (order.order_field.aggregation) {
                orderFieldForQuery.field_type = 'measure';
                orderFieldForQuery.aggregation = order.order_field.aggregation;
            }

        }

        // get options from default_expression and add a static sort
        let defaultOptions = {};
        if (selectedFilter && selectedFilter.default_expression && selectedFilter.default_expression.options) {
            defaultOptions = { ...selectedFilter.default_expression.options };
        }

        if ((['filtersArea', 'filtersEditor']).indexOf(source) < 0) {
            defaultOptions.sort = [{
                fieldId: field._id,
                order: 1,
                dataset: dataset
            }];
        }

        if (!!order && !!order.type) {

            if ((['asc', 'desc']).indexOf(order.type) >= 0) {
                const orderCriteria = order.type === 'asc' ? 1 : -1;
                defaultOptions.sort = [
                    {
                        type: order.type,
                        fieldId: field._id,
                        order: orderCriteria,
                        dataset: dataset
                    }
                ];
            }
            if (order.type === 'custom' && !!order.custom_order) {
                const orderCriteria = order.custom_order.remainingSort === 'asc' ? 1 : -1;
                defaultOptions.sort = [
                    {
                        type: order.type,
                        fieldId: field._id,
                        order: orderCriteria,
                        dataset: dataset,
                        custom_order: order.custom_order
                    }
                ];
            }
            if (order.type === 'field' && orderFieldForQuery && orderFieldForQuery._id) {
                defaultOptions.sort = [
                    {
                        type: order.type,
                        fieldId: orderFieldForQuery._id,
                        order: order.order_field.type === 'asc' ? 1 : -1,
                        dataset: dataset,
                        otherFieldId: field._id
                    }
                ];
            }
        }

        let options = [defaultOptions];
        let filters = [];
        let fields = [fieldForQuery];

        if (!isEmpty(orderFieldForQuery) && orderFieldForQuery._id) {
            fields.push(orderFieldForQuery);
        }

        // Look for dependant filters
        data.forEach((filter) => {
            if (filter.affected_filters && filter.affected_filters.find((filter_id) => { return String(filter_id) === String(filterId) })) {

                let expression;

                if (!!filter.url_param) {
                    expression = getFilterExpressionFromURLParam(filter, history);
                }

                const drillThroughConfig = checkDrillThrough(filter);

                if (!!drillThroughConfig) {
                    expression = drillThroughConfig.expression;
                }

                if (expression == null) {
                    if (source === 'filtersArea') {
                        if (filter.expression && (filter.expression.all_values || (filter.expression.values && filter.expression.values.length > 0))) {
                            expression = filter.expression;
                        } else {
                            expression = filter.default_expression;
                        }
                    } else {
                        expression = filter.default_expression;
                    }
                }

                filters.push({
                    data_field: filter.data_field._id,
                    dataset: filter.dataset._id,
                    expression: expression,
                    field_type: filter.data_field && filter.data_field.field_type ? filter.data_field.field_type : null,
                    filter_type: filter.filter_type,
                    _id: filter._id,
                    data_type: filter.data_type,
                    mandatory: filter.mandatory
                });
            }
        });

        let datamodelForQuery = JSON.stringify([datamodel]);
        let filtersForQuery = JSON.stringify([filters]);
        let fieldsForQuery = JSON.stringify([fields]);
        let optionsForQuery;
        if (datamodel?.size > 0) {
            options[0].datamodel = JSON.stringify(datamodel);
        }
        options[0].language = language;
        optionsForQuery = JSON.stringify(options);

        let queryAlias = `_dataset_${dataset}__field_${field._id}`;
        let queryApollo = gql`
            query apolloQueryDatasetField ($datamodel: JSON! $filters: JSON! $fields: JSON! $options: JSON!){
                ${queryAlias}: queryDatasetField (datamodel: $datamodel filters: $filters fields: $fields options: $options){
                    data,
                    dataWarnings,
                    dataErrors
                }
            }
        `;

        return {
            datamodel: datamodelForQuery,
            filters: filtersForQuery,
            fields: fieldsForQuery,
            options: optionsForQuery,
            queryAlias: queryAlias,
            queryApollo: queryApollo
        }
    }
}

/**
 * Get Query Props for queryDataset. Returns data needed to execute a query in database
 * @param dataConnection (number) - Data connection Id
 * @param dataset (number) - Dataset Id used in query
 * @param filters (object) - Filters used in query
 * @param fields (array) - Fields used in query
 * @param options (object) - Options for query
 */
export function getQueryPropsDataset({ dataConnection, dataset, filters, fields, options }) {

    let fieldsForQuery = [];
    if (fields?.length > 0) {
        fields.forEach((f) => {

            let fieldForQuery = {};
            fieldForQuery._id = f._id;
            fieldForQuery.dataset = dataset;

            // Measures should not be aggregated in filters
            fieldForQuery.field_type = "dimension"
            fieldForQuery.aggregation = null

            if (f.date_level) {
                fieldForQuery.date_level = f.date_level;
            }

            if (f.data_type === 'date') {
                fieldForQuery.date_level = 'full_date';
            }

            if (f.data_type === 'datetime') {
                fieldForQuery.date_level = 'full_date_time';
            }

            fieldsForQuery.push(fieldForQuery);
        });
    }

    let filtersForQuery = JSON.stringify(filters ?? {});
    let optionsForQuery = JSON.stringify({ ...(options ?? {}), apply_data_policies: true  });
    fieldsForQuery = JSON.stringify(fieldsForQuery);

    return {
        dataConnection: dataConnection,
        dataset: dataset,
        filters: filtersForQuery,
        fields: fieldsForQuery,
        options: optionsForQuery
    }
}

/**
 * Get Apollo Props for queryDataset. Returns data needed to read a query in Apollo store
 * @param dataConnection (number) - Data connection Id
 * @param dataset (number) - Dataset Id used in query
 * @param filters (object) - Filters used in query
 * @param field (object) - Field used in query
 */
export function getQueryPropsDatasetDataPolicy({ dataConnection, dataset, filters, field, dateLevel }) {

    let fieldForQuery = {};
    fieldForQuery._id = field._id;
    fieldForQuery.dataset = dataset;

    // Measures should not be aggregated in filters
    if (field.data_type === 'number') {
        fieldForQuery.field_type = 'dimension';
    }

    if (field.calculated_field && field.field_type === 'measure') {
        fieldForQuery.aggregation = null;
    }

    if (field.date_level || dateLevel) {
        fieldForQuery.date_level = "full_date";
    }

    let filtersForQuery = JSON.stringify(filters);
    let optionsForQuery = JSON.stringify({ apply_data_policies: false, sort: [{ fieldId: field._id, order: 1, dataset: dataset }]  });
    fieldForQuery = JSON.stringify([fieldForQuery]);

    return {
        dataConnection: dataConnection,
        dataset: dataset,
        filters: filtersForQuery,
        fields: fieldForQuery,
        options: optionsForQuery
    }
}

/**
 * Defines filter expression based on given URL params defined in filter config
 * @param filter, filter metadata
 * @param history, react router history to check url params
 * @returns {Object | null}
 */
export const getFilterExpressionFromURLParam = (filter, history = History.getHistory()) => {

    let expression = { ...filter.expression };
    let values = getFilterValuesFromURLParam(filter, history);

    if (!values) return null;

    expression.all_values = false;
    expression.values = values;

    if (filter.data_type === 'date' || filter.data_type === 'datetime') {
        const predefinedRange = filter.expression.operation === 'predefinedRange';
        let dateLevel = getDateLevelFromValue(values[0], predefinedRange);

        if (!dateLevel) return null;

        if (dateLevel.level !== filter.expression.date_level) {
            expression.date_level = dateLevel.level;
            expression.date_mask = dateLevel.dateMask;
        }
    }

    return expression;
};

/**
 * Get filter values from filter URL param config
 * @param {Object} filter, filter metadata
 * @param history, react router history to check url params
 * @returns {Array<*>|null}
 */
export const getFilterValuesFromURLParam = (filter, history = History.getHistory()) => {
    let params = null;
    if (history && history.location && history.location.search) {
        params = queryString.parse(history.location.search, {
            decode: true,
            arrayFormat: 'separator',
            arrayFormatSeparator: '|',
            parseNumbers: true,
            parseBooleans: false
        });
    }

    const { url_param, multi_value } = filter;

    // Skip logic if operation is related with top or null logic
    if ((['isNull', 'isNotNull', 'top', 'bottom', 'interval']).indexOf(filter.expression.operation) >= 0) return null;

    if (!!url_param && !!url_param.enabled && !!params) {
        let paramValues = params[url_param.param_field];
        if (isNil(paramValues)) return null;

        // Special case for between and notBetween operation
        if ((['between', 'notBetween']).indexOf(filter.expression.operation) >= 0) {
            if (!Array.isArray(paramValues) || paramValues.length < 2) {
                return null;
            } else {
                paramValues = [paramValues[0], paramValues[1]];
            }
        } else if (!!multi_value) {
            paramValues = Array.isArray(paramValues) ? paramValues : [paramValues];
        } else {
            paramValues = Array.isArray(paramValues) ? [paramValues[0]] : [paramValues];
        }

        let validValues = true;
        switch(filter.data_type) {
            case 'date':
            case 'datetime':
                validValues = !paramValues.some(value => !getDateLevelFromValue(value, filter.expression.operation === 'predefinedRange'));
                break;
            case 'number':
                validValues = !paramValues.some(value => isNil(value) || isNaN(Number(value)));
                break;
            case 'boolean':
                validValues = !paramValues.some(value => (["true", "false"]).indexOf(value) < 0);
                break;
            case 'string':
            default:
                validValues = !paramValues.some(value => isNil(value));
                break;
        }

        if (!validValues) return null;

        return paramValues;
    } else return null;
};

/**
 * Return date level and date mask based on given value
 * @param {String} value, date value to check
 * @param {Boolean} predefined, if true check the predefined values and only returns date level
 * @returns {Object<{level: *, dateMask: *}>|null}
 */
export const getDateLevelFromValue = (value, predefined = false) => {
    let level = null, dateMask = null;

    if (predefined) {
        let valueIndex = dateLevelFilterOptions.findIndex(option => String(option.value) === String(value));

        if (valueIndex >= 0) {
            level = dateLevelFilterOptions[valueIndex].level;
        }
    } else {

        for (let dateLevel of Array.from(dateLevelOptions).reverse()) {
            const regexCheck = dateLevel.regex.exec(value);
            if (!!regexCheck && !!moment(value, dateLevel.date_mask).isValid()) {
                level = dateLevel.value;
                dateMask = dateLevel.date_mask;
                break;
            }
        }
    }

    if (!level) return null;

    return { level, dateMask };
};

/**
 * Return date input format
 * @param {String} dateLevel
 * @returns {String}
 */
export function getDateInputFormat(dateLevel) {
    return {
        year: "YYYY",
        quarter: "YYYY-Q",
        month: "YYYY-MM",
        week: "GGGG-WW",
        day: "YYYY-MM-DD",
        hour_of_day: "HH"
    }[dateLevel] || "";
}

/**
 * Return date input mask
 * @param {String} dateInputFormat
 */
export function getDateInputMask(dateInputFormat) {
    return dateInputFormat
        .replace(/D/g, '1')
        .replace(/M/g, '1')
        .replace(/Q/g, '1')
        .replace(/Y/g, '1')
        .replace(/W/g, '1')
        .replace(/H/g, '1')
        .replace(/m/g, '1')
        .replace(/s/g, '1')
        .replace(/G/g, "1");
}

/**
 * Function helper to check if a date value input is valid
 * @param {{ format: String, mask: String, value: String }} param0
 * @returns {Boolean}
 */
export function dateValueInputIsValid({format, mask, value}) {
    mask = mask ? mask : getDateInputMask(format);
    if (value.length > mask.length) return false;
    const maskedValue = String(value)?.replace(/[0-9]/g,"1");
    const subMask = mask.substring(0, value.length);
    if (maskedValue !== subMask) return false;
    return true;
}

/**
 * Function helper to check if a date value is valid
 * @param {{ format: String, value: String }} param0
 * @returns {Boolean}
 */
 export function dateValueIsValid({ format, value }) {
     if (!value) return false;
    const mask = getDateInputMask(format);
    const maskedValue = String(value)?.replace(/[0-9]/g,"1");
    return maskedValue === mask && moment(value, format).isValid();
}

export function numberValueIsValid(numValue) {
    return !isNil(numValue) && numValue !== "" && !isNaN(Number(numValue));
}

/**
 * Function to transform day level data to specified date level
 * @param {Array<String>} data
 * @param {String} dateLevel
 * @returns {Array<String>}
 */
export function parseDateDataToDateLevel(data, dateLevel) {
    if (!Array.isArray(data) || !dateLevel) return [];
    if (dateLevel === 'hour_of_day') return dayHoursOptions;
    const oldFormat = getDateInputFormat("day");
    const newFormat = getDateInputFormat(dateLevel);
    data = data
        // Filter valid values on old date level
        .filter(clValue => dateValueIsValid({ format: oldFormat, value: clValue.value }))
        // Format for new date level
        .map(clValue => moment(clValue.value, oldFormat).format(newFormat));
    // Return data without duplicates
    return uniq(data).map(e => ({ label: e, value: e }));
}

/**
 * Function to get the initial date and end date (at day level) from any expression
 * @param value Original value
 * @param dateLevel Date Level
 * @param initialDateMask Input Date Mask
 * @param returnDateMask Output Date Mask
 * @param operation Operation applied (predefinedRange, between, equals...)
 * @param timeWindow Window applied (year, quarter, month, week, day)
 * @param prevFunction Calculated field function (PREV_YEAR, PREV_QUARTER, PREV_MONTH, PREV_WEEK, PREV_DAY, PREV_PERIOD)
 * @returns {Object}
 */
export function getDateStartEnd(value, dateLevel, initialDateMask, returnDateMask, operation, timeWindow, prevFunction) {

    let startDate = moment(), endDate = moment(), newOperation = operation, newDisplacedValue = [], newDateLevel;
    let displacement = 1;

    switch (operation) {
        case "predefinedRange":
        case "notPredefinedRange":

            const dates = parsePredefinedOperations(value[0]);
            if (dates.length === 1) {
                startDate = moment(dates[0], initialDateMask).startOf(dateLevel).subtract(1, timeWindow).format("YYYY-MM-DD");
                endDate = moment(dates[0], initialDateMask).subtract(1, timeWindow).endOf(dateLevel).format("YYYY-MM-DD");
            } else if (dates.length > 1) {

                // Calculate how many units of displacement are necessary when applying a "prev_period function
                if (prevFunction === "prev_period") {
                    displacement = moment(dates[1], initialDateMask).diff(moment(dates[0], initialDateMask), `${timeWindow}s`) + 1;
                }

                if (["yearToDate", "quarterToDate", "monthToDate", "weekToDate"].includes(value[0])) {
                    startDate = moment(dates[0], initialDateMask).startOf(dateLevel).subtract(displacement, timeWindow).format("YYYY-MM-DD");
                    endDate = moment(dates[1], initialDateMask).subtract(displacement, timeWindow).endOf("day").format("YYYY-MM-DD");
                } else {
                    startDate = moment(dates[0], initialDateMask).startOf(dateLevel).subtract(displacement, timeWindow).format("YYYY-MM-DD");
                    endDate = moment(dates[1], initialDateMask).subtract(displacement, timeWindow).endOf(dateLevel).format("YYYY-MM-DD");
                }
            }

            newDateLevel = "day";
            newOperation = operation === "predefinedRange" ? "between" : "notBetween";
            newDisplacedValue.push(startDate);
            newDisplacedValue.push(endDate);
            break;
        case "inList":
        case "notInList":
            if (value?.length > 0) {
                value.forEach((v) => {
                    startDate = moment(v, initialDateMask).startOf(dateLevel).subtract(1, timeWindow).format(returnDateMask);
                    endDate = moment(v, initialDateMask).subtract(1, timeWindow).endOf(dateLevel).format(returnDateMask);
                    while(startDate <= endDate) {
                        newDisplacedValue.push(startDate);
                        startDate = moment(startDate, returnDateMask).startOf(timeWindow).add(1, timeWindow).format(returnDateMask);
                    }
                });
            }
            break;
        case "equals":
        case "notEquals":
            startDate = moment(value, initialDateMask).startOf(dateLevel).subtract(1, timeWindow).format(returnDateMask);
            endDate = moment(value, initialDateMask).subtract(1, timeWindow).endOf(dateLevel).format(returnDateMask);
            newOperation = operation === "equals" ? "between" : "notBetween";
            newDisplacedValue.push(startDate);
            newDisplacedValue.push(endDate);
            break;
        case "greaterThan":
        case "smallerOrEqualThan":
            startDate = moment(value, initialDateMask).endOf(dateLevel).subtract(1, timeWindow).format(returnDateMask);
            newDisplacedValue.push(startDate);
            break;
        case "between":
        case "notBetween":

            // Calculate how many units of displacement are necessary when applying a "prev_period function
            if (prevFunction === "prev_period") {
                displacement = moment(value[1], initialDateMask).diff(moment(value[0], initialDateMask), `${timeWindow}s`) + 1;
            }

            startDate = moment(value[0], initialDateMask).startOf(dateLevel).subtract(displacement, timeWindow).format(returnDateMask);
            endDate = moment(value[1], initialDateMask).subtract(displacement, timeWindow).endOf(dateLevel).format(returnDateMask);
            newDisplacedValue.push(startDate);
            newDisplacedValue.push(endDate);
            break;
        default:
            newDisplacedValue.push(moment(value, initialDateMask).startOf(dateLevel).subtract(1, timeWindow).format(returnDateMask));
            break;
    }

    return { newDisplacedValue, newOperation, newDateLevel: newDateLevel || dateLevel  };
}

/**
 * Function to transform predefined operations to its equivalent in the previous period
 * @param {String} value Original value (thisYear, previousMonth, etc...)
 * @returns {Object}
 */
export function parsePredefinedOperations(value) {

    switch (value) {

        // Years
        case 'thisYear':
            return [moment()];
        case 'previousYear':
            return [moment().subtract(1, 'year')];
        case 'previousTwoYears':
            return [moment().subtract(2, 'year'), moment().subtract(1, 'year')];
        case 'twoYearsAgo':
            return [moment().subtract(2, 'year')];
        case 'lastTwoYears':
            return [moment().subtract(1, 'year'), moment()];
        case 'lastThreeYears':
            return [moment().subtract(2, 'year'), moment()];
        case 'yearToDate':
            return [moment().startOf('year'), moment()];

        // Quarters
        case 'thisQuarter':
            return [moment()];
        case 'previousQuarter':
            return [moment().subtract(1, 'quarter')];
        case 'previousTwoQuarters':
            return [moment().subtract(2, 'quarter'), moment().subtract(1, 'quarter')];
        case 'twoQuartersAgo':
            return [moment().subtract(2, 'quarter')];
        case 'threeQuartersAgo':
            return [moment().subtract(3, 'quarter')];
        case 'lastTwoQuarters':
            return [moment().subtract(1, 'quarter'), moment()];
        case 'lastThreeQuarters':
            return [moment().subtract(2, 'quarter'), moment()];
        case 'lastFourQuarters':
            return [moment().subtract(3, 'quarter'), moment()];
        case 'quarterToDate':
            return [moment().startOf('quarter'), moment()];

        // Months
        case 'thisMonth':
            return [moment()];
        case 'previousMonth':
            return [moment().subtract(1, 'month')];
        case 'previousTwoMonths':
            return [moment().subtract(2, 'month'), moment().subtract(1, 'month')];
        case 'twoMonthsAgo':
            return [moment().subtract(2, 'month')];
        case 'threeMonthsAgo':
            return [moment().subtract(3, 'month')];
        case 'lastTwoMonths':
            return [moment().subtract(1, 'month'), moment()];
        case 'lastThreeMonths':
            return [moment().subtract(2, 'month'), moment()];
        case 'lastSixMonths':
            return [moment().subtract(5, 'month'), moment()];
        case 'lastTwelveMonths':
            return [moment().subtract(11, 'month'), moment()];
        case 'monthToDate':
            return [moment().startOf('month'), moment()];

        // Weeks
        case 'thisWeek':
            return [moment()];
        case 'lastWeek':
            return [moment().subtract(1, 'week')];
        case 'previousTwoWeeks':
            return [moment().subtract(2, 'week'), moment().subtract(1, 'week')];
        case 'twoWeeksAgo':
            return [moment().subtract(2, 'week')];
        case 'threeWeeksAgo':
            return [moment().subtract(3, 'week')];
        case 'lastTwoWeeks':
            return [moment().subtract(1, 'week'), moment()];
        case 'lastThreeWeeks':
            return [moment().subtract(2, 'week'), moment()];
        case 'lastFourWeeks':
            return [moment().subtract(3, 'week'), moment()];
        case 'lastSixWeeks':
            return [moment().subtract(5, 'week'), moment()];
        case 'lastEightWeeks':
            return [moment().subtract(7, 'week'), moment()];
        case 'lastTwelveWeeks':
            return [moment().subtract(11, 'week'), moment()];
        case 'weekToDate':
            return [moment().startOf('week'), moment()];

        // Days
        case 'today':
            return [moment()];
        case 'yesterday':
            return [moment().subtract(1, 'day')];
        case 'previousTwoDays':
            return [moment().subtract(2, 'day'), moment().subtract(1, 'day')];
        case 'twoDaysAgo':
            return [moment().subtract(2, 'day')];
        case 'threeDaysAgo':
            return [moment().subtract(3, 'day')];
        case 'currentWeek':
            return [moment().startOf('isoWeek'), moment().endOf('isoWeek')];
        case 'lastSevenDays':
            return [moment().subtract(6, 'day'), moment()];
        case 'lastFourteenDays':
            return [moment().subtract(13, 'day'), moment()];
        case 'lastThirtyDays':
            return [moment().subtract(29, 'day'), moment()];
        case 'lastSixtyDays':
            return [moment().subtract(59, 'day'), moment()];

        default:
            return [moment()];
    }
}

export const OPERATIONS_THAT_ALLOWS_EMPTY_VALUE = ["contains", "notContains", "startsWith", "notStartsWith", "endsWith", "notEndsWith"]

export const EMPTY_VALUE = "\u200A"

export function getCleanFilterValue(value) {
    if (value === EMPTY_VALUE) return ""
    return value
}

export function createNewFilter(currentFilters) {
    const sortedFilters = sortBy(currentFilters, ["name"])

    let newFilterName = `${i18n.t("common.newLabel")} ${i18n.t("filters.filterLabel")}`
    let index = 1

    // Search if there are other filters not renamed
    for (let i = 0; i < sortedFilters.length; i++) {
        if (sortedFilters[i].name.match(new RegExp(`\\${i18n.t("common.newLabel")} ${i18n.t("filters.filterLabel")}\\.*`))) {
            const nameIndex = sortedFilters[i].name.substring(sortedFilters[i].name.indexOf("(") + 1, sortedFilters[i].name.lastIndexOf(")"))
            if (nameIndex) {
                index = parseInt(nameIndex, 10) + 1
            }
            newFilterName = `${i18n.t("common.newLabel")} ${i18n.t("filters.filterLabel")} (${index})`
        }
    }

    // Function that generates a mongoDB objectId
    const mongoObjectId = () => {
        const timestamp = (new Date().getTime() / 1000 | 0).toString(16)
        return timestamp + "xxxxxxxxxxxxxxxx".replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase()
    }

    // Add new filter to filters array with basic initial structure
    return {
        _id: mongoObjectId(),
        name: newFilterName,
        data_type: "string",
        filter_type: "String",
        field_type: null,
        editable: true,
        visible: true,
        multi_value: false,
        mandatory: true,
        stage: "where",
        default_expression: {
            values: [],
            options: {
                limit: "500"
            }
        },
        url_param: {
            enabled: false,
            param_field: null
        },
        use_custom_list: false,
        custom_list: [],
        datamodel: null,
        dataset: null,
        data_field: null,
        cards: [],
        queries: [],
        time_metric: false,
        time_level: null,
        time_format: null,
        isNew: true,
        affected_filters: [],
        filter_use: {
            selection_tab: { show: true, date_level: [] },
            expression_tab: { show: true, operations_allowed: [], date_level: [] },
            top_bottom_tab: { show: false },
            predefined_tab: { show: true, date_level: [] }
        }
    }
}

export function addNewFilter(currentFilters) {
    const newFilters = cloneDeep(currentFilters)

    // Add new filter to filters array with basic initial structure
    newFilters.push(createNewFilter(currentFilters));

    return newFilters;
}

export default {
    getLanguageStringOperation,
    getQueryPropsDatasetDataPolicy,
    getApolloProps,
    filterTypeOptions,
    booleanFilterOptions,
    booleanMultiFilterOptions,
    booleanFilterValuesOptions,
    topBottomFilterOptions,
    textFilterOptions,
    textFilterFieldOptions,
    textMultiFilterOptions,
    numberFilterFieldOptions,
    numberMultiFilterOptions,
    dateFilterOptions,
    dateFilterFieldOptions,
    dateMultiFilterOptions,
    allFilterOperationsOptions,
    allAggregationsOptions,
    dateLevelOptions,
    dateYearFilterOptions,
    dateQuarterFilterOptions,
    dateMonthFilterOptions,
    dateWeekFilterOptions,
    dateDayFilterOptions,
    dateLevelFilterOptions,
    aggregationTextFilterOptions,
    aggregationTextEmptyFilterOptions,
    aggregationNumberFilterOptions,
    aggregationNumberEmptyFilterOptions,
    defaultDate,
    defaultDateFormat,
    defaultDateTime,
    defaultDateTimeFormat,
    defaultTime,
    defaultTimeFormat,
    customOrderMaxValues,
    getFilterValuesFromURLParam,
    getFilterExpressionFromURLParam,
    getDateInputFormat,
    getDateInputMask,
    dateValueInputIsValid,
    dateValueIsValid,
    numberValueIsValid,
    parseDateDataToDateLevel,
    parsePredefinedOperations,
    OPERATIONS_THAT_ALLOWS_EMPTY_VALUE,
    EMPTY_VALUE,
    getCleanFilterValue,
    addNewFilter,
    createNewFilter
};