/**
 * Function that cleans "manual" populates before updating cards depending on card type
 * @param card (object) - Card to clean/update
 * @return {object} - Cleaned card
 */
export function cleanCardBeforeUpdate (card) {
    if (card && card.queries) {
        card.queries = card.queries.map(query => {
            query.fields = query.fields.map(field => {
                field.dataField = field.dataField._id;
                return field;
            });
            return query;
        });
    }

    if (card?.calculated_fields?.length > 0) {
        card.calculated_fields = card.calculated_fields.map(cf => {
            delete(cf.dragDisabled);
            return cf;
        });
    }


    if (card?.card_type === "Chart") {
        for (let metric of card?.visualization?.metrics) {
            if (metric?.palette?._id) metric.palette = metric.palette._id
        }
    }
    if (card?.card_type === "Map") {
        for (let map_layer of card?.visualization?.map_layers) {
            if (map_layer?.color?.palette?._id) map_layer.color.palette = map_layer.color.palette._id
        }
    }

    return card;
}