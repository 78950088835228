import { Map as ImmutableMap, fromJS } from "immutable";

// Actions
import {
    // Interactive Data System
    INITIALIZE_IDS,
    IDS_SET_CONTENT_FILTERS,
    IDS_CLEAN_CONTENT_FILTERS,
    IDS_SET_CONTENT_SORT,
} from './interactive-data-system-actions';


// Initial state
const initialState = ImmutableMap(fromJS({}));


/**
 * Data card editor Reducers
 */
const interactiveDataSystem = (state = initialState, action) => {
    
    let newState = state;
    const configRoute = action?.pageId ? `${action.pageId}__${action.cardId}` : action.cardId;

    switch (action.type) {

        // IDS
        case INITIALIZE_IDS:
            return initialState;

        case IDS_SET_CONTENT_FILTERS:
            const prevFilters = state?.getIn([configRoute, "filters"]) || ImmutableMap({});
            return newState?.setIn([configRoute, "filters"], prevFilters?.merge(action.filters));
        case IDS_CLEAN_CONTENT_FILTERS:
            return newState?.setIn([action.contentId, "filters"], ImmutableMap(fromJS({})));
        case IDS_SET_CONTENT_SORT:
            const prevSort = state?.getIn([configRoute, "sort"]) || ImmutableMap({});
            return newState?.setIn([configRoute, "sort"], prevSort?.merge(action.sort));
        default:
            return newState;
    }

};

export default interactiveDataSystem;