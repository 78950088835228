export default {
    "hello": "HOLA",
    "breadcrumbs": {
        "home": "Inicio",
        "admin": {
            "instance": "Administrar Instancia",
            "organization": "Administrar Organización"
        },
        "userProfile": "Perfil de Usuario"
    },
    "auth": {
        "signupForm": {
            "headerTitle": "Registra tu cuenta de Biuwer",
            "signup": "Registrate",
            "emailNotValidMessage": "El campo email no es válido",
            "termsAndPrivacyMessage": "Acepto las <0>condiciones del servicio</0> y su <1>política de datos</1>",
            "marketingMessage": "Me gustaría recibir actualizaciones, consejos y ofertas vía email",
            "submitButton": "Registrar",
            "signinLabel": "¿Ya tienes una cuenta? Inicia sesión",
            "createDemoContent": "Generar contenido de demostración"
        },
        "signupEmailSent": {
            "almostThereMessage": "¡Ya casi estás!",
            "verifyEmailMessage": "Revisa tu correo para completar el registro",
            "infoSafeMessage": "Para mantener tu información segura, se ha enviado un correo a:"
        },
        "signupConfirmation": {
            "welcome": "Bienvenid@ a Biuwer",
            "welcomeInfo": "Estás a punto de crear la organización",
            "welcomeInfo2": "en la que serás el administrador principal.",
            "headerTitle": "Completa el registro con una contraseña segura",
            "headerFinishTitle": "Estamos terminando de crear tu cuenta en Biuwer",
            "processingSignup": "Procesando el registro...",
            "submitButton": "Completar"
        },
        "signinForm": {
            "headerTitle": "Accede a tu cuenta de Biuwer",
            "signin": "Acceder",
            "forgotPasswordLabel": "¿Has olvidado tu contraseña?",
            "oneTimeAccessLabel": "Solicitar enlace de acceso de un solo uso",
            "emailNotValidMessage": "El campo email no es válido",
            "submitButton": "Acceder",
            "helpChat": "Hablar con soporte",
            "signupLabel": "¿No tienes una cuenta? Registrate"
        },
        "forgotForm": {
            "headerTitle": "Solicitar nueva contraseña",
            "submitButton": "Enviar contraseña",
            "confirmationMessage": "Acabamos de enviarte un correo electrónico con las instrucciones para recuperar tu contraseña"
        },
        "oneTimeForm": {
            "headerTitle": "Solicitar un enlace de acceso",
            "submitButton": "Enviar enlace",
            "helpDescription": "",
            "confirmationMessage": "Comprueba tu email. Te hemos enviado un correo electrónico con un enlace que te permitirá acceder."
        },
        "resetPassword": {
            "headerTitle": "Cambio de contraseña",
            "otpTitle": "Acceso con contraseña de un solo uso",
            "passwordLabel": "Nueva contraseña",
            "reEnterPasswordLabel": "Vuelve a introducir la contraseña",
            "submitButton": "Cambiar contraseña",
            "accessButton": "Acceder",
            "confirmationMessage": "La contraseña se ha cambiado correctamente.",
            "otpConfirmationMessage": "El enlace de acceso es correcto. Accede a la plataforma."
        },
        "welcomeUser": {
            "headerTitle": "Bienvenido a Biuwer",
            "adminAddedYou": "Un administrador te ha invitado a colaborar en la organización {{name}}",
            "completeUserData": "Para continuar es necesario terminar la validadción de tu cuenta de usuario estableciendo una contraseña segura"
        },
        "pageNotFound": {
            "pageNotFoundMessage": "Página no encontrada",
            "goHomePageMessage": "Ir a la página de inicio",
            "goBackMessage": "Volver atrás"
        },
        "forbiddenAccess": {
            "forbiddenAccessMessage": "Acceso denegado"
        },
        "userNameLabel": "Nombre",
        "userSurnameLabel": "Apellidos",
        "organizationNameLabel": "Organización",
        "phoneLabel":"Teléfono",
        "emailLabel": "E-mail",
        "emailTooltipMessage": "Introduce una dirección de correo electrónico",
        "passwordLabel": "Contraseña",
        "validatePasswordLabel": "Repite la contraseña",
        "passwordTooltipMessage": "Introduce tu contraseña",
        "passwordMinLengthMessage": "La longitud de la contraseña debe de ser mayor de 6 caracteres",
        "passwordNotMatchMessage": "Las contraseñas no coinciden",
        "reviewPasswordRequirements": "Revise los requisitos de contraseña",
        "passwordStrength" : {
            "passwordRequirementsMessage": "Requisitos mínimos para la nueva contraseña",
            "minusLabel": "Una letra minúscula",
            "mayusLabel": "Una letra mayúscula",
            "numberLabel": "Un dígito numérico",
            "specialLabel": "Un carácter especial",
            "lengthLabel": "Al menos 7 caracteres"
        },
        "back": "Volver",
        "cancel": "Cancelar",
        "accessSettingsLabel": "Configuración de acceso"
    },
    "admin": {
        "title": "Administración",
        "instance": {
            "title": "Configuración de la Instancia",
            "idLabel": "Id de Instancia",
            "descriptionLabel": "Descripción",
            "createdLabel": "Fecha de instalación",
            "extendTrial": {
                "title": "Extender trial",
                "titleTo": "Extender trial a {{name}}",
                "currentStatus": "Estado actual",
                "currentEndDate": "Fecha de fin actual",
                "trialEndedAt": "Fecha fin de trial",
                "newTrialEndDate": "Nueva fecha fin de trial"
            }
        },
        "organization": {
            "title": "Organización",
            "title_plural": "Organizaciones",
            "orgId": "Id Org.",
            "showOrganizations": "Ver Organizaciones",
            "nameLabel": "Nombre comercial",
            "businessNameLabel": "Nombre fiscal",
            "fiscalIdLabel": "Num. Identificación fiscal",
            "townNameLabel": "Ciudad",
            "provinceNameLabel": "Provincia",
            "postalCodeLabel": "Código postal",
            "countryLabel": "País",
            "fiscalAddressLabel": "Dirección fiscal",
            "typeLabel": "Tipo",
            "defaultLabel": "Organización por defecto",
            "connected": "Conectado",
            "connectedTo": "Conectado a",
            "connect": "Conectar",
            "availableOrgs": "Organizaciones disponibles",
            "selectOrg": "Selecciona organización",
            "switchTo": "Cambiar a",
            "default": "Por defecto",
            "setDefault": "Establecer por defecto",
            "switchOrgTitle": "Cambiar organización",
            "switchOrgModalCloseButtonLabel": "Cerrar",
            "withoutOrganizationLabel": "Sin organización",
            "hideCoreRolesLabel": "Ocultar roles del núcleo",
            "hideCoreRolesDescription": "Si se activa esta opción los usuarios de cada organización no verán los roles del núcleo dentro de su listado de roles",
            "createdLabel": "Fecha de Creación",
            "languageLabel": "Idioma",
            "mainLanguageLabel": "Idioma principal",
            "organizationName": "Nombre de la Organización",
            "primaryContact": "Contacto principal",
            "selectPlanLabel": "Selecciona un plan para continuar usando Biuwer",
            "cancelSubscription": "Cancelar subscripción",
            "paymentPending": "Pago pendiente",
            "language": {
                "*": "Definido por la organización",
                "es": "Español",
                "en": "Inglés"
            },
            "changeStatusTo": "Cambiar estado a",
            "changeTrialStatusTo": "Cambiar estado de trial a",
            "initialStatus": "Estado inicial",
            "statusLabel": "Estado",
            "status": {
                "trial": "Trial",
                "cancelled": "Cancelada",
                "paymentPending": "Pendiente de pago"
            },
            "showIntroContent": "Mostrar contenido de bienvenida",
            "showDemoSpace": "Mostrar espacio de demostración",
            "multiLanguageContent": "Contenido multi idioma",
            "useBrowserLanguage": "Usar idioma del navegador",
            "translationsFor": "Traducciones para",
            "aditionalLanguages": "Idiomas adicionales",
            "availableLanguages": "Idiomas disponibles",
            "noLanguagesConfig": "No hay idiomas configurados",
            "changeContentLanguage": "Cambiar idioma",
            "applyFeatureWall": "Limitar funcionalidades",
            "automaticBilling": "Facturación automática",
            "customStyles": {
                "title": "Estilos personalizados",
                "customStylesWarningMessage": "Los estilos personalizados solo se aplican en los recursos compartidos",
                "disabledCustomStyles": "Estilos personalizados desactivados",
                "enabledCustomStyles": "Estilos personalizados activados",
                "applyToAll": "Aplicar a todo",
                "generalStyles": "Estilos generales",
                "headerStyles": "Estilos de cabeceras",
                "fixHeaderBoxShadowColor": "Color de sombreado en cabecera fija",
                "cardStyles": "Estilos de tarjetas",
                "collectionStyles": "Estilos de colecciones",
                "font": "Fuente",
                "filigreeColor": "Color de filigrana",
                "primaryButton": "Botón principal",
                "secondaryButton": "Botón secundario",
                "primaryButtonColor": "Botón principal",
                "desc": "Descripción",
                "content": "Contenido",
                "tabs": "Pestañas",
                "tabsBackgroundColor": "Color de fondo de pestañas",
                "activeTabColor": "Color de pestaña activa",
                "backgroundColor": "Color de fondo",
                "text": "Texto"
            },
            "themes": {
                "title": "Tema",
                "title_plural": "Temas",
                "builtinTheme": "Tema Predefinido",
                "builtinTheme_plural": "Temas Predefinidos",
                "customTheme": "Tema Personalizado",
                "customTheme_plural": "Temas Personalizados",
                "addTheme": "Crear nuevo Tema",
                "seeUsage": "Ver uso",
                "generalStylesStatus": "Estilos generales {{status}}",
                "collectionStylesStatus": "Estilos de collecciones {{status}}",
                "pageStylesStatus": "Estilos de páginas {{status}}",
                "cardStylesStatus": "Estilos de tarjetas {{status}}",
                "filterStylesStatus": "Estilos de filtros {{status}}",
                "themesListInfo": "Utilice temas para dar estilo y color a su contenido. El sistema de temas le permite aplicar estilos complejos de múltiples y flexibles formas.",
                "themesListBuiltInInfo": "Utilice cualquiera de estos temas predefinidos por Biuwer en su cuenta.",
                "themesListCustomInfo": "Define tus propios temas personalizados para aplicar cualquier estilo a cualquier contenido.",
                "themeEditorInfo": "Los estilos pueden configurarse para aplicar a diferentes areas de contenido, con las siguientes opciones.",
                "inheritedTheme": "Tema heredado",
                "themeForm": {
                    "general": {
                        "appMenuAndDropdowns": "Menús y desplegables de aplicación",
                        "dataWarnings": "Avisos sobre los datos",
                        "informativeText": "Texto informativo",
                        "getDataButton": "Botón de Obtener datos",
                        "configMenuButton": "Botón de Configuración"
                    }
                }
            },
            "colorPalettes": {
                "title": "Paleta de colores",
                "title_plural": "Paletas de colores",
                "builtinColorPalette": "Paleta de colores Predefinida",
                "builtinColorPalette_plural": "Paletas de colores Predefinidas",
                "customColorPalette": "Paleta de colores Personalizada",
                "customColorPalette_plural": "Paletas de colores Personalizadas",
                "addColorPalette": "Create Palete de colores",
                "editColorPalette": "Editar Paleta de colores",
                "seeUsage": "Ver uso",
                "colorPalettesListInfo": "Utilice paletas de colores para definir esquemas de colores recurrentes para sus gráficos, mapas y otros elementos visuales compatibles. Las paletas de colores pueden utilizarse directamente o a través del los temas.",
                "colorPalettesListBuiltInInfo": "Utilice cualquiera de estas paletas de colores predefinidas por Biuwer, listas para usar con su contenido.",
                "colorPalettesListCustomInfo": "Defina sus propias paletas de colores personalizadas para aplicar cualquier combinación de colores a cualquier contenido.",
                "colorsNumber": "# Colores",
                "color": "Color",
                "color_plural": "Colores",
                "paletteTypes": {
                    "qualitative": "Cualitativa",
                    "sequential": "Secuencial",
                    "diverging": "Divergente"
                },
                "edit": {
                    "createTitle": "Crear Paleta de colores",
                    "editTitle": "Editar Paleta de colores",
                    "duplicateTitle": "Duplicar Paleta de colores",
                    "moveColor": "Mover color en la paleta",
                    "removeColor": "Eliminar color de la paleta",
                    "repetitions": "Repeticiones",
                    "repetitionPctDegrade": "% de degradación de color",
                    "repetitionsNumber": "Número de repeticiones",
                    "keyColors": "Colores clave",
                    "startColor": "Color inicial",
                    "middleColor": "Color intermedio",
                    "endColor": "Color final",
                    "numberOfColors": "Número de colores",
                    "defineYour": "Define tus",
                    "ownColors": "Propios colores",
                    "paletteName": "Nombre de la paleta"
                },
                "deleteColorPaletteMessage": "¿Estás seguro de que quieres borrar la paleta de colores <b>\"{{- name}}\"</b>?",
                "inUse": "En uso"
            },
            "customMessages": {
                "title": "Mensaje Personalizado",
                "title_plural": "Mensajes Personalizados",
                "customMessagesInfo": "Los mensajes personalizados permiten incluir textos personalizados incluyendo las traducciones del contenido usando la funcionalidad del Multi Lenguage. Comprueba la descripción de cada mensaje para conocer su uso.",
                "messages": {
                    "noData": {
                        "title": "No hay datos disponibles",
                        "description": "Este mensaje aparece dentro de cada tarjeta cuando no hay datos disponibles para los filtros seleccionados. Esto no es un error, sólo una falta de datos para mostrar."
                    },
                    "forbiddenAccess": {
                        "description": "Este mensaje aparece cuando alguien intenta acceder a cualquier contenido al que no se le permite acceder. Personalice esto para unificar la mensajería con su aplicación."
                    },
                    "queryDataError": {
                        "description": "Este mensaje aparece dentro de cada tarjeta cuando ocurre un error durante la ejecución de consultas de datos. Los errores pueden deberse, por ejemplo, después de cambiar el esquema físico de los conjuntos de datos."
                    },
                    "applicationError": {
                        "title": "Error de aplicación",
                        "description": "Este contenido se muestra al usuario final cuando, por alguna razón, hay un error no controlado en Biuwer. Puedes personalizar los textos, formatos y enlaces."
                    }
                },
                "previewCustom": "Previsualizar personalizado",
                "previewDefault": "Previsualizar por defecto",
                "preview": "Previsualizando",
                "resetDefaultValues": "Reestablecer valores por defecto",
                "link1Text": "Texto enlace 1",
                "link2Text": "Texto enlace 2",
                "link1Url": "Url enlace 1",
                "link2Url": "Url enlace 2",
                "text2": {
                    "title": "Texto título",
                    "message": "Texto mensaje",
                    "messageFormat": "Formato mensaje",
                    "titleFormat": "Formato título"
                },
                "emptyLinkInfo": "Si vacío, volver atrás."
            },
            "deleteOrganizationMessage": "¿Estás seguro? Esta acción iniciará el proceso de eliminación de la organización {{name}}."
        },
        "trial": {
            "status": {
                "trialing": "Trialing",
                "trialingInfo": "Ningún plan seleccionado",
                "converted": "Convertido",
                "convertedInfo": "Plan seleccionado y cargos aceptados",
                "expired": "Expirado",
                "expiredInfo": "El periodo de trial ha expirado"
            },
            "trialPeriod": "Periodo de Trial",
            "trialEndsIn": "El trial termina en",
            "regularUseStartAt": "Uso regular empieza el",
            "trialExpired": "El periodo de trial ha expirado",
            "contactUsP1": "Puede",
            "contactUsP2": "contactarnos",
            "contactUsP3": "para solicitar ayuda u obtener una ampliación del trial",
            "selectPlan": "Por favor selecciona un plan para activar su organización y continuar usando Biuwer",
            "trialWall": {
                "trialExpired": "El periodo de prueba de tu organización ha terminado",
                "SubscribeOrContact": "Por favor configura tu subscripción ahora o chatea con nosotros para obtener una extensión mediante los botones de arriba"
            }
        },
        "billing": {
            "products": "Productos y servicios",
            "subscription": "Suscripción",
            "billingInterval": "Intervalo de facturación",
            "billingIntervals": {
                "month": "Mensual",
                "year": "Anual"
            },
            "defaultPaymentMethods": {
                "wireTransfer": "Transferencia Bancaria",
                "creditCard": "Tarjeta de Crédito",
                "directDebit": "Domiciliación Bancaria"
            },
            "currencies": {
                "usd": "$",
                "usdLabel": "Dólar ($)",
                "usdLabel_plural": "Dólares ($)",
                "eur": "€",
                "eurLabel": "Euro (€)",
                "eurLabel_plural": "Euros (€)",
            },
            "noProductsOrServicesMessage": "Actualmente no hay productos o servicios incluidos en tu suscripción",
            "companyNameAndAddress": "Nombre y dirección fiscal",
            "companyName": "Nombre de la empresa",
            "paymentInformation": "Información sobre pagos",
            "paymentInformationMessage": "Puede elegir su método de pago en cada compra, entre <b>Tarjeta de Crédito o Débito, Domiciliación Bancaria o Transferencia Bancaria</b>, en función de su intervalo de facturación y su país.",
            "LocationCurrencyMessage": "Por su localización, se le facturará en {{currency}}",
            "currentPeriod": "Periodo actual",
            "nextPeriodStartsAt": "Siguiente periodo empieza el",
            "nextInvoiceOn": "Siguiente factura el",
            "productsAndServicesInfo": "Lista de productos y servicios contratados con facturación <b>{{- period}}</b>",
            "billingAmountAndDateLabel": "Se le facturará a su organización {{amount}} el {{date}} (los precios no incluyen ningún impuesto aplicable)",
            "additionalNotes": "Notas adicionales",
            "additionalNotesMessage": "Utilice este campo para añadir cualquier información adicional en sus facturas",
            "businessPurchaseLabel": "¿Es una compra empresarial?",
            "streetAddressLabel": "Dirección fiscal",
            "VATNumberLabel": "Número de IVA",
            "companyNameMessage": "Esta información será incluida en todas las facturas de su organización",
            "billingContacts": "Contactos de facturación",
            "billingContactsMessage": "Estos contactos serán notificados por email de todo lo relacionado con la facturación de su subscripción, además del contacto principal",
            "noBillingContactsMessage": "Actualmente no hay contactos de facturación",
            "addBillingContact": "Añadir contacto de facturación"
        },
        "users": {
            "title": "Usuario",
            "title_plural": "Usuarios",
            "nameLabel": "Nombre",
            "surnamesLabel": "Apellidos",
            "phoneLabel": "Teléfono",
            "emailLabel": "Email",
            "changeEmail": "Cambiar email",
            "passwordLabel": "Contraseña",
            "statusLabel": "Estado",
            "statusActive": "Activo",
            "statusInactive": "Inactivo",
            "createdLabel": "Fecha de Creación",
            "lastSeenAt": "Última conexión",
            "activeUsers": "{{count}} usuario activo",
            "activeUsers_plural": "{{count}} usuarios activos",
            "inactiveUsers": "{{count}} usuario inactivo",
            "inactiveUsers_plural": "{{count}} usuarios inactivos",
            "deletedUsers": "{{count}} usuario eliminado",
            "deletedUsers_plural": "{{count}} usuarios eliminados",
            "listOfLabel": "Listado de usuarios",
            "addUserLabel": "Añadir Usuario",
            "addUserLabel_plural": "Añadir Usuarios",
            "addBasicUser": "Añadir Usuario Básico",
            "addBasicUser_plural": "Añadir Usuarios Básicos",
            "addAdvancedUser": "Añadir Usuario Avanzado",
            "addAdvancedUser_plural": "Añadir Usuarios Avanzados",
            "assignUserLabel": "Asignar Usuario",
            "assignUserLabel_plural": "Asignar Usuarios",
            "definePassword": "Definir contraseña",
            "generateAutomaticPassword": "Generar contraseña automáticamente",
            "confirmPasswordLabel": "Vuelve a introducir la contraseña",
            "assignTemporalPassword": "Se asignará una contraseña temporal",
            "incorrectLengthPasswordMessage": "La contraseña debe tener al menos {{count}} caracteres",
            "checkPasswordRequirementsMessage": "Revise los requisitos de seguridad de la contraseña",
            "notMatchingPasswordsMessage": "Las contraseñas no coinciden",
            "sendEmailToUser": "Enviar email al usuario",
            "successCreatedMessage": "Se ha creado un usuario con el nombre",
            "errorCreatedMessage": "Se ha producido un error al crear el usuario",
            "successUpdatedMessage": "Se ha actualizado correctamente el usuario",
            "errorUpdatedMessage": "Se ha producido un error al actualizar el usuario",
            "sendEmailMessage": "Se ha enviado un correo electrónico con los detalles de acceso a {{email}}",
            "confirmEmailLabel": "Vuelve a introducir el email",
            "incorrectEmailFormatMessage": "El formato del email es incorrecto",
            "notMatchingEmailsMessage": "Los emails no coinciden",
            "userExistMessage": "Ya existe un usuario con este email",
            "deleteConfirmationMessage": "¿Está seguro que desea eliminar el usuario de la organización?",
            "deleteUserMessage": "¿Estás seguro de que quieres eliminar el usuario {{user}}?",
            "deleteUserFromPlatformMessage": "¿Estás seguro de que quieres eliminar el usuario {{user}}? Será eliminado de todas las organizaciones en las que esté incluido y no podrá acceder a la plataforma.",
            "deleteUserSelectMessage": "Selecciona un usuario al que transferir la propiedad de todos sus objetos",
            "deleteUserPersonalSpaceMessage": "Los objetos del espacio personal del usuario a borrar se transferirán al espacio personal del usuario seleccionado.",
            "deleteUserAuthTokensMessage": "Los Tokens de Autenticación asociados al usuario a borrar también se transferirán al usuario seleccionado.",
            "deleteUserObjectProfileMessage": "Se eliminará al usuario de la configuración de acceso de los objetos. Comprueba que los permisos de acceso a contenido y datos son los adecuados.",
            "addUserToOrganizationLabel": "Añadir usuario a la organización",
            "userExistInPlatformMessage": "<0>Ya existe un usuario con este email en la aplicación.</0> Si quiere añadir el usuario a su organización, haga click en el botón verde 'Añadir'",
            "masqueradeUserActAsLabel": "Conectar como",
            "masqueradeUserRecoverMessage": "Modo enmascaramiento. Conectado como",
            "masqueradeUserCloseSessionLabel": "Cerrar sesión",
            "ownerLabel": "Propietario",
            "addEditUsers": "Añadir/Editar Usuarios",
            "activationMethod": "Modo de activación",
            "activateByEmail": "Activar por email",
            "activateNow": "Activar ahora",
            "setPassword": "Establecer contraseña",
            "warningActivateNowMessage": "<0>El nuevo usuario se creará en estado activo,</0> pudiendo usar Biuwer mediante un inicio de sesión o compartiendo contenido con un token de autenticación válido.",
            "warningNewUserMessage": "<0>El nuevo usuario se creará pendiente de activación.</0> Para activarlo es necesario enviarle el email de bienvenida. El usuario establecerá su contraseña.",
            "warningPendingUserMessage": "<0>El usuario se encuentra pendiente de activación.</0> Para activarlo es necesario enviarle el email de bienvenida. El usuario establecerá su contraseña.",
            "warningAddUserActivateNowMessage" : "<0>El usuario se activará tras añadirlo a la organización</0>, pudiendo usar Biuwer mediante un inicio de sesión o compartiendo contenido con un token de autenticación válido.",
            "warningAddUserActivateByEmailMessage" : "<0>El usuario se añadirá a la organización pendiente de activación.</0> Para activarlo es necesario enviarle el email de bienvenida. El usuario establecerá su contraseña.",
            "selectUserType": "Selecciona el tipo de Usuario",
            "basicUserFeatures": "Los usuarios básicos solo pueden ver y analizar datos en Páginas y Tarjetas de Espacios compartidos. No tienen un Espacio Personal ni pueden compartir contenido fuera de Biuwer.",
            "advancedUserFeatures": "Los usuarios avanzados pueden utilizar todas las funcionalidades de Biuwer. Pueden ver, editar y administrar contenido tanto en Espacios compartidos como en su Espacio Personal. También pueden compartir contenido fuera de Biuwer, acceder al Centro de Datos y otras herramientas disponibles en la plataforma.",
            "userTypeLabel": "Tipo de usuario",
            "switchToAdvancedLabel": "Cambiar a Avanzado",
            "switchToBasicLabel": "Cambiar a Básico",
            "showingObjectIncludedInUser": "Mostrando <b>{{- object}}</b> en el que el usuario está incluido",
            "showingObjectIncludedInUser_plural": "Mostrando <b>{{- object}}</b> en los que el usuario está incluido",
            "showingObjectAssignedToUser": "Mostrando <b>{{- object}}</b> asignado al usuario",
            "showingObjectAssignedToUser_plural": "Mostrando <b>{{- object}}</b> asignados al usuario",
            "showingObjectLinkedToUser": "Mostrando <b>{{- object}}</b> vinculado al usuario",
            "showingObjectLinkedToUser_plural": "Mostrando <b>{{- object}}</b> vinculados al usuario",
            "usersListHeaderDescription": "Gestiona <strong>Usuarios Básicos y Avanzados</strong> para darles acceso a la plataforma Biuwer.",
            "noGroupsMessage": "El usuario no está incluido en ningún Grupo",
            "noRolesMessage": "El usuario no tiene asignado ningún Rol",
            "noCustomFieldsMessage": "No existe ningún Campo Personalizado vinculado al usuario",
            "noAuthTokensMessage": "No existe ningún Token de Autenticación vinculado al usuario",
            "deleteGroupFromUserMessage": "¿Estás seguro de que quieres desasignar al usuario del grupo <b>{{- group}}</b>?",
            "deleteRoleFromUserMessage": "¿Estás seguro de que quieres desasignar al usuario del rol <b>{{- role}}</b>?",
            "advancedUsersLabel": "Usuarios Avanzados",
            "advancedUserLabel": "Usuario Avanzado",
            "advancedUserLabel_plural": "Usuarios Avanzados",
            "basicUsersLabel": "Usuarios Básicos",
            "basicUserLabel": "Usuario Básico",
            "basicUserLabel_plural": "Usuarios Básicos",
            "showInstanceUsers": "Mostrar usuarios de instancia",
            "organizationsOfUser": "Organizaciones del Usuario: {{userFullName}}",
            "switchUserType": {
                "main": "¿Estás seguro de realizar esta operación?",
                "details": {
                    "basic": "<div class=\"mb10\">Si confirmas pulsando Aceptar, se va a eliminar el espacio personal del usuario y se van a traspasar todos los objetos de su propiedad al usuario avanzado designado a continuación.</div><div>A partir de ahora, el usuario solamente contará con el permiso Ver allá donde se configure y no podrá compartir contenido fuera de Biuwer.</div>",
                    "advanced": "<div>Si confirmas pulsando Aceptar:</div><ul><li>Se va a crear un espacio personal para el usuario donde será el propietario de los objetos que cree.</li><li>El usuario va a poder tener los niveles de seguridad Ver, Editar y Administrar al configurar la seguridad de los objetos.</li><li>El usuario va a poder tener asignados los Roles disponibles, para facilitar el acceso y gestión de contenidos, datos y la propia Organización.</li></ul>"
                },
                "newOwnerSelect": "Selecciona un usuario al que transferir la propiedad de todos sus objetos:",
            }
        },
        "groups": {
            "title": "Grupo",
            "title_plural": "Grupos",
            "nameLabel": "Nombre",
            "nameOfGroupLabel": "Nombre del grupo",
            "descriptionLabel": "Descripción",
            "statusLabel": "Estado",
            "statusActive": "Activo",
            "statusInactive": "Inactivo",
            "createdLabel": "Fecha de Creación",
            "activeGroups": "{{count}} grupo activo",
            "activeGroups_plural": "{{count}} grupos activos",
            "inactiveGroups": "{{count}} grupo inactivo",
            "inactiveGroups_plural": "{{count}} grupos inactivos",
            "listOfLabel": "Listado de grupos de usuarios",
            "addGroupLabel": "Añadir Grupo",
            "addGroupLabel_plural": "Añadir Grupos",
            "noUsersInGroup": "No hay ningún usuario en este grupo",
            "noRolesInGroup": "No hay ningún rol en este grupo",
            "assignGroupLabel": "Asignar Grupo",
            "assignGroupLabel_plural": "Asignar Grupos",
            "deleteGroupMessage": "¿Estás seguro de que quieres eliminar el grupo <b>\"{{- group}}\"</b>?",
            "coreGroups": {
                "adminGroup": {
                    "name": "Administradores",
                    "desc": "Grupo de administración de la organización"
                },
                "allBasicUsers": {
                    "name": "Todos los usuarios básicos",
                    "desc": "Grupo dínamico con todos los usuarios básicos"
                },
                "allAdvancedUsers": {
                    "name": "Todos los usuarios avanzados",
                    "desc": "Grupo dínamico con todos los usuarios avanzados"
                }
            },
            "groupTypes": {
                "builtin": "Predefinido",
                "custom": "Personalizado"
            },
            "addEditGroups": "Añadir/Editar Grupos",
            "selectGroupType": "Selecciona el tipo de Grupo",
            "groupFeatures": "Un grupo es un contenedor de Usuarios que permite ver, editar y administrar Páginas y Tarjetas de Espacios Compartidos. También puedes asignarle Roles.",
            "groupTypeLabel": "Tipo de grupo",
            "groupsListHeaderDescription": "Administra <b>Grupos</b> para aplicar a una colección de Usuarios Básicos y Avanzados los mismos niveles de acceso y roles.",
            "showingObjectIncludedInGroup": "Mostrando <b>{{- object}}</b> asignados al grupo.",
            "deleteUserFromGroupMessage": "¿Estás seguro de que quieres desasignar del grupo al usuario <b>{{- user}}</b>?",
            "deleteRoleFromGroupMessage": "¿Estás seguro de que quieres desasignar del grupo el rol <b>{{- role}}</b>?",
            "noRolesInGroupMessage": "No hay roles asignados a este grupo.",
            "groupRolesInfoMessage": "Los roles asignados al grupo solo se aplicarán a los usuarios avanzados.",
            "groupAccessInfoMessage": "Se aplicará el nivel de acceso seleccionado a los usuarios avanzados del grupo y el nivel Ver a los usuarios básicos del grupo"
        },
        "roles": {
            "title": "Rol",
            "title_plural": "Roles",
            "nameLabel": "Nombre",
            "descriptionLabel": "Descripción",
            "statusLabel": "Estado",
            "statusActive": "Activo",
            "statusInactive": "Inactivo",
            "createdLabel": "Fecha de Creación",
            "activeRoles": "{{count}} rol activo",
            "activeRoles_plural": "{{count}} roles activos",
            "inactiveRoles": "{{count}} rol inactivo",
            "inactiveRoles_plural": "{{count}} roles inactivos",
            "deletedRoles": "{{count}} rol eliminado",
            "deletedRoles_plural": "{{count}} roles eliminados",
            "noPermissionsInRole": "No hay ningún permiso en este rol",
            "noUsersInRole": "Este rol no está asociado a ningún usuario",
            "noGroupsInRole": "Este rol no está asociado a ningún grupo",
            "assignRoleLabel": "Asignar Rol",
            "assignRoleLabel_plural": "Asignar Roles",
            "listOfLabel": "Listado de roles",
            "showingObjectIncludedInRole": "Mostrando <b>{{- object}}</b> en el que el rol está incluido",
            "showingObjectIncludedInRole_plural": "Mostrando <b>{{- object}}</b> en los que el rol está incluido",
            "showingObjectAssignedToRole": "Mostrando <b>{{- object}}</b> asignado al rol",
            "showingObjectAssignedToRole_plural": "Mostrando <b>{{- object}}</b> asignados a este rol",
            "showingObjectAssociatedToRole": "Mostrando <b>{{- object}}</b> asociado al rol",
            "showingObjectAssociatedToRole_plural": "Mostrando <b>{{- object}}</b> asociados a este rol",
            "showingObjectLinkedToRole": "Mostrando <b>{{- object}}</b> vinculado al rol",
            "showingObjectLinkedToRole_plural": "Mostrando <b>{{- object}}</b> vinculados al rol",
            "deleteUserFromRoleMessage": "¿Estás seguro de que quieres desasignar al usuario <b>{{- user}}</b> del rol?",
            "deleteGroupFromRoleMessage": "¿Estás seguro de que quieres desasignar al grupo <b>{{- group}}</b> del rol?",
            "rolesListHeaderDescription": "Los Roles son colecciones de permisos que permiten a Usuarios Avanzados y Grupos Estándar realizar acciones en Biuwer.",
            "coreRoles": {
                "superAdmin": {
                    "name": "Super administrador de la Organización 1",
                    "desc": "Super administrador de la Organización 1"
                },
                "superViewer": {
                    "name": "Super visor de la Organización 1",
                    "desc": "Super visor de la Organización 1"
                },
                "orgAdmin": {
                    "name": "Administrador de la organización",
                    "desc": "Administra la Organización"
                },
                "contentAdmin": {
                    "name": "Administrador de contenido",
                    "desc": "Administra todos los Espacios, Páginas y Tarjetas"
                },
                "contentEditor": {
                    "name": "Editor de contenido",
                    "desc": "Edita todos los Espacios, Páginas y Tarjetas"
                },
                "contentViewer": {
                    "name": "Visor de contenido",
                    "desc": "Ver todos los Espacios, Páginas y Tarjetas"
                },
                "dataAdmin": {
                    "name": "Administrador de datos",
                    "desc": "Administra Conexiones, Conjuntos de Datos y Modelos"
                },
                "dataEditor": {
                    "name": "Editor de datos",
                    "desc": "Edita Conexiones, Conjuntos de Datos y Modelos"
                },
                "basic": {
                    "name": "Rol básico",
                    "desc": "Acceso y uso básico de Biuwer"
                },
                "jobberAdmin": {
                    "name": "Administrador de Data Prep",
                    "desc": "Administra Trabajos, Pasos, Ejecuciones y Agentes"
                },
                "jobberEditor": {
                    "name": "Editor de Data Prep",
                    "desc": "Edita Trabajos, Pasos, Ejecuciones y Agentes"
                },
                "jobberViewer": {
                    "name": "Visor de Data Prep",
                    "desc": "Ver Trabajos, Pasos, Ejecuciones y Agentes"
                }
            }
        },
        "permissions": {
            "title": "Permiso",
            "title_plural": "Permisos",
            "actionsTitle": "Acción",
            "actionsTitle_plural": "Acciones",
            "listOfLabel": "Listado de permisos",
            "accessLabel": "Acceso",
            "accessSettingsLabel": "Configuración de acceso",
            "currentOwner": "Propietario actual",
            "currentAccess": "Accesos actuales",
            "enterUsersLabel": "Introduzca usuario, grupo o email",
            "viewLabel": "Ver",
            "editLabel": "Editar",
            "adminLabel": "Administrar",
            "customPermissions": "Personalizar permisos",
            "inheritedPermissions": "Permisos de acceso heredados",
            "definedPermissionsSection": "Permisos de acceso definidos aquí",
            "inheritPermissions": "Heredar permisos",
            "applyPermissionsToChildren": "Configurar hijos para que tengan permisos heredados",
            "applyPermissionsToChildrenInfo": "Marcando esta opción, al guardar, se establece en todos los hijos que sus permisos de acceso se heredan de esta configuración",
            "noAccessDefined": "No se ha definido ningún acceso",
            "parentWillBeApplied": "Se aplicarán los permisos del padre al guardar"
        },
        "policies": {
            "dataPoliciesLabel": "Políticas de Datos",
            "enabledDataPolicies": "Políticas de Datos activadas",
            "disabledDataPolicies": "Políticas de Datos desactivadas",
            "addPolicy": "Añadir Política",
            "configurePolicyFilter": "Configurar filtro asociado a la política",
            "configurePolicyGroups": "Grupos asociados a la política",
            "configurePolicyUsers": "Usuarios asociados a la política",
            "selectPolicyGroups": "Selecciona los grupos a los que se aplicará la política",
            "selectPolicyUsers": "Selecciona los usuarios a los que se aplicará la política",
            "newPolicy": "Nueva Política",
            "selectGroup": "Selecciona un grupo",
            "allGroupsSelected": "Todos los grupos han sido seleccionados",
            "noGroupsSelected": "No se ha seleccionado ningún grupo",
            "selectUser": "Selecciona un usuario",
            "allUsersSelected": "Todos los usuarios han sido seleccionados",
            "noUsersSelected": "No se ha seleccionado ningún usuario",
            "noDataPoliciesEnabled": "Este conjunto de datos no tiene activadas las políticas de datos. Cualquier usuario con permiso para ejecutar consultas puede ver todas las filas.",
            "noDataPolicies": "Este conjunto de datos no tiene ninguna política de datos definida. Pulsa el botón AÑADIR POLÍTICA para configurar políticas de datos.",
            "deleteMessage": "¿Estás seguro de que quieres eliminar la política de datos <b>\"{{- dataPolicy}}\"</b>?",
            "applyAlways": "Forzar",
            "applyAlwaysInfo": "Si se fuerza la aplicación de una Política de Datos, se incluirá este Conjunto de Datos en cualquier consulta que los Usuarios y Grupos configurados realicen a través de cualquier Modelo de Datos en que este Conjunto de Datos haya sido incluido.",
            "dataPoliciesMode": "Comportamiento entre políticas:",
            "dataPoliciesModeTooltip": {
                "info": "Se aplicarán los filtros de las políticas a los usuarios y grupos configurados, pudiendo devolver resultados que cumplan:",
                "orInfo": "<b>OR:</b> <b><u>ALGUNO</u></b> de los filtros",
                "andInfo": "<b>AND:</b> <b><u>TODOS</u></b> los filtros"
            }
        },
        "customFields": {
            "title": "Campo personalizado",
            "title_plural": "Campos personalizados",
            "simpleFields": "Campos simples",
            "groupOfFields": "Grupos de campos",
            "createDialogTittle": "Crear campo personalizado",
            "updateDialogTittle": "Actualizar campo personalizado",
            "createFieldDialogTittle": "Crear campo simple",
            "createGroupDialogTittle": "Crear grupo de campos",
            "updateFieldDialogTittle": "Actualizar campo simple",
            "updateGroupDialogTittle": "Actualizar grupo de campos",
            "addFieldToGroup": "Añadir campo personalizado al grupo",
            "addField": "Añadir campo",
            "subfieldsEmpty": "Lista de subcampos vacia",
            "createSubfield": "Crear subcampo",
            "updateSubfield": "Actualizar subcampo",
            "subfields": "Subcampos",
            "subfieldsList": "Lista de subcampos",
            "enumPlaceholder": "Separar valores con punto y coma ';' ...",
            "enumerateMsg": "Enumerar valores permitidos",
        }
    },
    "user": {
        "profile": {
            "title": "Perfil de usuario",
            "userTitle": "Perfil de usuario: {{name}}",
            "nameLabel": "Nombre",
            "surnamesLabel": "Apellidos",
            "phoneLabel": "Teléfono",
            "emailLabel": "Email",
            "passwordLabel": "Contraseña",
            "changePassword": "Cambiar contraseña",
            "currentPasswordLabel": "Contraseña actual",
            "currentPasswordTooltipMessage": "Introduce tu contraseña actual",
            "changePasswordTooltipMessage": "Introduce una nueva contraseña",
            "reEnterPasswordLabel": "Vuelve a introducir la contraseña",
            "editButton": "Editar perfil",
            "cancelButton": "Cancelar",
            "saveButton": "Guardar",
            "nameInvalidMessage": "El campo nombre no puede estar vacío",
            "emailInvalidMessage": "El campo email no es válido",
            "emailExistsMessage": "El email ya existe",
            "infoHeadingLabel": "Información del perfil",
            "userLabel": "Usuario",
            "userLabel_plural": "Usuarios",
            "userNameLabel": "Nombre de usuario"
        }
    },
    "menus": {
        "dataCenter": {
            "connections": {
                "title": "Conexiones",
                "myConnections": "Mis conexiones",
                "connectionCatalog": "Catálogo de conexiones",
                "newConnection": "Nueva Conexión",
                "editConnection": "Editar Conexión",
                "reverseEngineering": "Ingeniería inversa"
            },
            "dataAndModels": {
                "title": "Datos y Modelos",
                "datasets": "Conjuntos de Datos",
                "datamodels": "Modelos de Datos"
            },
            "dataPreparation": {
                "title": "Data Preparation",
                "jobs": "Trabajos",
                "steps": "Pasos",
                "executions": "Ejecuciones",
                "agents": "Agentes",
                "stepCatalog": "Catálogo de Pasos"
            },
            "dataWarehouse": {
                "title": "Data Warehouse",
                "configuration": "Configuración",
                "schemas": "Esquemas",
                "security": "Seguridad",
                "accessAndAPI": "Acceso y API"
            }
        },
        "administration": {
            "generalInfo": {
                "title": "Información General",
                "overview": "Visión General",
                "orgDetails": "Detalles de la Organización"
            },
            "usageAndBilling": {
                "title": "Uso y Facturación",
                "usage": "Uso",
                "statistics": "Estadística",
                "plans": "Planes",
                "orderHistory": "Historial de Pedidos",
                "paymentInfo": "Información del Pago",
                "orderDetails": "Detalles del Pedido"
            },
            "settings": {
                "title": "Ajustes",
                "mapCatalog": "Catalogo de mapas",
                "localization": "Localización",
                "customFields": "Campos Personalizados",
                "regionalSettings": "Configuración Regional",
                "generalSettings": "Configuración General",
                "contentSettings": "Configuración de Contenidos",
                "languageSettings": "Configuración de Lenguajes"
            },
            "security": {
                "title": "Seguridad",
                "authSettings": "Ajustes de autenticación",
                "users": "Usuarios",
                "groups": "Grupos",
                "roles": "Roles",
                "authTokens": "Tokens de Autenticación",
                "apiKeys": "API Keys"
            },
            "content": {
                "title": "Contenido",
                "sharedResources": "Recursos Compartidos"
            }
        },
        "supportChatButton":"Hablar con soporte",
        "user": {
            "configInstance": "Configurar Instancia",
            "configOrganization": "Mi Organización",
            "switchOrganization": "Cambiar Organización",
            "profile": "Mi perfil",
            "logout": "Salir"
        },
        "instanceAdmin": {
            "title": "Instancia",
            "generalInfo": {
                "title": "Información General",
                "organizations": "Organizaciones",
                "users": "Usuarios"
            }
        }
    },
    "content": {
        "share": {
            "share": "Compartir",
            "accessType": "Tipo de acceso",
            "validityPeriod":"Periodo de validez",
            "deleteResourceToken": "Dejar de compartir",
            "noResourceTokenFound": "Actualmente no se está compartiendo el {{object}}",
            "noResourceTokenFound_female": "Actualmente no se está compartiendo la {{object}}",
            "resourceTokenFound": "El {{object}} se está compartiendo",
            "resourceTokenFound_female": "La {{object}} se está compartiendo",
            "resourceTokenUrlCode": "URL para compartir:",
            "resourceTokenSecureFrameCode": "IFRAME para insertar:",
            "frameSize": "Tamaño en pixels:",
            "resourceId": "Id del recurso:",
            "authTokenNeeded": "AQUÍ_DEBE_PONER_UN_AUTH_TOKEN",
            "deleteResourceTokenMessage": "¿Está seguro de que desea dejar de compartir el {{object}}? Dejará de estar disponible fuera de Biuwer",
            "deleteResourceTokenMessage_female": "¿Está seguro de que desea dejar de compartir la {{object}}? Dejará de estar disponible fuera de Biuwer",
            "sharedResource": "Recurso compartido"
        },
        "exports": {
            "exportToPdf": "Exportar a PDF",
            "export": "Exportar",
            "formatSelectLabel": "Selecciona el formato",
            "pageStructureLabel": "Estructurado",
            "pageStructureDescription": "Imprime la página manteniendo la estructura de su contenido.",
            "pageStructureWarning": "IMPORTANTE: El resultado puede variar en función del tamaño y la orientación seleccionados, causando cortes no deseados en las tarjetas (especialmente en gráficos y en tablas con una gran cantidad de datos).",
            "pageSequentialLabel": "Secuencial",
            "pageSequentialDescription": "Imprime cada elemento contenido en la página de forma sucesiva.",
            "numPageLabel": "Página",
            "numTotalPageLabel": "Página/Total",
            "cardAdjustLabel": "Ajustar a tamaño disponible",
            "includeHeader": "Incluir cabecera de contenido",
            "includeFilters": "Incluir área de filtros",
            "unrollCardContent": "Incluir contenido visible y no visible"
        },
        "imports": {
            "file": "Fichero",
            "file_plural": "Ficheros",
            "fileTypeLabel": "Tipo de fichero",
            "uploadFileLabel": "Subir fichero",
            "deleteFileLabel": "Eliminar fichero",
            "validFileTypesLabel": "Tipos de fichero admitidos: {{types}}",
            "mapFields": "Mapear campos",
            "selectSheet": "Selecciona una hoja",
            "sheetSelection": "Seleccion de hoja",
            "from": "Desde",
            "to": "Hasta",
            "selectRange": "Seleccionar rango",
            "column": "Columna",
            "row": "Fila",
            "rowRangeFormat": "Formato filas: Números enteros (1, 2, 3)",
            "columnRangeFormat": " Formato columnas: Letras mayúsculas (A, B, C)",
            "steps": {
                "fileSelect": "Selección de fichero a importar",
                "datasetConfig": "Configuración del Conjunto de Datos",
                "mappingFieldsConfig": "Configuración de Mapeo de campos",
                "noDatasetSelectedMessage": "Selecciona un Conjunto de Datos en el paso anterior",
                "addFieldMessage": "Añade campos al nuevo Conjunto de datos",
                "setDatasetNameMessage": "Añade un nombre al nuevo Conjunto de datos en el paso anterior"
            }
        },
        "moveDuplicate": {
            "pageChildrenSelect": "Selecciona qué hacer con las tarjetas de la página",
            "newParentSelect": "Selecciona el espacio destino",
            "moveLabel": "Mover",
            "duplicateLabel": "Duplicar",
            "moveObject": "Mover {{object}}",
            "duplicateObject": "Duplicar {{object}}",
            "childActions": {
                "none": "Borrar de la página",
                "noneExplanation": "Se moverá la página sin su contenido. Las tarjetas asociadas a esta página se mantendrán en su espacio actual.",
                "move": "Mover",
                "moveExplanation": "Las tarjetas que sólo se utilicen en esta página se moverán, aquellas que se utilicen en más de una página del espacio actual se duplicarán en el espacio destino.",
                "duplicate": "Duplicar",
                "duplicateExplanation": "Se duplicarán todas las tarjetas de la página en el espacio destino."
            },
            "pageMoveWarning": "Mover y duplicar sólo se realizará sobre las tarjetas que tengas permiso de edición o superior, el resto se eliminarán de la estructura de la página"
        },
        "canDelete": {
            "dependenciesFoundLabel": "Dependencias encontradas",
            "checkingDependenciesLabel": "Comprobando dependencias",
            "deletionNotAllowed": "No tienes permiso para eliminar el {{object}}",
            "deletionNotAllowed_female": "No tienes permiso para eliminar la {{object}}",
            "objectNotFound": "{{object}} no encontrado"
        }
    },
    "filters": {
        "dates": {
            "from": "Desde",
            "to": "Hasta",
            "fromTo": "Desde/Hasta",
            "ranges": "Rangos",
            "predefined": "Predefinidos",
            "notPredefined": "No predefinidos",
            "level": "Nivel",
            "year": "Año",
            "year_plural": "Años",
            "allYears": "Todos los años",
            "quarter": "Trimestre",
            "quarter_plural": "Trimestres",
            "allQuarters": "Todos los trimestres",
            "quarterSymbol": "T",
            "month": "Mes",
            "month_plural": "Meses",
            "allMonths": "Todos los meses",
            "week": "Semana",
            "week_plural": "Semanas",
            "allWeeks": "Todas las semanas",
            "day": "Día",
            "day_plural": "Días",
            "hourOfDay": "Hora del Día",
            "hourOfDay_plural": "Horas del Día",
            "allDays": "Todos los días",
            "inRange": "En el rango",
            "thisYear": "Este año",
            "previousYear": "El año anterior",
            "previousTwoYears": "Los 2 años anteriores",
            "twoYearsAgo": "Hace 2 años",
            "lastTwoYears": "Los últimos 2 años",
            "lastThreeYears": "Los últimos 3 años",
            "thisQuarter": "Este trimestre",
            "previousQuarter": "El trimestre anterior",
            "previousTwoQuarters": "Los 2 trimestres anteriores",
            "twoQuartersAgo": "Hace 2 trimestres",
            "threeQuartersAgo": "Hace 3 trimestres",
            "lastTwoQuarters": "Los últimos 2 trimestres",
            "lastThreeQuarters": "Los últimos 3 trimestres",
            "lastFourQuarters": "Los últimos 4 trimestres",
            "thisMonth": "Este mes",
            "previousMonth": "El mes anterior",
            "previousTwoMonths": "Los 2 meses anteriores",
            "twoMonthsAgo": "Hace 2 meses",
            "threeMonthsAgo": "Hace 3 meses",
            "lastTwoMonths": "Los últimos 2 meses",
            "lastThreeMonths": "Los últimos 3 meses",
            "lastSixMonths": "Los últimos 6 meses",
            "lastTwelveMonths": "Los últimos 12 meses",
            "today": "Hoy",
            "yesterday": "Ayer",
            "previousTwoDays": "Los 2 días anteriores",
            "twoDaysAgo": "Hace 2 días",
            "threeDaysAgo": "Hace 3 días",
            "currentWeek": "Esta semana",
            "lastSevenDays": "Los últimos 7 días",
            "lastFourteenDays": "Los últimos 14 días",
            "lastThirtyDays": "Los últimos 30 días",
            "lastSixtyDays": "Los últimos 60 días",
            "lastHour": "Última hora",
            "lastTwoHours": "Últimas 2 horas",
            "lastThreeHours": "Últimas 3 horas",
            "lastFourHours": "Últimas 4 horas",
            "lastSixHours": "Últimas 6 horas",
            "lastEightHours": "Últimas 8 horas",
            "lastTwelveHours": "Últimas 12 horas",
            "lastTwentyFourHours": "Últimas 24 horas",
            "thisWeek": "Esta semana",
            "lastWeek": "La semana anterior",
            "previousTwoWeeks": "Las dos semanas anteriores",
            "twoWeeksAgo": "Hace 2 semanas",
            "threeWeeksAgo": "Hace 3 semanas",
            "lastTwoWeeks": "Las últimas 2 semanas",
            "lastThreeWeeks": "Las últimas 3 semanas",
            "lastFourWeeks": "Las últimas 4 semanas",
            "lastSixWeeks": "Las últimas 6 semanas",
            "lastEightWeeks": "Las últimas 8 semanas",
            "lastTwelveWeeks": "Las últimas 12 semanas",
            "yearToDate": "Este año hasta la fecha",
            "quarterToDate": "Este trimestre hasta la fecha",
            "monthToDate": "Este mes hasta la fecha",
            "weekToDate": "Esta semana hasta la fecha"
        },
        "numbers": {
            "greaterThan": "Mayor que",
            "greaterOrEqualThan": "Mayor o igual que",
            "smallerThan": "Menor que",
            "smallerOrEqualThan": "Menor o igual que",
            "equalThan": "Igual que",
            "equals": "Igual que",
            "notEqualThan": "Distinto de",
            "notEquals": "Distinto de",
            "between": "Entre",
            "notBetween": "No entre",
        },
        "strings": {
            "startsWith": "Empieza por",
            "notStartsWith": "No empieza por",
            "endsWith": "Termina por",
            "notEndsWith": "No termina por",
            "contains": "Contiene",
            "notContains": "No contiene"
        },
        "boolean": {
            "true": "Verdadero",
            "false": "Falso"
        },
        "filterType": {
            "date": "Fecha",
            "string": "Texto",
            "number": "Número",
            "field": "Campo",
            "boolean": "Booleano"
        },
        "formatHelp": {
            "year": "Formato: AAAA",
            "quarter": "Formato: AAAA-T",
            "month": "Formato: AAAA-MM",
            "week": "Formato: AAAA-SS",
            "day": "Formato: DD/MM/AAAA",
            "hour_of_day": "Formato: HH"
        },
        "formatHelpVT": {
            "year": "Formato: AAAA",
            "quarter": "Formato: T",
            "month": "Formato: MM",
            "week": "Formato: SS",
            "year_quarter": "Formato: AAAA-TT",
            "year_month": "Formato: AAAA-MM",
            "year_week": "Formato: AAAA-SS",
            "full_date": "Formato: AAAA-MM-DD",
            "full_date_time": "Formato: AAAA-MM-DD HH:mm:ss",
            "hour_of_day": "Formato: HH"
        },
        "urlParam": {
            "title": "Parámetro URL",
            "description": "El valor del filtro lo define el parámetro de la URL indicado a continuación, cuando éste se encuentre presente y su valor sea del tipo de dato configurado en el filtro. El valor del filtro no será editable por el usuario.",
            "paramLabel": "Parámetro"
        },
        "openFiltersArea": "Abrir área de filtros",
        "closeFiltersArea": "Cerrar área de filtros",
        "cleanFilters": "Limpiar filtros",
        "operationLabel": "Operación",
        "operationLabel_plural": "Operaciones",
        "excludeLabel": "Excluir",
        "between": "Entre",
        "notBetween": "No entre",
        "inList": "En la lista",
        "notInList": "No en la lista",
        "equals": "Igual a",
        "notEquals": "No igual a",
        "selection": "Selección",
        "expression": "Expresión",
        "aggregationLabel": "Agregación",
        "topLabel": "Primeros",
        "top": "Primeros",
        "notTop": "No primeros",
        "bottom": "Últimos",
        "notBottom": "No últimos",
        "interval": "Intervalo",
        "notInterval": "No intervalo",
        "isNull": "Es nulo",
        "isNotNull": "No es nulo",
        "aggregateBy": "Agregar por",
        "filterLabel": "filtro",
        "filterLabel_plural": "filtros",
        "filtersAreaLabel": "Área de filtros",
        "fieldsSelection": "Selección de campos",
        "selectField": "Selecciona campo",
        "noData": "No hay datos disponibles para el campo seleccionado",
        "noDataFilters": "No hay datos disponibles con los filtros seleccionados",
        "allValues": "Todos los valores",
        "multivalued": "Varios valores",
        "typeHere": "Escribe aquí",
        "startTyping": "Escribe para buscar",
        "filterValuesThat": "Filtrar valores en los que",
        "preview": "Vista previa",
        "showingPartialFromTotal": "Mostrando {{partial}} de {{total}} resultados",
        "configureFilter": "Configura el filtro",
        "condition": "Condición",
        "useCustomList": "Lista personalizada",
        "editableLabel": "Editable",
        "mandatoryLabel": "Obligatorio",
        "multiValueLabel": "Permitir múltiples valores",
        "visibleLabel": "Visible",
        "cardsToUpdateInfo": "Selecciona las tarjetas a las que afecta este filtro",
        "cardsToUpdateIndividual": "Seleccionar tarjetas y aplicar configuración individualmente",
        "cardsToUpdateInBlock": "Aplicar en bloque",
        "applySameConfigToAllCards": "Aplicar la misma configuración a todas las tarjetas",
        "cardsToUpdateLabel": "Tarjetas afectadas",
        "filtersToUpdateLabel": "Filtros dependientes",
        "filtersToUpdateInfo": "Selecciona los filtros que son dependientes de este filtro",
        "filterUse": "Uso del filtro",
        "noFilterToUpdateInfo": "Este filtro no se puede seleccionar. No es de tipo Campo",
        "dependentFiltersMustBeField": "Solo los filtros de tipo Field pueden ser dependientes" ,
        "dependencyAlreadyExists": "Ya existe una relación con este filtro",
        "autoApplyUniqueValue": "Auto aplicar al recibir un único valor",
        "showSelectionTab": "Mostrar pestaña SELECCIÓN",
        "showExpressionTab": "Mostrar pestaña EXPRESIÓN",
        "showPredefinedTab": "Mostrar pestaña PREDEFINIDOS",
        "showTopBottomTab": "Mostrar pestaña PRIMEROS",
        "dateLevel": "Nivel de fecha",
        "allowedOperations": "Operaciones",
        "customizeFilterLabel": "Personalizar Filtro",
        "addEditFilterDialogLabel": "Añadir/editar filtros de {{object}}",
        "cardFiltersApplied": "Filtros aplicados a esta tarjeta:",
        "queryUpdatedLabel": "Última actualización: ",
        "queryUpdatedNowLabel": "Ahora mismo",
        "queryUpdatedTimeLabel": "Hace {{qty}} {{unit}}",
        "selectValues": "Elige valores",
        "multipleValuesHelp": "Puedes añadir múltiples valores escribiéndolos separados por ; (punto y coma)",
        "noFilterLabel": "Sin filtro",
        "noFilterLabel_plural": "Sin filtros",
        "noFiltersConfigured": "Ningún filtro configurado",
        "FiltersConfigured": "Filtros configurados",
        "showingFiltersFromPageLabel": "Filtros de página",
        "pageFiltersInfoLabel": "Área de filtros de la página seleccionada",
        "cardFiltersInfoLabel": "Área de filtros de la tarjeta",
        "cardWithNoFiltersLabel": "La tarjeta no tiene configurado ningún filtro",
        "filterWithNoCardsLabel": "Hay algún filtro sin la sección de TARJETAS AFECTADAS completada. ¿Estás seguro de guardar?",
        "addFilterLabel": "Añadir Filtro",
        "stageLabel": "Aplicar despúes de agrupar",
        "stageLabelInfo": "Solo se aplicará sobre campos de tipo métrica",
        "noFiltersApplied": "Esta tarjeta no tiene filtros aplicados",
        "cardFiltersModified": "Se han modificado los filtros de esta tarjeta.",
        "pageFiltersModified": "Se han modificado los filtros de esta página.",
        "clickPreviewToUpdate": "Pulsa en el botón Previsualizar para actualizar la visualización de datos.",
        "selectFilter": "Elige un filtro",
        "order": {
            "customOrderConfig": "Configurar orden"
        },
        "sort": {
            "sortValues": "Ordenar valores",
            "customOrderConfig": "Configurar orden",
            "customOrderInfo": "Para aplicar una ordenación personalizada, el número de valores no debe ser superior a {{qty}}",
            "otherField": "Otro campo"
        },
        "optionsTabLabel": "Opciones y Uso",
        "filterOptions": "Opciones del filtro",
        "filtersEditor": "Editor de filtros",
        "numRecords": "# Registros",
        "orderLabel": "Orden",
        "visibleEditableInfo": "Si el filtro no es visible o editable, se aplicará el valor por defecto o si se configura un parámetro de URL el valor que le llegue por URL o navegación guiada",
        "dateValueInputInfo": "Según el nivel de fecha elegido, estos son los formatos de fecha: Año (YYYY), Trimestre (YYYY-T), Mes (YYYY-MM) y Día (YYYY-MM-DD).",
        "allowedValuesInfo": "Pulsa TAB o ENTER para confirmar. Puedes usar el carácter \";\" para separar valores.",
        "allowedValuesDateInfo": "El formato de fecha manual debe ser YYYY-MM-DD en todos los casos.",
        "groups": {
            "label": "Grupos de Filtros",
            "new": "Nuevo Grupo",
            "create": "Nuevo Grupo de Filtros",
            "edit": "Editor de Grupo de Filtros",
            "addGroup": "Añadir Grupo",
            "addFilter": "Añadir Filtro",
            "showGroup": "Mostrar Grupo de Filtros",
            "showLogicalOperator": "Mostrar operador lógico (AND, OR) entre los Filtros del Grupo",
            "showNameAndColor": "Mostrar nombre y color de Grupo de Filtros",
            "nameInfo": "El nombre se mostrará encima de todos los filtros contenidos en el grupo de filtros, utilizando el formato de texto y el color especificados.",
            "colorInfo": "El color y la fuente también se utilizarán para personalizar el contenido de cada filtro.",
            "groupsInfo": "Sólo se aplica a las conexiones con bases de datos compatibles con SQL.",
            "deleteMessage": "¿Estás seguro de que quieres eliminar el grupo de filtros <b>\"{{- name}}\"</b>? Esta operación borrará también los filtros incluidos en el grupo.",
        }
    },
    "authTokens": {
        "title": "Token de Autenticación",
        "title_plural": "Tokens de Autenticación",
        "deleteAuthTokenMessage": "¿Estás seguro de que quieres eliminar el token de autenticación <br> <b>\"{{- authToken}}\"</b> perteneciente al usuario <b>\"{{user}}\"</b>?"
    },
    "apiKeys": {
        "title": "API Key",
        "title_plural": "API Keys",
        "deleteApiKeyMessage": "¿Estás seguro de que quieres eliminar el API Key <br><b>\"{{- apiKey}}\"</b>?"
    },
    "templates": {
        "templateLabel": "Plantilla",
        "createTemplate": "Crear plantilla",
        "createTemplateBasedOn": "Crear plantilla basada en",
        "content": "Contenido",
        "createDefaultContent": "Crear contenido predefinido"
    },
    "spaces": {
        "title": "Espacio",
        "title_plural": "Espacios",
        "createSpace": "Crear espacio",
        "editSpace": "Editar espacio",
        "deleteSpaceMessage": "¿Estás seguro de que quieres eliminar el espacio <b>\"{{- space}}\"</b>?",
        "cantDeleteSpaceMessage": "No se puede eliminar el espacio <b>\"{{- space}}\"</b>",
        "cantDeleteSpaceMessage_plural": "No se pueden eliminar los espacios",
        "personalSpace": "Mi espacio",
        "personalSpaces": "Espacios personales",
        "sharedSpaces": "Espacios compartidos",
        "selectSpace": "Selecciona espacio",
        "spaceNotValid": "Espacio no válido",
        "selectValidSpace": "Selecciona un espacio en el menú lateral",
    },
    "collections": {
        "collectionsLabel": "Colección",
        "collectionsLabel_plural": "Colecciones",
        "deleteCollectionMessage": "¿Estás seguro de que quieres eliminar la colección <b>\"{{- collection}}\"</b>?",
        "itemsLabel": "Elementos",
        "contentLabel": "Contenido",
        "withoutTitleLabel": "Colección sin título",
        "createCollection": "Crear colección",
        "editCollection": "Editar colección",
        "contentSettings": "Administrar contenido",
        "contentSettings_large": "Configura el número de elementos y su contenido en la colección",
        "noItemsInCollection": "Actualmente no hay elementos en la colección",
        "generalSettings": "Configuración general",
        "collectionGeneralSettings": "Configuración general de la colección",
    },
    "pages": {
        "interactiveFlatTable": "Tabla plana interactiva",
        "nameLabel": "Página",
        "nameLabel_plural": "Páginas",
        "createPage": "Crear página",
        "editPage": "Editar página",
        "structureLabel": "Estructura",
        "editModeLabel": "Modo edición",
        "pagesList": "Listado de páginas",
        "deletePageMessage": "¿Estás seguro de que quieres eliminar la página <b>\"{{- page}}\"</b>?",
        "cantDeletePageMessage": "No se puede eliminar la página <b>\"{{- page}}\"</b>",
        "cantDeletePageMessage_plural": "No se pueden eliminar las páginas",
        "withoutTitleLabel": "Página sin título",
        "pageFiltersLabel": "Filtros de página",
        "structureAndContentLabel": "Estructura y contenido",
        "showFiltersArea": "Mostrar área de filtros",
        "autoApplyFilters": "Aplicar filtros automáticamente",
        "generalTabTitle": "General",
        "styleTabTitle": "Estilo y formato",
        "pageNoContent": "Esta página no tiene contenido.",
        "pageStructureSelect": "Comienza eligiendo una estructura predefinida.",
        "pageJustCreatedMessage": "Acabas de crear esta página.",
        "nextStepsMessage": "Siguientes pasos:",
        "noContent": "Actualmente esta página no contiene ninguna tarjeta",
        "noContentAccess": "Actualmente tu usuario no tiene acceso a ninguna tarjeta de esta página",
        "addContent": "Añadir contenido",
        "editNameAndDesc": "También puedes editar el nombre y la descripción"
    },
    "cards": {
        "cardsLabel": "Tarjeta",
        "cardsLabel_plural": "Tarjetas",
        "cardDescription": "Descripción de la tarjeta",
        "openFullScreen": "Mostrar tarjeta maximizada",
        "appliedFilters": "Filtros aplicados",
        "cardOptions": "Opciones de la tarjeta",
        "cardConfigError": "Hay un error en la configuración de la tarjeta.",
        "cardsCatalog": "Catálogo de tarjetas",
        "newCard": "Nueva tarjeta",
        "dataCardEditor" :"Editor de tarjetas",
        "assignExistentCard": "Añadir tarjeta existente",
        "createCard": "Crear tarjeta",
        "editCard": "Editar tarjeta",
        "showTitle": "Mostrar título",
        "noContentAccess": "Actualmente tu usuario no tiene acceso a esta tarjeta",
        "deleteCardMessage": "¿Estás seguro de que quieres eliminar la tarjeta <b>\"{{- card}}\"</b>?",
        "cantDeleteCardMessage": "No se puede eliminar la tarjeta <b>\"{{- card}}\"</b>",
        "cantDeleteCardMessage_plural": "No se pueden eliminar las tarjetas",
        "cardStatusManagedInSpace": "El estado de la tarjeta puede ser cambiado en su espacio",
        "dataPreview": "Previsualización de datos",
        "underlyingData": "Datos subyacentes",
        "queryString": "Código de la consulta",
        "queryStringError": "Para ver el código de la consulta debes seleccionar un modelo de datos y añadir al menos un campo",
        "dataCard": "Tarjeta de datos",
        "showFooter": "Mostrar pie de página",
        "showRowLimitWarning": "Mostrar aviso de límite de filas alcanzado",
        "fixedHeader": "Mantener cabecera arriba",
        "fixedFilters": "Mantener filtros arriba",
        "fixedNavigation": "Mantener barra de navegación arriba",
        "cardType": "Tipo de tarjeta",
        "cardsList": "Listado de tarjetas",
        "cardFilters": "Filtros de tarjeta",
        "interactiveFilters": "Filtros interactivos",
        "cardTheme": "Aspecto de tarjeta",
        "richTextLabel": "Texto enriquecido",
        "crossTableLabel": "Tabla cruzada",
        "verticalTableLabel": "Tabla vertical",
        "chartLabel": "Gráfico",
        "kpiLabel": "KPI",
        "KpiLayoutConfig": "Configurar plantilla de KPI",
        "KpiLayoutConfigHelp": "Selecciona como se alinearán los KPI en cada una de la ubicaciones (sólo disponible cuando una ubicación tiene más de un KPI).",
        "gaugeLabel": "Calibre",
        "indicatorLabel": "Indicador",
        "indicatorsLabel": "Indicadores",
        "indicatorConfig": "Config. indicador",
        "indicatorLocation": "Ubicación de indicadores",
        "styleConfig": "Config. estilo",
        "cardChanges": "Se han producido cambios en la configuración de la tarjeta.",
        "cardChangesClick": "Pulsa en Previsualizar para mostrar los resultados actualizados.",
        "cardDataNotSaved": "Hay cambios sin guardar, si confirma se perderán los cambios",
        "changeCardType": {
            "confirm": "Si confirmas pulsando Aceptar, se va a",
            "next": "y a continuación, podrás configurar la visualización de la tarjeta desde el inicio.",
            "subTitle": "Cambiar el tipo de Tarjeta",
            "visualization": "Inicializar toda la configuración de la visualización.",
            "actions": "Borrar las acciones configuradas en la tarjeta.",
            "translations": "Borrar las traducciones configuradas en los campos."
        },
        "cardQueryMessagePreview": "Para previsualizar esta consulta, añade al menos un campo a la sección \"COLUMNAS\"",
        "kpiMessagePreview": "Para previsualizar esta tarjeta, añade al menos una métrica a la sección \"UBICACIÓN DE INDICADORES\"",
        "kpiMessageSave": "Para guardar esta tarjeta, añade al menos una métrica a la sección \"UBICACIÓN DE INDICADORES\"",
        "crossTableMessagePreview": "Para previsualizar esta tarjeta, añade al menos una métrica a la sección \"DATOS\"",
        "crossTableMessageSave": "Para guardar esta tarjeta, añade al menos una métrica a la sección \"DATOS\"",
        "verticalTableMessagePreview": "Para previsualizar esta tarjeta, añade al menos un campo a la sección \"COLUMNAS\" de la pestaña \"TABLA VERTICAL\"",
        "verticalTableMessageSave": "Para guardar esta tarjeta, añade al menos un campo a la sección \"COLUMNAS\" de la pestaña \"TABLA VERTICAL\"",
        "gaugeMessagePreview": "Para previsualizar esta tarjeta, añade al menos una métrica a la sección \"MÉTRICA\"",
        "gaugeMessageSave": "Para guardar esta tarjeta, añade al menos una métrica a la sección \"MÉTRICA\"",
        "chartMessage": "Para {{mode}} esta tarjeta, añade la siguiente información",
        "breakByMessages": {
            "breakByUnavailableMessage": "División no disponible para el tipo de gráfico seleccionado",
            "breakByDisabledMessage": "División no disponible para más de una métrica"
        },
        "chartConditionsMessages": {
            "typeMessage": "El tipo de gráfico",
            "singleMessage": "Una {{fieldType}} en la sección {{section}}",
            "multipleMessage": "Al menos una {{fieldType}} en la sección {{section}}",
            "twoOptionsMessage": "{{firstOption}} o {{secondOption}}"
        },
        "updateSuccess": "Tarjeta guardada correctamente",
        "crossTableStyles": {
            "crossTableCleanStyle": "Clean",
            "crossTableGoldStyle": "Gold",
            "crossTableBlueGreyStyle": "Blue Grey",
            "crossTableRedStyle": "Red"
        }, 
        "cardEditorLabel": "Editor de tarjetas",
        "chartTypeLabel": "Tipo de gráfico",
        "selectChartTypeLabel": "Seleccionar gráfico",
        "chartTypes": {
            "bar": "Barras verticales",
            "horizontalBar": "Barras horizontales",
            "line": "Líneas",
            "area": "Áreas",
            "pie": "Tarta",
            "doughnut": "Rosquilla",
            "stackedBar": "Barras verticales apiladas",
            "stackedHorizontalBar": "Barras horizontales apiladas",
            "stacked100Bar": "Barras verticales al 100% apiladas",
            "stacked100HorizontalBar": "Barras horizontales al 100% apiladas",
            "mixed": "Mixto Líneas / Barras",
            "radar": "Radar",
            "funnel": "Embudo",
            "horizontalFunnel": "Embudo horizontal",
            "gauge": "Calibre",
            "scatter": "Dispersión",
            "bubbles": "Burbujas",
            "bullet": "Bala",
            "treemap": "Mapa de árbol",
            "heatmap": "Mapa de calor"
        },
        "chartFamilies": {
            "bars": "Barras",
            "line": "Línea",
            "lines": "Líneas",
            "areas": "Áreas",
            "pies": "Tartas",
            "mixed": "Mixto",
            "funnels": "Embudos"
        },
        "chartConfig": {
            "generalConfig": "Configuración general",
            "xAxeConfig": "Configuración de eje X",
            "yAxeConfig": "Configuración de eje Y",
            "yAxisConfig": "Configuración de ejes Y",
            "metricConfig": "Configuración de métrica",
            "dimensionConfig": "Configuración de dimensión",
            "showAxisLabel": "Mostrar etiqueta",
            "showAxisLine": "Mostrar eje",
            "showTicksLabel": "Mostrar marcas",
            "showLegendLabel": "Mostrar leyenda",
            "showBreadcrumbLabel": "Mostrar miga de pan",
            "showTooltipLabel": "Mostrar descripción emergente",
            "showDatalabelsLabel": "Etiquetar datos",
            "showPoints": "Mostar puntos",
            "directionLabel": "Orientación",
            "borderLineWidthLabel": "Grosor de línea",
            "lineTensionLabel": "Curvatura de línea",
            "gridLineStyleLabel": "Estilo de línea",
            "showValue": "Mostrar valor",
            "showPercentage": "Mostrar porcentaje",
            "showSubtotal": "Mostrar subtotal",
            "showTotal": "Mostrar total",
            "funnelAlign": "Visualización del embudo",
            "tickDirections": {
                "auto": "Automático",
                "horizontal": "Horizontal",
                "vertical": "Vertical",
                "slanting": "Inclinado"
            },
            "gridLineStyles": {
                "solid": "Continuo",
                "dashed": "Discontinuo",
                "dotted": "Puntos"
            },
            "lineTension": {
                "none": "Ninguna",
                "soft": "Suave",
                "moderated": "Moderada",
            },
            "pointStyles": {
                "circle": "Círculo",
                "triangle": "Triángulo",
                "rect": "Cuadrado",
                "rectRounded": "Cuadrado redondeado",
                "rectRot": "Rombo",
                "cross": "Cruz",
                "crossRot": "Equis",
                "line": "Línea"
            },
            "palettes": {
                "mix1": "Paleta 1",
                "mix2": "Paleta 2",
                "mix3": "Paleta 3",
                "mix4": "Paleta 4"
            },
            "ticksStepValueLabel": "Salto",
            "showGridLinesLabel": "Mostrar líneas",
            "fieldsConfig": "Configuración de campos",
            "axisConfig": "Configuración de ejes",
            "axeConfigButton": "Configurar eje",
            "axeConfigButton_plural": "Configurar ejes",
            "order": {
                "desc": "Descendente",
                "asc": "Ascendente",
                "default": "De origen",
                "custom": "Personalizado"
            },
            "gaugeConfig": {
                "showLabel": "Mostrar etiqueta",
                "labelPosition": "Posición",
                "labelStyle": "Formato",
                "gaugeWidth": "Ancho de la barra",
                "gaugeColor": "Color de la barra",
                "minValue": "Valor mínimo",
                "maxValue": "Valor máximo",
                "showRoundEdges": "Mostrar extremos redondeados",
                "showAxisLabel": "Mostrar mínimo y máximo",
                "axisLabelPosition": "Posición de etiquetas",
                "gaugeType": "Tipo de barra",
                "gaugeForm": {
                    "circular": "Circular",
                    "semicircular": "Semicircular",
                    "custom":  "Personalizada"
                }
            },
            "bulletConfig": {
                "barHeight": "Altura de la barra",
                "metricWidth": "Ancho de la barra de métrica",
                "metricHeight": "Altura de la barra de métrica",
                "threshold": "Umbral",
                "thresholdSize": "Anchura",
                "thresholdWidth": "Altura"
            },
            "treemapConfig": {
                "borderWidth": "Tamaño del borde",
                "upperLabelBackgroundColor": "Color de fondo de cabecera"
            },
            "radarShape": {
                "circle": "Circular",
                "polygon": "Poligonal"
            },
            "radarShapeLabel": "Forma del radar",
            "radarSegments": "Nº de divisiones",
            "enableAnimation": "Habilitar animación",
            "graphicAnimation": "Animación del gráfico",
            "valueAnimation": "Animación del valor",
            "radius": "Radio",
            "radiusSize": "Tamaño del radio",
            "borderConfig": "Configuración del borde",
            "applyTo": "Aplicar a",
            "endLabel": "Extremo",
            "baseAndEndLabel": "Base y extremo",
            "iconSize": "Tamaño icono página",
            "iconColor": "Color icono página",
            "inactiveColor": "Color icono página inactivo",
            "padAngle": "Ángulo de separación"
        },
        "gaugeTypes": {
            "bullet": "Bala",
            "gauge" : "Calibre"
        },
        "gaugeConfig": {
            "visualizationTypeLabel": "Tipo de visualización",
            "rangeConfigLabel": "Configuración de rango",
            "minValueLabel": "Valor mínimo",
            "maxValueLabel": "Valor máximo",
            "outerBarLabel": "Barra base",
            "innerBarLabel": "Barra de la métrica",
            "outerSectorLabel": "Sector base",
            "innerSectorLabel": "Sector de la métrica"
        },
        "conditionalStyles": {
            "enabledConditionalStyle": "Estilo condicional activado",
            "disabledConditionalStyle": "Estilo condicional desactivado",
            "disableConditionalStylesMessage": "Activa los estilos condicionales para aplicar diferentes formatos a los elementos de datos basados en sus valores.",
            "addRule": "Añadir regla",
            "deleteAllRules": "Borrar todas las reglas",
            "field": "Campo",
            "ruleExpression": "Expresión",
            "stylePreview": "Vista previa del estilo",
            "styleSample": "Ejemplo de estilo",
            "clickToConfigure": "Click para configurar",
            "fieldSelectedMandatory": "Selecciona un campo",
            "indicatorConfig": "Configuración de indicador",
            "elementStyle": "Estilo del elemento",
            "datalabelStyle": "Estilo de etiqueta de datos"
        },
        "customCards": {
            "currentFile": "Fichero actual",
            "uploadFileIdleMessage": "Añadir fichero (Arrastra o haz click)",
            "uploadFileReplaceMessage": "Reemplazar fichero (Arrastra o haz click)",
            "uploadFileRejectedMessage": "Fichero no admitido",
            "uploadFileAcceptedMessage": "Suelta aquí el fichero",
            "uploadFileTypeMessage": "Extensión de fichero admitida: ",
            "uploadFileSizeMessage": "Tamaño máximo de fichero: "
        },
        "customCardLabel": "Tarjeta Personalizada",
        "chooseCardLabel": "Elige tarjeta",
        "SearchCardLabel": "Buscar tarjeta",
        "interactiveColumnsLabel": "Columnas interactivas",
        "columnsInUseLabel": "Columnas utilizadas",
        "allowInteractiveFields": "Permitir selección de campos interactiva",
        "indicatorFormat": "Formato de indicador",
        "numberFormat": "Formato de número",
        "internationalNumberFormat": "Coma decimal, punto para miles (1.000,00)",
        "englishNumberFormat": "Punto decimal, coma para miles (1,000.00)",
        "internationalNumberFormatValue": "1.000,00",
        "englishNumberFormatValue": "1,000.00",
        "dateFormat": "Formato de fecha",
        "dateLevel": "Agrupación de fecha",
        "timeZone": "Zona horaria",
        "showText": "Mostrar texto",
        "showIndicator": "Mostrar indicador",
        "editIndicator": "Editar indicador",
        "showVisualElement": "Mostrar semáforo vertical",
        "activeColor": "Color activo",
        "inactiveColor": "Color inactivo",
        "activeCriteria": "Criterio activo",
        "showHeader": "Mostrar cabeceras",
        "useStrippedRows": "Usar filas de color alterno",
        "nullValues": "Tratamiento de valores nulos",
        "nullCustomValue": "Valor personalizado",
        "nullOptionShowNothing": "No mostrar nada",
        "nullOptionShowNull": "Mostrar 'NULO'",
        "nullOptionCustomValue": "Mostrar valor personalizado",
        "allowSort": "Permitir ordenación interactiva",
        "allowMultiSort": "Permitir ordenación múltiple",
        "allowFilter": "Permitir filtrado interactivo",
        "filterTypeInput": "Texto libre",
        "filterTypeSelect": "Selector",
        "filterTypeDateRange": "Rango de fechas",
        "filterTypeNumberRange": "Rango numérico",
        "defaultSort": "Ordenación por defecto",
        "columnTitle": "Título de columna",
        "totalType": "Tipo de total",
        "totalValue": "Valor de total",
        "editColumn": "Editar columna",
        "backgroundColor": "Color de fondo",
        "showLeftGrid": " Mostrar cuadrícula izquierda",
        "showRightGrid": "Mostrar cuadrícula derecha",
        "showMainHeader": "Mostrar cabecera principal",
        "showLeftHeader": "Mostrar cabecera izquierda",
        "showRightHeader": "Mostrar cabecera derecha",
        "showMainFooter": "Mostrar pie de página principal",
        "showLeftFooter": "Mostrar pie de página izquierdo",
        "showRightFooter": "Mostrar pie de página derecho",
        "showRowTotals": "Mostrar totales de fila",
        "showColumnTotals": "Mostrar totales de columna",
        "headerLabel": "Cabecera",
        "footerLabel": "Pie de página",
        "showSubTotals": "Mostrar subtotales",
        "subtotalsLabel": "Subtotales",
        "subtotalsCollapse": "Expandir/contraer subtotales",
        "usePredefinedStyle": "Usar estilos predefinidos",
        "predefinedStyleLabel": "Estilo predefinido",
        "useCustomStyle": "Usar estilos personalizados",
        "useHeaderCustomStyle": "Estilo personalizado de cabecera",
        "useFooterCustomStyle": "Estilo personalizado de totales",
        "useSubtotalsCustomStyle": "Estilo personalizado de subtotales",
        "rowCustomStyle": "Estilo personalizado de filas",
        "configureRowCustomStyle": "Estilo condicional de filas",
        "configureCustomStyle": "Definir estilo condicional",
        "editHeaders": "Editar cabeceras",
        "editFooters": "Editar pies de página",
        "editSubtotals": "Editar subtotales",
        "addCondition": "Añadir condición",
        "deleteAll": "Borrar todas",
        "chooseDataField": "Elige campo",
        "mergeCells": "Agrupar celdas",
        "cardConfig": "Configuración de tarjeta",
        "cardDataConfig": "Configurar datos de tarjeta",
        "isDataCard": "¿Es una tarjeta de datos?",
        "multiQueryCard": "¿Es una tarjeta con múltiples consultas?",
        "addQueryLabel": "Añadir consulta",
        "deleteQueryLabel": "Borrar consulta",
        "selectQueryLabel": "Elige consulta",
        "queryLabel": "Consulta",
        "queryShortLabel": "Q",
        "affectedFieldLabel": "Campo afectado",
        "affectedFieldLabel_plural": "Campos afectados",
        "joinedQueryLabel": "Consulta relacionada",
        "queriesLabel": "Consultas",
        "availableFields": "Campos disponibles",
        "selectedFields": "Campos seleccionados",
        "configQueries": "Configurar consultas",
        "configRelations": "Configurar relaciones",
        "selectQueriesWithData": "Selecciona las consultas que devolverán datos",
        "headerDynamicRowHeight": "Configurar altura de cabecera",
        "dynamicRowHeight": "Configurar altura de fila",
        "imageUrl": "Valor como URL de imagen",
        "imageUrlTooltip": "Los valores del campo se tratarán como URLs para mostrar una imagen en la celda. Si no hay ninguna disponible, se mostrará una imagen de 'No encontrado'",
        "useDynamicRowHeight": "Usar altura dinámica",
        "calculateRowHeightField": "Calcular en altura tomando como referencia:",
        "calculateRowHeightPrimaryField": "Campo principal",
        "calculateRowHeightSecondaryField": "Campo alternativo",
        "showRowCount": "Mostrar filas obtenidas/mostradas",
        "showRowNumber": "Mostrar número de fila",
        "verticalPosition": "Posición vertical",
        "noDimensionsAvailable": "No hay dimensiones disponibles con el fitro seleccionado",
        "noDimensionsAvailableNoFilter": "No hay dimensiones disponibles",
        "noMetricsAvailable": "No hay métricas disponibles con el fitro seleccionado",
        "noMetricsAvailableNoFilter": "No hay métricas disponibles",
        "rowHeight": "Altura de filas",
        "calculatedFields": "Campos calculados",
        "noCalculatedFieldsAvailable": "No hay ningún campo calculado disponible. Pulsa el botón para añadir uno.",
        "newCalculatedFieldLabel": "Nuevo campo calculado",
        "mapLabel": "Mapa",
        "mapStructure": {
            "baseMap": "Mapa base",
            "showBaseMap": "Mostrar mapa base",
            "mapCenter": "Centro del mapa",
            "latitude": "Latitud",
            "longitude": "Longitud",
            "layers": "Capas",
            "warningAddLayer": "Debes añadir una nueva consulta antes de añadir una nueva capa",
            "showLayerSelector": "Mostrar selector de capas",
            "zoom": "Zoom",
            "initial": "Inicial",
            "showZoomControls": "Mostrar controles de zoom",
            "scrollWheelZoom": "Desplazarse con la rueda del ratón",
            "legend": "Leyenda"
        },
        "mapLayerDialog": {
            "layer": "Capa",
            "symbol": "Símbolo",
            "symbolTooltip": {
                "p1": "Tipo de mapa en el que los símbolos se colocan en ubicaciones específicas, de tamaño y color de acuerdo con sus datos.",
                "p2": "Selecciona entre una amplia gama de símbolos para reflejar mejor sus PDI (puntos de interés)."
            },
            "choropleth": "Coropleta",
            "choroplethTooltip": "Tipo de mapa temático en el que las áreas están sombreadas o modeladas en proporción a un valor métrico que representa un resumen agregado de una característica geográfica dentro de cada área.",
            "heatmap": "Mapa de calor",
            "heatmapTooltip": "Un mapa de calor es una técnica de visualización de datos que muestra la magnitud de un fenómeno como color en dos dimensiones. La variación en el color puede ser por matiz o intensidad, dando pistas visuales obvias sobre cómo el fenómeno está agrupado o varía en el espacio."
        },
        "mapLayerSymbolStructure": {
            "fixed": "Fijo",
            "byValue": "Por valor",
            "buckets": "Cubos",
            "opacity": "Opacidad",
            "symbolLocation": "Localización del símbolo",
            "symbolSize": "Tamaño del símbolo",
            "symbolColorAndIcon": "Color e icono del símbolo",
            "stroke": "Borde",
            "tooltip": "Tooltip",
            "showTooltip": "Mostrar tooltip al pasar por encima",
            "showPopup": "Mostrar tooltip al hacer click"
        },
        "mapLayerChoroplethStructure": {
            "fixed": "Fijo",
            "byValue": "Por valor",
            "buckets": "Cubos",
            "opacity": "Opacidad",
            "mapCatalog": "Catalogo de mapas",
            "color": "Color",
            "stroke": "Borde",
            "tooltip": "Tooltip",
            "showTooltip": "Mostrar tooltip al pasar por encima",
            "showPopup": "Mostrar tooltip al hacer click"
        },
        "mapTileLayers": {
            "biuwer_default": {
                "label": "Por defecto",
                "attribution": "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
            },
            "detailed_blue": {
                "label": "Azul detallado",
                "attribution": "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors, Tiles style by <a href=\"https://www.hotosm.org/\" target=\"_blank\">Humanitarian OpenStreetMap Team</a> hosted by <a href=\"https://openstreetmap.fr/\" target=\"_blank\">OpenStreetMap France</a>"
            },
            "topograph": {
                "label": "Topográfico",
                "attribution": "Map data: &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors, <a href=\"http://viewfinderpanoramas.org\">SRTM</a> | Map style: &copy; <a href=\"https://opentopomap.org\">OpenTopoMap</a> (<a href=\"https://creativecommons.org/licenses/by-sa/3.0/\">CC-BY-SA</a>)"
            },
            "elegant_grey": {
                "label": "Gris elegante",
                "attribution": "&copy; <a href=\"https://stadiamaps.com/\">Stadia Maps</a>, &copy; <a href=\"https://openmaptiles.org/\">OpenMapTiles</a> &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> contributors"
            },
            "elegant_dark": {
                "label": "Negro elegante",
                "attribution": "&copy; <a href=\"https://stadiamaps.com/\">Stadia Maps</a>, &copy; <a href=\"https://openmaptiles.org/\">OpenMapTiles</a> &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> contributors"
            },
            "black_white": {
                "label": "Blanco y negro",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "grey_white": {
                "label": "Blanco y gris",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "terrain": {
                "label": "Terreno",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "classic": {
                "label": "Clásico",
                "attribution": "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
            },
            "satellite": {
                "label": "Satélite",
                "attribution": "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            },
            "smooth_light": {
                "label": "Suave claro",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "smooth_dark": {
                "label": "Suave oscuro",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "outdoors": {
                "label": "Aire libre",
                "attribution": "&copy; <a href=\"http://www.thunderforest.com/\">Thunderforest</a>, &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
            },
            "neighbourhood": {
                "label": "Vencindarios",
                "attribution": "&copy; <a href=\"http://www.thunderforest.com/\">Thunderforest</a>, &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
            }
        },
        "mapLocationDefinedByOptions": {
            "lat_lng_fields": "Campos Latitud + Longitud",
            "administrative_regions": "Regiones administrativas"
        },
        "dropFieldHere": "Suelta el campo de {{name}} aquí",
        "dropFieldHere_plural": "Suelta los campos de {{name}} aquí",
        "mapConditionsMessages": {
            "layerMessage": "Capa {{name}}",
            "singleMessage": "El campo {{fieldType}}",
            "multipleMessage": "Al menos un campo en {{fieldType}}",
            "selectOptionMessage_male": "Selecciona un {{option}}",
            "selectOptionMessage_female": "Selecciona una {{option}}"
        },
        "mapPreviewMove": "Se ha detectado un movimiento en la previsualización del mapa. ¿Quieres actualizar el centro y zoom inicial con los nuevos valores?",
        "mapCatalog": {
            "title": "Cátalogo de mapas",
            "matchingFieldMeaning": "Significado de relación",
            "matchingField": "Relación"
        },
        "mapCatalogMeaningOptions": {
            "countriesIso3": "Países (ISO3)",
            "countriesName": "Países (Nombre local)",
            "countriesEnName": "Países (Nombre en Inglés)",
            "ueCountriesNutsCode": "Europa - Países (Código NUTS)",
            "ueCountriesNutsName": "Europa - Países (Nombre local NUTS)",
            "spainNuts2Code": "España - Comunidades autónomas (Código NUTS-2)",
            "spainNuts2Name": "España - Comunidades autónomas (Nombre local NUTS-2)",
            "spainNuts3Code": "España - Provincias (Código NUTS-3)",
            "spainNuts3Name": "España - Provincias (Nombre local NUTS-3)",
            "spainCusecCode": "Spain - Sección censal (Código CUSEC)",
            "usStatesFipsCode": "EEUU - Estados (Código FIPS Dos dígitos)",
            "usStatesName": "EEUU - Estados (Nombre local)",
            "usCountiesFipsCode": "EEUU Condados (Código FIPS Cinco dígitos)",
            "usCountiesName": "EEUU - Condados (Nombre local)"
        },
        "deleteMapLayerMessage": "¿Estás seguro de que quieres eliminar la capa <b>\"{{- layer}}\"</b>?",
        "deleteQueryMessage": "¿Estás seguro de que quieres eliminar la consulta <b>\"Q{{- query}}\"</b>?<br><br>Si se elimina la consulta, también se eliminará toda la configuración de la visualización que utilice cualquier campo de la consulta.",
        "deleteQueryModelMessage": "¿Estás seguro de que quieres cambiar el modelo de datos de la consulta <b>\"Q{{- query}}\"</b>?<br><br>Si se cambia el modelo de datos, también se eliminará toda la configuración de la consulta y de la visualización que utilice cualquier campo de la consulta.",
        "cardQueryOperations": "Operaciones entre consultas",
        "cardQueryRelations": "Relaciones entre consultas",
        "cardQueryDataRelations": "Datos relacionados",
        "cardQueryCombinationsMainQuery": "Consulta principal",
        "cardQueryCombinations": "Consultas combinadas",
        "cardQueryDataCombinations": "Datos combinados",
        "cardQueryRelationsInfo": "Las relaciones se usan para combinar filas de dos o más consultas basadas en una o más columnas relacionadas.",
        "cardQueryCombinationsInfo_p1": "Las combinaciones se usan para juntar el resultado de dos o más consultas en un único conjunto de datos.",
        "cardQueryCombinationsInfo_p2": "Todas las consultas deben tener el mismo número de columnas y sus tipos de datos deben ser los mismos para cada columna en la misma posición.",
        "calculatedFieldEditor": "Editor de campo calculado",
        "calculatedExpressionCorrect": "La expresión es correcta",
        "calculatedExpressionMultipleLocalDatasets": "Múltiples DATOS RELACIONADOS en uso",
        "calculatedExpressionEmpty": "La expresión no puede estar vacía",
        "calculatedExpressionError": "Error en la expresión",
        "deleteQueryFieldMessage": "El campo <b>\"{{- queryField}}\"</b> está siendo utilizado.<br><br>Si se elimina se perderá toda la configuración relacionada con este campo (relaciones, campos calculados de tarjeta, campos de visualización, etc.)",
        "changeQueryFieldTypeMessage": "El campo <b>\"{{- queryField}}\"</b> está siendo utilizado.<br><br>Si se modifica el tipo de campo se perderá toda la información relacionada con este campo donde el nuevo tipo sea incompatible.",
        "deleteVisualizationFieldMessage": "El campo <b>\"{{- queryField}}\"</b> está siendo utilizado.<br><br>Si se elimina se perderán todas las acciones de navegación relacionadas con este campo",
    },
    "dataCenter": {
        "connectionCatalog": {
            "headerDesc": "Busca, selecciona y configura <strong>conexiones de datos</strong> para acceder a tus datos",
            "noConnectionFoundWithFilters": "No se han encontrado conexiones en el catálogo con los filtros seleccionados",
            "noConnectionFound": "No se han encontrado conexiones en el catálogo",
            "status": {
                "coming_soon": "Próximamente",
                "beta": "Beta"
            },
            "categories": {
                "allCategories": "Todas las categorías",
                "files": "Ficheros",
                "file_stores": "Almacén de ficheros",
                "applications": "Aplicaciones",
                "events": "Eventos",
                "databases": "Bases de Datos",
                "cloud_databases": "Bases de Datos Cloud",
                "cloud_files": "Ficheros Cloud"
            }
        },
        "connectionForm": {
            "connectionType": "Tipo de conexión",
            "connectionDetails": "Detalles de la conexión",
            "selectConnectionType": "Selecciona un tipo de conexión del catálogo",
            "sshTunnel": "Túnel SSH",
            "databasesWarning": "Para asegurar el acceso a su base de datos <strong>{{- connection}}</strong>, asegurese de que su servidor esté configurado para permitir la conexión a las siguientes direcciones IP: <strong>{{- address}}</strong>",
            "timeZoneWarning1": "Su base de datos <strong>{{- connection}}</strong> debe tener habilitada la configuración de zonas horarias. Puede consultar como realizar este proceso en el siguiente enlace: ",
            "timeZoneWarning2": "Si por algún motivo no puede habilitar la configuración de zonas horarias en su base de datos <strong>{{- connection}}</strong>, no active el soporte de zonas horarias en <strong>Biuwer</strong>, de lo contrario no podrá ver datos de  ningún campo de tipo fecha.",
            "timeZoneWarningMongo": "Su base de datos <strong>{{- connection}}</strong> debe ser la version 3.6 o superior para admitir la configuración de zonas horarias. No active el soporte de zonas horarias en <strong>Biuwer</strong> si su base de datos tiene una versión anterior.",
            "timeZoneDoc": "configuración de zona horaria",
            "mappingConfigWarning": "Esta conexión requiere un mapeo de campos",
            "connectionConfig": "Configuración de conexión",
            "mappingConfig": "Mapeo de campos",
            "loadData": "Obtener datos",
            "reloadData": "Volver a obtener datos",
            "documentIdSearch": "Buscar documento",
            "documentIdManual": "Introducir Id manualmente",
            "headerParams": "Parámetros de Cabecera",
            "URLParams": "Parámetros de URL",
            "bodyParams": "Parámetros de Body",
            "authorization": {
                "basicAuth": "Autenticación básica",
                "token": "Token de accesso",
                "addKeyTo": "Añadir clave a"
            },
            "pagination": {
                "keyBased": "Clave",
                "incremental": "Incremental",
                "nextKey": "Siguiente clave",
                "initialValue": "Valor inicial",
                "incrementalValue": "Valor a incrementar",
                "sizeKey": "Clave de tamaño",
                "sizeValue": "Tamaño paginación",
                "addSizeKeyTo": "Añadir tamaño a"
            }
        },
        "connectionMappingForm": {
            "dataPreviewTitle": "Previsualización de datos aplicado el mapeo",
            "datasetAndMappingConfigTitle": "Campos del Conjunto de datos",
            "dataSampleTitle": "Estructura de datos obtenida",
            "previewData": "Previsualizar datos",
            "mappingPath": "Mapeo",
            "noMappeableFields": "Selecciona el Conjunto de datos a actualizar o añade campos al nuevo Conjunto de datos.",
            "noPreviewData": "No hay datos para previsualizar. Si ha configurado el mapeo de campos pulse el botón \"Previsualizar datos\" para ver los datos tal y como se van a guardar."
        },
        "connectionsList": {
            "dataset" :"Conjunto de Datos",
            "dataset_plural" :"Conjuntos de Datos",
            "datamodel": "Modelo de Datos",
            "datamodel_plural": "Modelos de Datos"
        },
        "reverseEngineering": {
            "datasetsList": "Listado de Conjuntos de datos",
            "originalDataType": "Tipo de dato original",
            "biuwerDataType": "Tipo de dato en Biuwer",
            "fieldType": "Tipo de campo",
            "fieldNotSupported": "No Soportado",
            "selectAll": "Seleccionar todo",
            "unselectAll": "Deseleccionar todo",
            "reverseEngineeringError_p1": "Error al obtener los conjuntos de datos de la conexión seleccionada.",
            "reverseEngineeringError_p2": "Revisa que la conexión está configurada correctamente."
        },
        "title": "Data Center",
        "homeLabel": "Inicio",
        "technologyLabel": "Tecnología",
        "technologyLabel_plural": "Tecnologías",
        "changeDataConnectionTitle": "Cambiar conexión",
        "changeDataConnectionWarning": "¿Estás seguro de que quieres cambiar la conexión <b>\"{{- prevConnection}}\"</b> por la conexión <b>\"{{- newConnection}}\"</b>?",
        "changeDataConnectionRelationsWarning": "Se borrarán todas las tablas y relaciones de este modelo",
        "deleteApplicationDataConnectionMessage": "ADVERTENCIA: Esta conexión es de tipo aplicación. Por tanto también se borrarán todos sus datos asociados y ya no se actualizarán datos de forma automática.",
        "deleteCloudFileDataConnectionMessage": "ADVERTENCIA: Esta conexión es de tipo fichero cloud. Por tanto también se borrarán todos sus datos asociados y ya no se actualizarán datos de forma automática."
    },
    "datasets": {
        "datasetLabel": "Conjunto de Datos",
        "datasetLabel_plural": "Conjuntos de Datos",
        "numberOfDatasets": "# Conjuntos de Datos",
        "fieldLabel": "Campo",
        "fieldLabel_plural": "Campos",
        "recordLabel": "Registro",
        "recordLabel_plural": "Registros",
        "dataPoliciesLabel": "Políticas de Datos",
        "enabledLabel": "Habilitadas",
        "disabledLabel": "Deshabilitadas",
        "managedLabel": "Gestionado",
        "notManagedLabel": "No Gestionado",
        "managedDatasetLabel": "Conjunto de datos gestionado",
        "notManagedDatasetLabel": "Conjunto de datos no gestionado",
        "dateLevelLabel": "Agrupación",
        "lastRefreshedLabel": "Última actualización",
        "managedDatasetInfo": "Los datos se almacenan en Biuwer Data Warehouse y las consultas se ejecutan dentro de Biuwer.",
        "notManagedDatasetInfo": "Los datos se almacenan fuera de Biuwer y las consultas se ejecutan en la base de datos remota.",
        "fieldsListDescription": "Mostrando la configuración actual de los campos del conjunto de datos.",
        "datasetPreviewInfo": "Mostrando los 100 primeros registros.",
        "customizeFieldValues": "Personalizar valores del campo",
        "in": "en",
        "personalizeValues": "Personalizar valores",
        "whereAreTranslatedValues": "¿Dónde están los valores traducidos?",
        "getTranslationsFromOtherField": "En otros campos del dataset",
        "internallyInThisSameField": "Internamente en este campo",
        "originalValues": "Valores originales",
        "originalValuesInField": "Valores originales en campo",
        "translatedValuesInField": "Valores traducidos en campo",
        "translationsValuesEnabled": "Traducciones de valores habilitadas",
        "basicInformation": "Información básica",
        "isAView": "¿Es una vista?",
        "notConfigured": "No configurado",
        "multiLanguage": "Multi idioma",
        "fieldsWithTranslations": "Campos con traducciones",
        "isInUse": "¿Está en uso?",
        "dataModelsUsage": "# Modelos de Datos",
        "cardsUsage": "# Tarjetas",
        "filtersUsage": "# Filtros",
        "performance": "Optimización",
        "queryResultsCaching": "Caché de resultados de consultas",
        "acceleratedStorage": "Aceleración de almacenamiento",
        "dataLastUpdated": "Última actualización de datos el",
        "dataNextUpdate": "Próxima actualización de datos el",
        "formatLink1": "Formato enlace 1",
        "formatLink2": "Formato enlace 2",
        "dateLevels": {
            "year": "Año",
            "month": "Mes",
            "week": "Semana",
            "quarter": "Trimestre",
            "yearQuarter": "Año-Trimestre",
            "yearMonth": "Año-Mes",
            "yearWeek": "Año-Semana",
            "fullDate": "Fecha Completa",
            "fullDateTime": "Fecha y Hora Completa",
            "hourOfDay": "Hora del Día"
        },
        "processFile": "Procesar fichero",
        "importFromFile": "Importar {{type}}",
        "importFileOptions": {
            "newDataset": "Crear nuevo Conjunto de datos",
            "updateDataset": "Actualizar Conjunto de datos existente"
        },
        "fileImportMessages": {
            "fileNotUploaded": "Ningún fichero seleccionado",
            "fileUploaded": "Fichero seleccionado",
            "fileNotProcessed": "El fichero no ha sido procesado",
            "processBeforeMapping": "Procesa el fichero para mapear campos",
            "dataNotValid": "Error al procesar datos del fichero, revise la columna \"{{field}}\" (tipo de dato y formato)",
            "genericDataNotValid": "Error al procesar datos del fichero"
        },
        "processFileWarning": "El fichero ha cambiado, es necesario volver a procesarlo",
        "datasetSelectLabel": "Selecciona Conjunto de Datos",
        "importDatasetActionSelectLabel": "Selecciona Tipo de actualización",
        "aggregationLabel": "Operación",
        "aggregations": {
            "sum": "Suma",
            "count": "Conteo",
            "countDistinct": "Conteo distinto",
            "avg": "Media",
            "min": "Mínimo",
            "max": "Máximo",
            "none": "Ninguna"
        },
        "datasetType": "Tipo de conjunto de datos",
        "dialogManaged": {
            "p1": "Los datos se almacenan en Biuwer Data Warehouse y las consultas se ejecutan dentro de Biuwer.",
            "p2": "Use estos conjuntos de datos para cargar datos desde ficheros externos o a través de un trabajo con Biuwer Data Prep."
        },
        "dialogNotManaged": {
            "p1": "Los datos se almacenan fuera de Biuwer y las consultas se ejecutan en la base de datos remota.",
            "p2": "Use estos conjuntos de datos para consultar datos de una tabla o vista almacenada en sus bases de datos."
        },
        "dialogStandard": {
            "p1": "Los campos estándar representan columnas físicas en tablas o campos en vistas.",
            "p2": "Utilice los campos estándar para consultar datos directamente desde ellos."
        },
        "dialogCalculated": {
            "p1": "Los campos calculados le permiten definir una expresión o fórmula utilizando otros campos, constantes, funciones y operadores estándar disponibles, según el tipo de dato del campo.",
            "fnHelp": "Las fórmulas de los campos calculados se procesan en la base de datos de origen y Biuwer no tiene control sobre las funciones disponibles ni sobre la validación de las fórmulas"
        },
        "calculatedFieldFnHelp": {
            "p1": "Las expresiones de los campos calculados de Conjuntos de Datos se procesan en la base de datos asociada a su conexión.",
            "p2": "Puedes usar las funciones disponibles en el motor de la base de datos origen. Consulta la ayuda para más información.",
            "p3": "Para comprobar que la expresión es correcta. una vez guardado el campo, accede a la pestaña \"Registros\"."
        },
        "fieldType": "Tipo de campo",
        "dataType": "Tipo de dato",
        "hidden": "Oculto",
        "calculated": "Calculado",
        "calculatedField": "Campo calculado",
        "deleteDataset": "Eliminar Conjunto de Datos",
        "deleteDatasetMessage": "¿Estás seguro de que quieres eliminar el Conjunto de Datos <b>\"{{- dataset}}\"</b>?",
        "cantDeleteDatasetMessage": "No se puede eliminar el Conjunto de Datos <b>\"{{- dataset}}\"</b>",
        "cantDeleteDatasetMessage_plural": "No se pueden eliminar los Conjunto de Datos",
        "deallocateDatasetMessage": "¿Estás seguro de que quieres desasignar el Conjunto de Datos <b>\"{{- dataset}}\"</b> del modelo?",
        "cantDeallocateDatasetMessage": "No se puede desasignar el Conjunto de Datos <b>\"{{- dataset}}\"</b> del modelo",
        "deleteDataFieldMessage": "¿Estás seguro de que quieres eliminar el Campo <b>\"{{- dataField}}\"</b>?",
        "cantDeleteDataFieldMessage": "No se puede eliminar el Campo <b>\"{{- dataField}}\"</b>",
        "cantDeleteDataFieldMessage_plural": "No se pueden eliminar los Campos",
        "createDataset": "Crear Conjunto de Datos",
        "addField": "Añadir campo",
        "addCalculatedField": "Añadir campo calculado",
        "editCalculatedField": "Editar campo calculado",
        "availableFields": "Campos disponibles",
        "functionType": "Tipo de función",
        "functions": "Funciones",
        "expression": "Expresión",
        "container": "Contenedor",
        "container_plural": "Contenedores",
        "selectContainers": "Selecciona los contenedores para encontrar conjuntos de datos:",
        "selectContainersShort": "Selecciona contenedores",
        "datasetsNotFound": "No se han encontrado conjuntos de datos",
        "datasetsList": "Listado de conjuntos de datos",
        "datasetField": "Campo del conjunto de datos",
        "importLabel": "Importar Conjunto de datos",
        "importData": "Importar datos",
        "newDataset": "Nuevo Conjunto de datos",
        "addFieldLabel": "Añadir Campo",
        "notAvailableFieldsLabel": "No hay campos disponibles",
        "importFileError": "Error al procesar el fichero",
        "fieldAlreadyExists": "Nombre de campo ya existe en el Conjunto de Datos",
        "importOptions": {
            "delimiter": "Delimitador",
            "quotes": "Comillas",
            "rowHeaders": "Primera fila continene nombre de columnas",
            "skipRows": "Omitir primeras filas",
            "dateFormat": "Formato fechas",
            "customFormat": "Formato personalizado",
            "numeralFormat": "Formato números",
            "booleanFormat": "Formato booleanos",
            "thousandsLabel": "Miles",
            "decimalsLabel": "Decimales",
            "dataWarningLabel": "Revisa el tipo de dato aplicado a las siguientes columnas, pues su contenido no cumple con el formato indicado",
            "dateFormatMaskInfo": "Los valores mostrados en la configuración de campos de tipo fecha siguen el siguiente formato",
            "dateFormatLangInfo": "Se tendrá en cuenta el idioma actualmente configurado en Biuwer",
            "booleanFormatInfo": "Los campos que selecciones como tipo \"Booleano\" sólo admiten dos valores posibles, \"true\" y \"false\". Indica aquí qué valor de tus datos significa \"true\" y qué valor de tus datos significa \"false\"",
            "numberFormatInfo": "Los campos que selecciones como tipo \"Número\" pueden incluir separador de miles y separador decimal. Indica aquí qué símbolo, ya sea un punto o una coma, representa cada uno de ellos en tus datos.",
            "delimiterOptions": {
                "comma": "Coma",
                "semicolon": "Punto y coma",
                "colon": "Dos puntos",
                "space": "Espacio",
                "tabulation": "Tabulación",
                "custom": "Personalizado"
            },
            "quotesOptions": {
                "simple": "Simples",
                "double": "Dobles"
            },
            "importOptionsLabel": "Opciones de importación",
            "fieldsConfigLabel": "Configuración de campos",
            "importTypeLabel": "Tipo de importación",
            "updateActions": {
                "appendLabel": "Añadir Datos",
                "appendInfo": "Adjunta los datos importados con los ya almacenados",
                "overwriteLabel": "Sustituir Datos",
                "overwriteInfo": "Borra los datos ya almacenados y guarda los nuevos datos"
            }
        },
        "viewCodeDialog": {
            "queryCode": "Código de la consulta",
            "schemaValidation": "Validar esquema",
            "schemaIsCorrect": "El esquema es correcto.",
            "schemaIsNotCorrect": "El esquema es incorrecto o hay problemas con algún campo.",
            "schemaHasChanged": "El esquema ha cambiado. Pulsa en el botón de validar esquema para evaluarlo de nuevo.",
            "errorHeader": "El servidor de base de datos respondió con el siguiente ",
            "errorLabel": "error",
            "clickSynchronize": "Se han detectado los siguientes cambios en los campos del Conjunto de Datos:",
            "fieldsToBe": "Campos que se van a",
            "addedLabel": "añadir",
            "deletedLabel": "borrar",
            "noChangesInSchema": "No hay cambios en el esquema. Pulsa sincronizar para actualizar cambios en la definición de los campos.",
            "synchronizeLabel": "Sincronizar",
            "itsAView": "¿Es una vista?",
            "fieldIssues": "Alguno de los campos que se ha marcado para borrar está en uso:",
            "schemaCorrectButIssues": "El esquema es correcto pero hay problemas con alguno de los campos.",
            "saveOK": "Pulsa en sincronizar para guardar los cambios.",
            "saveKO": "Revisa la consulta o los campos que se han marcado para borrar."
        }
    },
    "datamodels": {
        "datamodelLabel": "Modelo",
        "datamodelLabel_plural": "Modelos",
        "datamodelType": "Tipo de modelo de datos",
        "datasetLabel": "Conjunto de Datos",
        "datasetLabel_plural": "Conjuntos de Datos",
        "datasetSelectLabel": "Selecciona Conjunto de Datos",
        "relationLabel": "Relación",
        "relationLabel_plural": "Relaciones",
        "numberOfRelations": "# Relaciones",
        "combinationLabel": "Combinación",
        "combinationLabel_plural": "Combinaciones",
        "createModel": "Crear Modelo",
        "editModel": "Editar Modelo",
        "deleteModel": "Eliminar Modelo",
        "chooseModel": "Elige Modelo",
        "datamodelsList": "listado de Modelos",
        "datasetAddLabel": "Añadir Conjunto de Datos",
        "datasetDeleteLabel": "Eliminar Conjunto de Datos",
        "datasetsAvailablesLabel": "Conjuntos de Datos disponibles",
        "datasetsSelectedLabel": "Conjuntos de Datos seleccionadas",
        "relationAddLabel": "Añadir Relación",
        "relationEditLabel": "Editar Relación",
        "relationDeleteLabel": "Eliminar Relación",
        "datasetsWarningMessage": "Debe de haber al menos 2 Conjuntos de Datos en el Modelo para añadir una Relación",
        "deleteDatamodelMessage": "¿Estás seguro de que quieres eliminar el modelo <b>\"{{- datamodel}}\"</b>?",
        "cantDeleteDatamodelMessage": "No se puede eliminar el modelo <b>\"{{- datamodel}}\"</b>",
        "cantDeleteDatamodelMessage_plural": "No se pueden eliminar los modelos",
        "deleteRelationMessage": "¿Estás seguro de que quieres eliminar la relación seleccionada?",
        "deleteDatasetMessage": "¿Estás seguro de que quieres eliminar la tabla <b>\"{{- dataset}}\"</b>",
        "dimensionsLabel": "Dimensiones",
        "metricsLabel": "Métricas",
        "selectParentDatamodel": "Seleccionar modelo padre",
        "showingDatasets": "Mostrando Conjuntos de Datos actualmente configurados en el Modelo",
        "showingRelations": "Mostrando Relaciones actualmente configuradas entre los Conjuntos de datos del Modelo",
        "changeDataConnectionWarning": "Si se cambia la conexión, se borrarán todos los Conjuntos de Datos y las Relaciones del Modelo",
        "mongoDBWarning": "MongoDB no está diseñado para usar relaciones entre conjuntos de datos como en una base de datos SQL. Utiliza a ser posible un único conjuntos de datos. Establece relaciones bajo tu propia responsabilidad, con pocos conjuntos de datos relacionados en estrella.",
       "dialogManaged": {
            "p2": "Use estos modelos de datos para incluir conjuntos de datos gestionados."
        },
        "dialogNotManaged": {
            "p2": "Use estos modelos de datos para incluir conjuntos de datos no gestionados."
        },
        "multiConnection": {
            "title": "Conexión dinámica",
            "title_plural": "Conexiones dinámicas",
            "enabled": "Conexiones dinámicas activadas",
            "disabled": "Conexiones dinámicas desactivadas",
            "multiConnectionDisabledMessage": "La configuración de conexiones dinámicas está desactivada",
            "noConnectionConfigured": "No hay ninguna conexión dinámica configurada",
            "deleteConfigMessage": "¿Estás seguro de que quieres eliminar la conexión dinámica <b>\"{{- name}}\"</b>?",
            "addMultiConnectionConfig": "Añadir conexión dinámica",
            "addMultiConnectionUsers": "Añadir usuarios a la conexión dinámica",
            "addMultiConnectionGroups": "Añadir grupos a la conexión dinámica"
        }
    },
    "dataConnections": {
        "connectionLabel": "Conexión",
        "connectionLabel_plural": "Conexiones",
        "connectionSelectLabel": "Selecciona Conexión",
        "connectionDetails": "Detalles de conexión",
        "createConnection": "Crear conexión",
        "deleteConnection": "Borrar conexión",
        "editConnection": "Editar conexión",
        "testConnection": "Probar conexión",
        "pauseSync": "Pausar sincronización",
        "resumeSync": "Reanudar sincronización",
        "host": "Servidor",
        "db": "Base de datos",
        "dbId": "Id de la Base de datos",
        "authSource": "BD autenticación",
        "user": "Usuario",
        "password": "Contraseña",
        "port": "Puerto",
        "encrypt": "Encriptación",
        "schema": "Esquema",
        "timeZone": "Zona horaria",
        "protocol": "Protocolo",
        "authType": "Tipo de autenticación",
        "privateKey": "Clave privada",
        "passphrase": "Frase de contraseña",
        "serviceAccount": "Cuenta de servicio",
        "privateKeySecurity": "La clave privada se almacenará cifrada y sólo se usará en el momento de la conexión",
        "passphraseRequired": "La clave privada no está protegida por una frase de contraseña",
        "passphraseSecurity": "La frase de contraseña se almacenará cifrada y solo se usará en el momento de la conexión",
        "connectionRequest": "Probando conexión",
        "connectionSuccess": "Conexión realizada con éxito",
        "connectionError": "Error al conectar",
        "chooseConnectionType": "Elige el tipo de conexión",
        "deleteDataConnectionMessage": "¿Estás seguro de que quieres eliminar la conexión <b>\"{{- dataConnection}}\"</b>?",
        "cantDeleteDataConnectionMessage": "No se puede eliminar la conexión <b>\"{{- dataConnection}}\"</b>",
        "cantDeleteDataConnectionMessage_plural": "No se pueden eliminar las conexiones",
        "reverseEngineering": "Ingeniería inversa",
        "reverseEngineeringError": "Error al recuperar Conjuntos de Datos. Revisa los parámetros de la conexión.",
        "project": "Proyecto",
        "projectId": "Id. Proyecto",
        "keyFilename": "Ruta",
        "ssl": "SSL",
        "rejectUnauthorized": "Rechazar conexiones no autorizadas",
        "apiKey": "Clave API",
        "clientId": "Client ID",
        "clientSecret": "Client Secret",
        "tenantId": "Tenant",
        "env": "Env",
        "hasCluster": "¿Tiene una configuración en cluster?",
        "authentication": "Autenticación",
        "authKey": "Clave de autenticación",
        "authValue": "Valor de autenticación",
        "method": "Método",
        "url": "URL",
        "hasPagination": "¿Tiene paginación?",
        "pagination": "Paginación",
        "headerParams": "Parámetros de Header",
        "urlParams": "Parámetros de URL",
        "bodyParams": "Parámetros de Body"
    },
    "dataPrep": {
        "agents": {
            "title": "Agente",
            "title_plural": "Agentes",
            "lastConnected": "Última conexión",
            "privateLabel": "Privado",
            "privateLabelDesc": "El agente está gestionado por la organización y sólo esta organización puede usarlo. Requiere descargar un fichero y ejecutarlo en una máquina privada.",
            "sharedLabel": "Compartido",
            "sharedLabelDesc": "El agente está gestionado por Biuwer, ya está configurado y listo para usarse.",
            "editAgentLabel": "Editar Agente",
            "addAgentLabel": "Añadir Agente",
            "chooseAgentLabel": "Selecciona Agente",
            "deleteAgentMessage": "¿Estás seguro de que quieres eliminar el agente <b>\"{{- agent}}\"</b>?",
            "sharedAgentDeleteMessage": "¿Estás seguro de que quieres desasignar el agente <b>\"{{- agent}}\"</b> de tu organización?",
            "deleteAgentInfoMessage": "Todos los trabajos que usaban este agente dejarán de estar operativos hasta que se les asigne otro agente válido.",
            "noAgentsAvailable": "No hay agentes disponibles"
        },
        "executions": {
            "executionLabel": "Ejecución",
            "executionLabel_plural": "Ejecuciones",
            "job": "Trabajo",
            "status": "Estado",
            "startDate": "Fecha inicio",
            "endDate": "Fecha fin",
            "executionTime": "Tiempo de ejecución",
            "agent": "Agente",
            "executionStatusList": {
                "scheduled": "Programado",
                "pending": "Pendiente",
                "queued": "En cola",
                "running": "En ejecución",
                "completed": "Completado",
                "canceled": "Cancelado",
                "error": "Error"
            },
            "seeExecutionDetails": "Ver detalles de la ejecución",
            "seeJobDetails": "Ver detalles del trabajo",
            "cancelExecution": "Cancelar ejecución",
            "cancelExecutionMessage": "¿Estás seguro de que quieres cancelar la ejecución <b>\"{{- execution}}\"</b>?",
            "deleteExecution": "Borrar ejecución",
            "jobExecutionDetail": "Detalle de la ejecución del trabajo",
            "jobsAndSteps": "Trabajos y pasos",
            "noExecutions": "No hay ninguna ejecución en los últimos 30 días.",
            "noExecutionsInJob": "Este trabajo no tiene ninguna ejecución en los últimos 30 días.",
            "lastExecution": "Última actualización",
            "nextExecution": "Próxima actualización",
            "executionRunning": "Actualizando"
        },
        "jobs": {
            "jobLabel": "Trabajo",
            "jobLabel_plural": "Trabajos",
            "executeJob": "Ejecutar Trabajo",
            "createJobLabel": "Crear Trabajo",
            "addJobLabel": "Añadir Trabajo",
            "updateJobLabel": "Editar Trabajo",
            "deleteJobMessage": "¿Estás seguro de que quieres borrar el trabajo <b>\"{{- job}}\"</b>?",
            "deleteJobInfoMessage": "Todos los pasos, ejecuciones y planificaciones pertenecientes a este trabajo también serán borrados.",
            "timeoutLabel": "Tiempo máximo",
            "timeoutInfo": "Tiempo máximo de ejecución en segundos. Indica 0 para no tener límite.",
            "executionSuccess": "Trabajo ejecutado correctamente.",
            "isContainer": "Contenedor",
            "containerInfo": "Un trabajo contenedor solo puede tener trabajos. Un trabajo no contenedor solo puede tener pasos. Para cambiar este atributo, el trabajo no puede contener ningún otro trabajo o paso.",
            "selectJobLabel": "Elige un trabajo",
            "errorNotification": "Notificar si hay error",
            "recipients": "Destinatarios",
            "chooseRecipients": "Elige destinatarios"
        },
        "steps": {
            "stepLabel": "Paso",
            "stepLabel_plural": "Pasos",
            "addStepLabel": "Crear Paso",
            "editStepLabel": "Editar Paso",
            "deleteStepMessage": "¿Estás seguro de que quieres borrar el paso <b>\"{{- step}}\"</b>?",
            "inputsLabel": "Entradas",
            "outputsLabel": "Salidas",
            "noStepsInJob": "Este trabajo no tiene ningún paso. Usa el botón añadir para crear uno nuevo."
        },
        "stepTypes": {
            "stepTypeLabel": "Tipo de paso",
            "stepTypeLabel_plural": "Tipos de paso",
            "chooseStepTypeLabel": "Selecciona un tipo de paso"
        },
        "schedules": {
            "scheduleLabel": "Planificación",
            "scheduleLabel_plural": "Planificaciones",
            "oneTime": "Una vez",
            "recurrent": "Recurrente",
            "createScheduleLabel": "Crear Planificación",
            "editScheduleLabel": "Editar Planificación",
            "deleteScheduleMessage": "¿Estás seguro de que quieres borrar la planificación <b>\"{{- schedule}}\"</b>?",
            "scheduleNotValid": "La planificación no es válida",
            "whenLabel": "Cuando",
            "noOptionsLabel": "No hay opciones disponibles",
            "monthsLabel": "Meses",
            "weekDaysLabel": "Días de la semana",
            "monthDaysLabel": "Días del mes",
            "jobExecution": "El trabajo se ejecutará",
            "onLabel": "el",
            "atLabel": "a las",
            "onceLabel": "Una vez",
            "everyLabel": "Cada",
            "noSchedulesInJob": "Este trabajo no tiene ninguna planificación. Usa el botón añadir para crear una nueva.",
            "everyDayLabel": "Todos los días"
        }
    },
    "styles": {
        "styleLabel": "Estilo",
        "boxStyleLabel": "Estilo de caja",
        "border": {
            "borderLabel": "Borde",
            "borderLabel_plural": "Bordes",
            "borderStyleSolid": "Sólido",
            "borderStyleDotted": "Punteado",
            "borderStyleDashed": "Línea discontinua",
            "borderStyleDouble": "Doble Borde"
        },
        "padding": {
            "paddingLabel": "Indentado"
        },
        "transparencyLabel": "Transparencia",
        "borderStyles": {
            "none": "Ninguno",
            "hidden": "Oculto",
            "dotted": "Punteado",
            "dashed": "Línea discontinua",
            "solid": "Sólido",
            "double": "Doble borde",
            "groove": "Ranura",
            "ridge": "Cresta",
            "inset": "Inset",
            "outset": "Outset"
        },
        "shadow": {
            "shadowLabel": "Sombra"
        }
    },
    "sorting": {
        "configCustomOrder": "Configurar orden personalizado",
        "availableValues": "Valores disponibles",
        "remainingValuesOrder": "Ordenación de los valores restantes",
        "selectedValues": "Valores seleccionados",
        "showSelectedValues": "Mostrar valores seleccionados"
    },
    "navigation": {
        "title": "Navegación",
        "createLink": "Crear enlace",
        "editLink": "Editar enlace",
        "parent": "Padre",
        "noNameLink": "Enlace sin nombre",
        "withoutLink": "Sin enlace",
        "withLink": "Con enlace",
        "behaviour": "Comportamiento"
    },
    "actions": {
        "actionLabel": "Acción",
        "actionsLabel": "Acciones",
        "navigationMode": "Modo navegación",
        "backNavigationInfo": "Volver al origen de la navegación",
        "closeNavigationInfo": "Cerrar modo navegación permaneciendo en la ubicación actual",
        "actionName": "Nombre de la acción",
        "actionType": "Tipo de acción",
        "destinationCardSelect": "Elige tarjeta de destino",
        "destinationPageSelect": "Elige página de destino",
        "navigationOrigin": "Origen de navegación",
        "destinationFiltersMapping": "Mapeo de filtros de destino",
        "destinationNoFilters": "El destino seleccionado no tiene configurado ningún filtro",
        "urlLink": "Url del enlace",
        "actionsEditor": "Editor de Acciones",
        "cardField": "Campo de tarjeta",
        "cardField_plural": "Campos de tarjeta",
        "systemVariable": "Variable del sistema",
        "systemVariable_plural": "Variables del sistema",
        "cardFilter": "Filtro de tarjeta",
        "pageFilter": "Filtro de página",
        "mapFieldBy": "Mapear por:",
        "selectPageFilter": "Elige filtro en las páginas deseadas",
        "destinationFilterConfig": "Mapeo del filtro de destino",
        "mapConfig": "Configura el mapeo del filtro",
        "availableFields": "Campos disponibles",
        "availableSystemVariables": "Variables del Sistema disponibles",
        "urlHelp": "Puedes usar variables del sistema y campos de tarjeta para personalizar una URL dinámica.",
        "includeThemThisWay": "Inclúyelas de esta manera",
        "useAlternativeText": "Usar texto alternativo",
        "alternativeText": "Texto alternativo",
        "applyOn": "Aplicar en",
        "popupSize": "Tamaño del popup",
        "types": {
            "card_navigation": "Navegar a una tarjeta",
            "page_navigation": "Navegar a una página",
            "url_link": "Navegar a una dirección externa",
            "card_popup": "Mostrar tarjeta en un popup"
        },
        "showAlwaysActionMenu": "Mostrar siempre el menú de Acciones"
    },
    "home": {
        "title": "Inicio",
        "noDataMessage": "No se han encontrado {{value}} activos",
        "noDataMessage_female": "No se han encontrado {{value}} activas",
        "gettingStarted": {
            "title": "Empezando",
            "mainConcepts": "Es importante familiarizarse con los <0>conceptos principales sobre Biuwer y los conceptos básicos de navegación</0>.",
            "sampleSpace": "Hemos preparado para su organización un <0>espacio compartido de muestra</0>, llamado <0>\"Film Rentals\"</0> para ayudarlo a usted y a su equipo a comenzar con Biuwer. Incluye una página con 7 tarjetas.",
            "sampleArticle": "Consulte este artículo en <0>Biuwer Docs</0> para obtener más detalles:",
            "glossaryLink": "Glosario",
            "homePageLink": "Página de inicio y navegación",
            "sampleArticleLink": "El espacio de Film Rentals"
        },
        "needHelp": {
            "title": "¿Necesitas ayuda?",
            "docs": "<0>Biuwer Docs</0> es nuestra base de conocimiento donde puede encontrar una explicación detallada sobre cómo usar Biuwer.",
            "docsButton": "Acceder a Biuwer Docs",
            "help": "Se puede acceder al <0>Centro de ayuda de Biuwer</0> a través de nuestro chat en línea, para obtener asistencia rápida del equipo de Biuwer.",
            "helpButton": "Hablar con soporte"
        }
    },
    "errors": {
        "unknownError": "Ha ocurrido un error. Contacta con soporte o con tu contacto principal en Biuwer",
        "errorMessage": "Mensaje de error",
        "5": "El correo electrónico solicitado no existe",
        "6": "La contraseña no es correcta",
        "7": "La información del usuario proporcionada es insuficiente. Contacte con el administrador",
        "8": "Error al generar el token. Contacte con el administrador",
        "120": "El usuario no está activo. Contacte con el administrador",
        "121": "El campo email es obligatorio",
        "122": "La solicitud de acceso ha expirado o no existe. Por favor vuelve a solicitar una nueva contraseña",
        "123": "El ID de usuario y el email deben de estar especificados para cambiar la contraseña",
        "124": "Las contraseñas no coinciden",
        "125": "El usuario no está logado",
        "126": "Por favor inserta una nueva contraseña",
        "127": "El usuario no ha sido encontrado",
        "128": "La contraseña actual es incorrecta",
        "130": "Error en el proceso. Contacte con el administrador",
        "11000": "El {{varName}} ya está siendo utilizado",
        "10000007": "La organización del usuario está inactiva o no existe",
        "queryDataError": "Error al consultar datos",
        "USER_EXISTS": "El usuario esta registrado. Contacte con el administrador",
        "SIGNUP_TOKEN_EXPIRED_NOT_EXIST": "Token no valido o ha expirado",
        "DATASET_FIELD_DEPENDENCIES": "Uno de los campos no seleccionados del Conjunto de Datos está siendo utilizado",
        "EMAIL_DOMAIN_NOT_VALID": "Dominio de email no válido",
        "REQUEST_TOO_RECENT": "Se ha realizado esta solicitud recientemente. Si quieres volver a realizarla, prueba en unos minutos",
        "ORDER_ALREADY_PAID": "Pedido pagado",
        "UNABLE_RECOVER_ORDER": "Pedido no encontrado",
        "ErrorBoundary": {
            "title": "¡Ooops! Algo no ha funcionado correctamente",
            "message": "Por favor inténtalo de nuevo. Si necesitas ayuda no dudes en contactar mediante el chat en línea."
        }
    },
    "warnings": {
        "-10000004": "Debido a un problema interno se han obtenido datos parciales",
        "rowLimitReached": "Límite de registros alcanzado",
        "noContent": {
            "message": "Actualmente no tienes acceso a ningún {{object}}",
            "message_female": "Actualmente no tienes acceso a ninguna {{object}}",
            "createLabel": "Crea uno",
            "createLabel_female": "Crea una",
            "createOrRequestLabel": "Crea uno o solicita acceso",
            "createOrRequestLabel_female": "Crea una o solicita acceso",
            "requestLabel": "Solicita acceso"
        },
        "noQueryExecuted": "No se han obtenido datos para la consulta seleccionada.",
        "noDataObtainedForPage": "Aún no se han obtenido resultados para la página.",
        "noDataObtainedForCard": "Aún no se han obtenido resultados para la tarjeta.",
        "clickPreviewToExecute": "Haz click en Previsualizar para ejecutar la consulta.",
        "clickRunToExecute": "Haz click en Ejecutar para lanzar la consulta.",
        "clickRunToExecutePlural": "Haz click en Ejecutar para lanzar las consultas."
    },
    "common": {
        "errorMessageLabel": "Mensaje de error",
        "enabled": "Habilitado",
        "enabled_female": "Habilitada",
        "enabled_plural": "Habilitados",
        "enabled_female_plural": "Habilitadas",
        "disabled": "Deshabilitado",
        "disabled_female": "Deshabilitada",
        "disabled_plural": "Deshabilitados",
        "disabled_female_plural": "Deshabilitadas",
        "valid": "Válido",
        "valid_female": "Válida",
        "valid_plural": "Válidos",
        "valid_female_plural": "Válidas",
        "invalid": "Inválido",
        "invalid_female": "Inválida",
        "invalid_plural": "Inválidos",
        "invalid_female_plural": "Inválidas",
        "config": "Configurar",
        "generalLabel": "General",
        "securityLabel": "Seguridad",
        "orderLabel": "Ordenar",
        "seeLabel": "Ver",
        "seeDetailLabel": "Ver detalle",
        "addLabel": "Añadir",
        "createLabel": "Crear",
        "createObject": "Crear {{object}}",
        "created": "Creado",
        "created_female": "Creada",
        "createdOn": "Creado el",
        "createdBy": "Creado por",
        "updatedOn": "Actualizado el",
        "updatedBy": "Actualizado por",
        "cloneLabel": "Clonar",
        "editLabel": "Editar",
        "editObject": "Editar {{object}}",
        "deleteLabel": "Eliminar",
        "activateLabel": "Activar",
        "deactivateLabel": "Desactivar",
        "previewLabel": "Previsualizar",
        "visualizationLabel": "Visualización",
        "saveLabel": "Guardar",
        "saveChangesLabel": "Guardar cambios",
        "pendingChangesLabel": "Hay cambios realizados pendientes de guardar",
        "acceptLabel": "Aceptar",
        "cancelLabel": "Cancelar",
        "chooseLabel": "Elige",
        "uploadLabel": "Subir",
        "downloadLabel": "Descargar",
        "applyLabel": "Aplicar",
        "applyThisLabel": "Aplicar {{object}}",
        "configureLabel": "Configurar",
        "configurationLabel": "Configuración",
        "generalConfigLabel": "Configuración general",
        "statusLabel": "Estado",
        "statusLabel_plural": "Estados",
        "createdLabel": "Creado el {{date}} por {{name}}",
        "updatedLabel": "Última modificación el {{date}} por {{name}}",
        "sendWelcomeEmailLabel": "Enviar email de bienvenida",
        "pendingLabel": "Pendiente",
        "activeLabel": "Activo",
        "activeLabel_plural": "Activos",
        "activeLabel_female": "Activa",
        "activeLabel_female_plural": "Activas",
        "inactiveLabel": "Inactivo",
        "inactiveLabel_plural": "Inactivos",
        "inactiveLabel_female": "Inactiva",
        "inactiveLabel_female_plural": "Inactivas",
        "openedLabel": "Abierto",
        "openedLabel_plural": "Abiertos",
        "openedLabel_female": "Abierta",
        "openedLabel_female_plural": "Abiertas",
        "closedLabel": "Cerrado",
        "closedLabel_plural": "Cerrados",
        "closedLabel_female": "Cerrada",
        "closedLabel_female_plural": "Cerradas",
        "deletedLabel": "Eliminado",
        "loadingLabel": "Cargando",
        "noDataFilterMessage": "No hay {{name}} disponibles con los filtros seleccionados",
        "noDataMessage": "No hay {{name}} disponibles actualmente",
        "noDataShortMessage": "No hay {{name}}",
        "noDataForAppliedFiltersMessage": "No hay datos para la selección realizada",
        "noFiltersAppliedMessage": "Configura los filtros y ejecuta la consulta",
        "noSelectedMessage": "No se ha seleccionado ningún {{name}}",
        "noSelectedMessage_female": "No se ha seleccionado ninguna {{name}}",
        "allSelectedMessage": "Todos los {{name}} han sido seleccionados",
        "allSelectedMessage_female": "Todas las {{name}} han sido seleccionadas",
        "selectMessage": "Selecciona un {{name}}",
        "selectMessage_female": "Selecciona una {{name}}",
        "selectActionLabel": "Selecciona",
        "selectLabel": "Seleccionar",
        "selectedLabel": "Seleccionado",
        "selectedLabel_female": "Seleccionada",
        "selectedLabel_plural": "Seleccionados",
        "selectedLabel_female_plural": "seleccionadas",
        "selectWeekLabel": "Selecciona una semana",
        "dateLabel": "Fecha",
        "createDateLabel": "Fecha de creación",
        "updatedDateLabel": "Fecha de modificación",
        "uploadDateLabel": "Fecha de subida",
        "sizeLabel": "Tamaño",
        "chooseSizeLabel": "Elige tamaño",
        "veryBigLabel": "Muy grande",
        "bigLabel": "Grande",
        "mediumLabel": "Mediano",
        "smallLabel": "Pequeño",
        "weekLabel": "Semana",
        "numberLabel": "Nº",
        "dotLabel": "Punto",
        "commaLabel": "Coma",
        "bothLabel": "Ambos",
        "locationLabel": "Localización",
        "locationLabel_plural": "Localizaciones",
        "provinceLabel": "Provincia",
        "noProvinceLabel": "Sin provincia",
        "provinceSelectActionLabel": "Selecciona una Provincia",
        "townLabel": "Municipio",
        "noTownLabel": "Sin municipio",
        "unitSelectLabel": "Selecciona una unidad",
        "unitLabel": "Unidad",
        "unitLabel_plural": "Unidades",
        "requiredFieldLabel": "El campo es obligatorio",
        "requiredNumericField": "El campo debe ser numérico",
        "allLabel": "Todos",
        "allLabel_female": "Todas",
        "noneLabel": "Ninguno",
        "noneLabel_female": "Ninguna",
        "quantityLabel": "Cantidad",
        "quantityLabel_plural": "Cantidades",
        "actionLabel": "Acción",
        "actionLabel_plural": "Acciones",
        "commentLabel": "Comentario",
        "commentLabel_plural": "Comentarios",
        "noLabel": "Sin",
        "totalLabel": "Total",
        "totalLabel_plural": "Totales",
        "pctLabelText_short": "Pct",
        "pctLabelText_large": "Porcentaje",
        "pctLabelSymbol": "%",
        "overviewLabel": "Resumen",
        "goBackLabel": "Volver",
        "newLabel": "Nuevo",
        "newLabel_female": "Nueva",
        "openLabel": "Abrir",
        "closeLabel": "Cerrar",
        "archiveLabel": "Archivar",
        "idLabel": "Id",
        "cleanLabel": "Limpiar",
        "andLabel": "y",
        "orLabel": "o",
        "inLabel": "en",
        "recordsObtained": "Registros obtenidos",
        "recordsShown": "Mostrados",
        "showingLabel": "Mostrando",
        "showLabel": "Mostrar",
        "dataLabel": "datos",
        "searchLabel": "Buscar",
        "deleteRow": "Eliminar fila",
        "deleteRowDeleteMessage": "¿Estás seguro de que quieres eliminar la fila seleccionada?",
        "updateLabel": "Actualizar",
        "duplicateLabel": "Duplicar",
        "codeLabel": "Código",
        "yes": "Sí",
        "no": "No",
        "hideLabel": "Ocultar",
        "stickLabel": "Fijar",
        "nameLabel": "Nombre",
        "descLabel": "Descripción",
        "typeLabel": "Tipo",
        "emailLabel": "Email",
        "calculate": "Calcular",
        "recalculate": "Recalcular",
        "character": "Caracter",
        "character_plural": "Caracteres",
        "invalidDate": "Fecha incorrecta",
        "itAlreadyExists": "El {{objectVarName}} ya existe",
        "itAlreadyExists_female": "La {{objectVarName}} ya existe",
        "informationLabel": "Información",
        "integrationLabel": "Integración",
        "statisticLabel": "Estadística",
        "statisticLabel_plural": "Estadísticas",
        "mainInformationLabel": "Información principal",
        "generalInformationLabel": "Información general",
        "fieldMustBeNumber": "El campo tiene que ser numérico",
        "noResult": "Sin resultado",
        "noResult_plural": "Sin resultados",
        "phoneLabel": "Teléfono",
        "addressLabel": "Dirección",
        "preferencesLabel": "Preferencias",
        "generateLabel": "Generar",
        "selectedXVariables": "Has seleccionado {{numberVariables}} {{variableLabel}}",
        "stopApplying": "Dejar de aplicar",
        "listLabel": "Lista",
        "listingLabel": "Listado",
        "internalLabel": "Interno",
        "externalLabel": "Externo",
        "linkLabel": "Enlace",
        "filenameLabel": "Nombre del archivo",
        "widthLabel": "Ancho",
        "heightLabel": "Alto",
        "valueLabel": "Valor",
        "valueLabel_plural": "Valores",
        "allowedValueLabel": "Valor permitido",
        "allowedValueLabel_plural": "Valores permitidos",
        "defaultValueLabel": "Valor por defecto",
        "menuLabel": "Menú",
        "menuLabel_plural": "Menús",
        "shownLabel": "Mostrado",
        "hiddenLabel": "Oculto",
        "deactivatedLink": "Enlace desactivado",
        "menuTreeLabel": "Árbol del menú",
        "descriptionLabel": "Descripción",
        "backLabel": "Atrás",
        "rowLabel": "Fila",
        "rowsLabel": "Filas",
        "metricLabel": "Métrica",
        "metricLabel_plural": "Métricas",
        "dimensionLabel": "Dimensión",
        "y-axisLabel": "Eje Y",
        "x-axisLabel": "Eje X",
        "colorBy": "Color por",
        "dimensionLabel_plural": "Dimensiones",
        "breakByLabel": "Dividir por",
        "insideLabel": "Dentro",
        "outsideLabel": "Fuera",
        "leftLabel": "Izquierda",
        "rightLabel": "Derecha",
        "centerLabel": "Centro",
        "topLabel": "Arriba",
        "bottomLabel": "Abajo",
        "topLeftLabel": "Arriba izquierda",
        "bottomLeftLabel": "Abajo izquierda",
        "topRightLabel": "Arriba derecha",
        "bottomRightLabel": "Abajo derecha",
        "topAndBottom": "Arriba y abajo",
        "formatLabel": "Formato",
        "minMaxFormatLabel": "Formato Mínimo/Máximo",
        "slicesLimit": "Limitar valores mostrados",
        "slicesNumber": "Número de valores",
        "showSliceLimit": "Mostrar resto en acumulado",
        "slicesDefaultName": "Otros",
        "labelLabel": "Etiqueta",
        "minLabel": "Mínimo",
        "minLabel_female": "Mínima",
        "maxLabel": "Máximo",
        "maxLabel_female": "Máxima",
        "columnLabel": "Columna",
        "columnsLabel": "Columnas",
        "separatorLabel": "Separador",
        "styleLabel": "Estilo",
        "conditionalStyle": "Estilo condicional",
        "rowConditionalStyle": "Estilo condicional de fila",
        "chartConditionalStyle": "Estilo condicional de gráfico",
        "of": "de",
        "positionLabel": "Posición",
        "legendLabel": "Leyenda",
        "runLabel": "Ejecutar",
        "titleLabel": "Título",
        "congratulationsLabel": "¡Felicidades!",
        "favoriteLabel": "Favorito",
        "favoriteLabel_plural": "Favoritos",
        "favoritesFirst": "Favoritos primero",
        "welcome": "Bienvenido",
        "welcomeTo": "Bienvenido a {{value}}",
        "welcomeText": "Conécta tus datos, visualízalos y obtén el conocimiento para mejorar tus objetivos de negocio",
        "fontLabel": "Fuente de texto",
        "alignmentLabel": "Alineación",
        "colorLabel": "Color",
        "colorLabel_plural": "Colores",
        "decorationLabel": "Formato de texto",
        "decimalsLabel": "Decimales",
        "textLabel": "Texto",
        "numberTypeLabel": "Tipo de número",
        "chooseNumberTypeLabel": "Elige tipo de número",
        "numberLongLabel": "Número",
        "percentageLabel": "Porcentaje",
        "currencyLabel": "Moneda",
        "chooseCurrencyLabel": "Elige moneda",
        "symbolPositionLabel": "Posición del símbolo",
        "beforeLabel": "Antes",
        "afterLabel": "Después",
        "alwaysShowAllDecimalsLabel": "Mostrar siempre todos los decimales",
        "thousandsSeparatorLabel": "Separador de miles",
        "useParenthesisAsNegative": "Negativos entre paréntesis",
        "useNumericPrefix": "Usar prefijos numéricos",
        "currencyEuroLabel": "Euro (€)",
        "currencyEuroLongLabel": "Euro (EUR, €)",
        "currencyDollarLabel": "Dólar ($)",
        "currencyDollarLongLabel": "Dólar US (USD, $)",
        "currencyPoundLabel": "Libra (£)",
        "currencyPoundLongLabel": "Libra esterlina (GBP, £)",
        "currencyYenLabel": "Yen (¥)",
        "currencyYenLongLabel": "Yen japonés (JPY, ¥)",
        "currencyYuanLabel": "Yuan (元)",
        "currencyYuanLongLabel": "Yuan chino (CNY, 元)",
        "originLabel": "Origen",
        "usingLabel": "usando",
        "aliasLabel": "Alias",
        "ofLabel": "de",
        "dimension": "Dimensión",
        "measure": "Métrica",
        "changeLabel": "Cambiar",
        "importLabel": "Importar",
        "readLabel": "Leer",
        "printIt": "Imprimir {{object}}",
        "generatedByLabel": "Generado por BIUWER",
        "logicLabel": "Lógica",
        "expressionNotValidated": "La expresión no es correcta",
        "seeObject": "Ver {{object}}",
        "deleteObject": "Eliminar {{object}}",
        "configureObject": "Configurar {{object}}",
        "builtinLabel": "Predefinido",
        "builtinLabel_female": "Predefinida",
        "builtinLabel_plural": "Predefinidos",
        "builtinLabel_female_plural": "Predefinidas",
        "customLabel": "Personalizado",
        "customLabel_female": "Personalizada",
        "customLabel_plural": "Personalizados",
        "customLabel_female_plural": "Personalizadas",
        "nullLabel": "NULO",
        "marginLabel": "Margen",
        "marginLabel_plural": "Márgenes",
        "nextLabel": "Siguiente",
        "autodetect": "Autodetectar",
        "recordBehavior": "Comportamiento de registros",
        "showRecordsWithoutValue": "Mostrar registros sin valor",
        "showRecordsWithoutValueInfo": {
            "p1": "Esta opción aplica los filtros mediante sub consultas, antes de aplicar las relaciones entre Conjuntos de Datos, consiguiendo así mostrar en todos los casos los registros que no tengan valor.",
            "p2": "Tiene sentido con OUTER JOINS aplicados a la consulta y con una configuración en la que intervengan dos o más tablas por la aplicación de los filtros configurados." ,
            "p3": "Por ejemplo, si en un Modelo de Datos relacionas una dimensión de tiempo y una tabla de hechos, en cuyos datos no hay valores todos los días, pero quieres que aparezcan siempre todos los días aunque no haya datos en algunos de ellos."
        },
        "rowLimit": "Límite de filas",
        "recordLimit": "Límite de registros",
        "unlimited": "Registros sin límite",
        "numberOfRecords": "Número de registros",
        "numberOfRows": "Número de filas",
        "recoveredRows": "Filas obtenidas",
        "displayedRows": "Filas mostradas",
        "noGroupBy": "No agrupar valores",
        "formula": "Fórmula",
        "layout": "Plantilla",
        "updateAvailableLabel": "Hay una nueva versión disponible de Biuwer",
        "updateAppLabel": "Actualizar",
        "absoluteLabel": "Absoluto",
        "relativeLabel": "Relativo",
        "timeMetric": "¿Es una métrica de tiempo?",
        "timeLevel": "Nivel de tiempo",
        "timeReferenceType": "Referencia de tiempo",
        "timeReferenceOption": "Referencia",
        "timeFormat": "Formato de tiempo",
        "lastConnection": "Última conexión",
        "lastUpdate": "Última modificación",
        "fieldType": "Tipo de campo",
        "multiValueLabelDesc": "Permitir múltiples valores",
        "multiValueLabel": "Múltiple",
        "visibleLabel": "Visible",
        "editableLabel": "Editable",
        "enumerate": "Enumerado",
        "destinationLabel": "Destino",
        "public": "Público",
        "private": "Privado",
        "enable": "Habilitar",
        "disable": "Deshabilitar",
        "validFrom": "Válido desde",
        "validTo": "Válido hasta",
        "copyToClipboard": "Copiar al portapapeles",
        "variableLabel": "Variable",
        "basic": "Básico",
        "standard": "Estándar",
        "advanced": "Avanzado",
        "selectObject": "Selecciona un {{object}}",
        "selectObject_female": "Selecciona una {{object}}",
        "assign" : "Asignar",
        "assignObject": "Asignar {{object}}",
        "unassign" : "Desasignar",
        "unassignObject": "Desasignar {{object}}",
        "help": "Ayuda",
        "usage": {
            "usageLabel": "Uso",
            "changeHistory": "Historial de cambios",
            "whereItsUsed": "Dónde se usa",
            "whereItsUsed_male": "Dónde se usa el",
            "whereItsUsed_female": "Dónde se usa la",
            "usageStats": "Estádisticas de uso",
            "emptyContent": "Este {{object}} no está relacionado con ningún objeto.",
            "emptyContent_female": "Esta {{object}} no está relacionada con ningún objeto."
        },
        "trialLayer": {
            "trialingTodayLabel": "El periodo de prueba de tu organización termina hoy.",
            "trialingLabel": "El periodo de prueba de tu organización termina en 1 día.",
            "trialingLabel_plural": "El periodo de prueba de tu organización termina en {{count}} días.",
            "trialExpiredLabel": "El periodo de prueba de tu organización ha terminado.",
            "configureSubscriptionLabel": "Configura tu suscripción ahora",
            "chatWithUsLabel": "Chatea con nosotros y obtén una extensión"
        },
        "verified": "Verificado",
        "unverified": "No verificado",
        "categoryLabel": "Categoría",
        "cancelled": "Cancelado",
        "cancelled_female": "Cancelada",
        "featureWall": {
            "p1": "Mejora disponible",
            "p2": "La funcionalidad a la que intentas acceder está disponible en planes superiores.",
            "p3": "Mejora tu plan para disfrutar de esta y más características",
            "seePlans": "Ver Planes",
            "limitP1": "Límite alcanzado",
            "limitP2": "Tu organización ya ha utilizado <0>{{current}} de {{limit}} {{entity}}</0> disponible en la suscripción actual.",
            "limitP2_plural": "Tu organización ya ha utilizado <0>{{current}} de {{limit}} {{entity}}</0> disponibles en la suscripción actual.",
            "limitP3": "Mejora tu plan para ampliar los límites actuales",
            "chatWithUs": "Chatea con nosotros"
        },
        "predefined": "Predefinidos",
        "sample": "Ejemplo",
        "noValue": "Sin valor",
        "key": "Clave",
        "value": "Valor",
        "comingSoon": "Próximamente",
        "button": "Botón",
        "writeEmail": "Introduce email"
    },
    "timeReferenceOptions": {
        "startOfMinute": "Comienzo del minuto",
        "startOfHour": "Comienzo de la hora",
        "startOfDay": "Comienzo del día",
        "startOfWeek": "Comienzo de la semana",
        "startOfMonth": "Comienzo del mes",
        "startOfYear": "Comienzo del año",
    },
    "dataTypes": {
        "string": "Texto",
        "number": "Número",
        "date": "Fecha",
        "datetime": "Fecha y hora",
        "boolean": "Booleano"
    },
    "measure": {
        "timeUnits": {
            "second": "segundo",
            "second_plural": "segundos",
            "minute": "minuto",
            "minute_plural": "minutos",
            "hour": "hora",
            "hour_plural": "horas",
            "week": "semana",
            "week_plural": "semanas",
            "quarter": "cuatrimestre",
            "quarter_plural": "cuatrimestres",
            "month": "mes",
            "month_plural": "meses",
            "year": "año",
            "year_plural": "años",
            "day": "día",
            "day_plural": "días"
        }
    },
    "payment": {
        "paymentInformationLabel": "Información para pagos y facturación",
        "paymentMethod": "Método de pago",
        "paymentMethod_plural": "Métodos de pago",
        "directDebitMethod": "Recibo domiciliado",
        "accountNumberIBAN": "Cuenta bancaria (IBAN)",
        "accountNumberIBANIncorrectMessage": "El código IBAN no es correcto",
        "creditCardSuccess": "El pago con tarjeta se ha procesado correctamente",
        "creditCardError": {
            "declined": "Su tarjeta ha sido rechazada",
            "incomplete_card": "Por favor, introduce los datos de su tarjeta",
            "incomplete_cvc": "El código de seguridad de su tarjeta está incompleto",
            "incomplete_expiry": "La fecha de caducidad de su tarjeta está incompleta",
            "incomplete_number": "El número de su tarjeta está incompleto",
            "expired_card": "Su tarjeta ha caducado",
            "card_declined_card_not_supported": "No se admite esta tarjeta",
            "invalid_brand": "No se admite ese tipo de tarjeta",
            "incorrect_cvc": "El código de seguridad de su tarjeta es incorrecto",
            "incorrect_number": "El número de su tarjeta es incorrecto",
            "invalid_cvc": "El código de seguridad de su tarjeta no es válido",
            "invalid_expiry_month": "La fecha de caducidad de su tarjeta no es válida",
            "invalid_expiry_month_past": "La fecha de caducidad de su tarjeta ya ha pasado",
            "invalid_expiry_year": "El año de caducidad de su tarjeta no es válido",
            "invalid_expiry_year_past": "El año de caducidad de su tarjeta ya pasó",
            "invalid_number": "El número de su tarjeta no es válido",
            "unexpected": "Ha ocurrido un error inesperado",
            "authentication_failure": "No hemos podido autenticar su método de pago",
            "payment_intent_authentication_failure": "No hemos podido autenticar su método de pago",
            "setup_intent_authentication_failure": "No hemos podido autenticar su método de pago",
            "insufficient_funds": "La tarjeta no tiene fondos suficientes",
        },
        "creditCardAlert": {
            "noCardMessage": "Para pagar con tarjeta, es necesario añadir una previamente",
            "noCardCheckoutMessage": "Para pagar con tarjeta, es necesario añadir una tarjeta en Detalles de la Organización",
            "invalidCardMessage": "La tarjeta no es válida, actualícela para poder seguirla utilizando",
            "invalidCardCheckoutMessage": "La tarjeta no es válida, actualícela en Detalles de la Organización para poder seguirla utilizando",
            "deleteCardMessage": "¿Está seguro que desea eliminar la tarjeta que termina en {{last4}}? Dejará de estar disponible para el pago de pedidos"
        },
        "creditCards": {
            "title": "Tarjeta",
            "title_plural": "Tarjetas",
            "description": "Aceptamos tarjetas débito o crédito de VISA y MasterCard",
            "addLabel": "Añadir tarjeta",
            "editLabel": "Editar tarjeta",
            "setAsDefault": "Establecer por defecto"
        },
        "status": {
            "pending": "Pendiente",
            "pending_female": "Pendiente",
            "pending_plural": "Pendientes",
            "pending_female_plural": "Pendientes",
            "paid": "Pagado",
            "paid_female": "Pagada",
            "paid_plural": "Pagados",
            "paid_female_plural": "Pagadas",
            "cancelled": "Cancelado",
            "cancelled_female": "Cancelada",
            "cancelled_plural": "Cancelados",
            "cancelled_female_plural": "Canceladas"
        },
        "paymentLinkPage": {
            "title": "Pago de pedido",
            "orderDetails": "Detalles del pedido",
            "orderAlreadyPaid": "El pedido número {{orderNumber}} ya está pagado",
            "invalidPaymentLink": "El enlace de pago no es válido"
        }
    },
    "tables": {
        "configureLabel": "Configuración de tabla"
    },
    "oauth": {
        "title": "Autorización Oauth",
        "authorize": "Obtener autorización",
        "authorizedWith": "Autorizado con {{user}}",
        "changeAuthorization": "Cambiar autorización",
        "googleAuthorize": "Autorizar Google",
        "facebookAuthorize": "Autorizar Facebook",
        "authorizing": "Autorizando",
        "canceling": "Cancelando",
        "reGoogleAuthorize": "Cambiar Autorizacion Google",
        "relatedFields": {
            "analyticsAccount": "Cuenta",
            "property": "Propiedad",
            "view": "Vista",
            "spreadsheet": "Documento",
            "documentMode": "Identificación de Documento",
            "account": "Cuenta",
            "managerAccount": "Cuenta de Administrador",
            "adAccount": "Cuenta Publicitaria"
        }
    },
    "notifications": {
        "createSuccess_male": "{{name}} creado correctamente",
        "createSuccess_female": "{{name}} creada correctamente",
        "createSuccess_male_plural": "{{name}} creados correctamente",
        "createSuccess_female_plural": "{{name}} creadas correctamente",

        "addSuccess_male": "{{name}} añadido correctamente",
        "addSuccess_female": "{{name}} añadida correctamente",
        "addSuccess_male_plural": "{{name}} añadidos correctamente",
        "addSuccess_female_plural": "{{name}} añadidas correctamente",

        "assignSuccess_male": "{{name}} asignado correctamente",
        "assignSuccess_female": "{{name}} asignada correctamente",
        "assignSuccess_male_plural": "{{name}} asignados correctamente",
        "assignSuccess_female_plural": "{{name}} asignadas correctamente",

        "updateSuccess_male": "{{name}} actualizado correctamente",
        "updateSuccess_female": "{{name}} actualizada correctamente",
        "updateSuccess_male_plural": "{{name}} actualizados correctamente",
        "updateSuccess_female_plural": "{{name}} actualizadas correctamente",

        "moveSuccess_male": "{{name}} movido correctamente",
        "moveSuccess_female": "{{name}} movida correctamente",
        "moveSuccess_male_plural": "{{name}} movidos correctamente",
        "moveSuccess_female_plural": "{{name}} movidas correctamente",

        "duplicateSuccess_male": "{{name}} duplicado correctamente",
        "duplicateSuccess_female": "{{name}} duplicada correctamente",
        "duplicateSuccess_male_plural": "{{name}} duplicados correctamente",
        "duplicateSuccess_female_plural": "{{name}} duplicadas correctamente",

        "copyToClipboardSuccess_male": "{{name}} copiado al portapapeles correctamente",
        "copyToClipboardSuccess_female": "{{name}} copiada al portapapeles correctamente",
        "copyToClipboardSuccess_male_plural": "{{name}} copiados al portapapeles correctamente",
        "copyToClipboardSuccess_female_plural": "{{name}} copiadas al portapapeles correctamente",

        "cancelSuccess_male": "{{name}} cancelado correctamente",
        "cancelSuccess_female": "{{name}} cancelada correctamente",
        "cancelSuccess_male_plural": "{{name}} cancelados correctamente",
        "cancelSuccess_female_plural": "{{name}} canceladas correctamente",

        "deleteSuccess_male": "{{name}} eliminado correctamente",
        "deleteSuccess_female": "{{name}} eliminada correctamente",
        "deleteSuccess_male_plural": "{{name}} eliminados correctamente",
        "deleteSuccess_female_plural": "{{name}} eliminadas correctamente",

        "unassignSuccess_male": "{{name}} desasignado correctamente",
        "unassignSuccess_female": "{{name}} desasignada correctamente",
        "unassignSuccess_male_plural": "{{name}} desasignados correctamente",
        "unassignSuccess_female_plural": "{{name}} desasignadas correctamente",

        "importSuccess_male": "{{name}} importado correctamente",
        "importSuccess_female": "{{name}} importada correctamente",
        "importSuccess_male_plural": "{{name}} importados correctamente",
        "importSuccess_female_plural": "{{name}} importadas correctamente",

        "sendWelcomeEmailSuccess": "Email de bienvenida enviado correctamente",
        "sendWelcomeEmailError": "Error en el envío del email de bienvenida",
        "sendingWelcomeEmail": "Eviando el email de bienvenida...",

        "favoritesUpdated": "Favoritos actualizados",
        "switchedToAdvancedSuccess": "Usuario {{name}} cambiado a Avanzado correctamente",
        "switchedToBasicSuccess": "Usuario {{name}} cambiado a Básico correctamente",

        "downloadPDFSuccessMessage": "PDF generado con éxito, guardando archivo ...",
        "downloadFileSuccessMessage": "Fichero generado con éxito, guardando archivo ...",

        "error": "Ha ocurrido un error",
        "errorPrintPDF": "Error al generar PDF",
        "errorPrintFile": "Error al generar fichero",

        "generatingFile":"Generando fichero ...",
        "downloadingFile": "Descargando fichero ...",
        "generatingPDF":"Generando PDF ...",
        "downloadingPDF": "Descargando PDF ...",

        "nextExecution": "Los datos se obtendrán el {{date}}",
        "currentExecution": "Los datos estarán disponibles en unos minutos",
        "spaceLink": "Accede al contenido en el espacio",
        "accessContent": "Accede al contenido",
    },
    "timeUnits": {
        "second_short": "s",
        "second_long": "segundo",
        "second_long_plural": "segundos",
        "minute_short": "mi",
        "minute_long": "minuto",
        "minute_long_plural": "minutos",
        "hour_short": "h",
        "hour_long": "hora",
        "hour_long_plural": "horas",
        "day_short": "d",
        "day_long": "día",
        "day_long_plural": "días",
        "month_short": "m",
        "month_long": "mes",
        "month_long_plural": "meses",
        "year_short": "a",
        "year_long": "año",
        "year_long_plural": "años"
    },
    "iconLabels": {
        "icon":"Icono",
        "icon_plural":"Iconos",
        "display_icon_question":"¿Mostrar Icono?"
    },
    "countries": {
        "AF": "Afganistán",
        "AL": "Albania",
        "DE": "Alemania",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antártida",
        "AG": "Antigua y Barbuda",
        "SA": "Arabia Saudí",
        "DZ": "Argelia",
        "AR": "Argentina",
        "AM": "Armenia",
        "AW": "Aruba",
        "AU": "Australia",
        "AT": "Austria",
        "AZ": "Azerbaiyán",
        "BS": "Bahamas",
        "BH": "Bahrein",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BE": "Bélgica",
        "BZ": "Belice",
        "BJ": "Benin",
        "BM": "Bermudas",
        "BY": "Bielorrusia",
        "MM": "Birmania",
        "BO": "Bolivia",
        "BA": "Bosnia y Herzegovina",
        "BW": "Botswana",
        "BR": "Brasil",
        "BN": "Brunei",
        "BG": "Bulgaria",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "BT": "Bután",
        "CV": "Cabo Verde",
        "KH": "Camboya",
        "CM": "Camerún",
        "CA": "Canadá",
        "TD": "Chad",
        "CL": "Chile",
        "CN": "China",
        "CY": "Chipre",
        "VA": "Ciudad del Vaticano (Santa Sede)",
        "CO": "Colombia",
        "KM": "Comores",
        "CG": "Congo",
        "CD": "Congo, República Democrática del",
        "KR": "Corea",
        "KP": "Corea del Norte",
        "CI": "Costa de Marfíl",
        "CR": "Costa Rica",
        "HR": "Croacia (Hrvatska)",
        "CU": "Cuba",
        "CW": "Curaçao",
        "DK": "Dinamarca",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "EC": "Ecuador",
        "EG": "Egipto",
        "SV": "El Salvador",
        "AE": "Emiratos Árabes Unidos",
        "ER": "Eritrea",
        "SI": "Eslovenia",
        "ES": "España - Península y Baleares",
        "ES_CANARY": "España - Islas Canarias",
        "US": "Estados Unidos",
        "EE": "Estonia",
        "ET": "Etiopía",
        "FJ": "Fiji",
        "PH": "Filipinas",
        "FI": "Finlandia",
        "FR": "Francia",
        "GA": "Gabón",
        "GM": "Gambia",
        "GE": "Georgia",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GD": "Granada",
        "GR": "Grecia",
        "GL": "Groenlandia",
        "GP": "Guadalupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GY": "Guyana",
        "GF": "Guayana Francesa",
        "GN": "Guinea",
        "GQ": "Guinea Ecuatorial",
        "GW": "Guinea-Bissau",
        "HT": "Haití",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hungría",
        "IN": "India",
        "ID": "Indonesia",
        "IQ": "Irak",
        "IR": "Irán",
        "IE": "Irlanda",
        "BV": "Isla Bouvet",
        "CX": "Isla de Christmas",
        "IS": "Islandia",
        "KY": "Islas Caimán",
        "CK": "Islas Cook",
        "CC": "Islas de Cocos o Keeling",
        "FO": "Islas Faroe",
        "HM": "Islas Heard y McDonald",
        "FK": "Islas Malvinas",
        "MP": "Islas Marianas del Norte",
        "MH": "Islas Marshall",
        "UM": "Islas menores de Estados Unidos",
        "PW": "Islas Palau",
        "SB": "Islas Salomón",
        "SJ": "Islas Svalbard y Jan Mayen",
        "TK": "Islas Tokelau",
        "TC": "Islas Turks y Caicos",
        "VI": "Islas Vírgenes (EE.UU.)",
        "VG": "Islas Vírgenes (Reino Unido)",
        "WF": "Islas Wallis y Futuna",
        "IL": "Israel",
        "IT": "Italia",
        "JM": "Jamaica",
        "JP": "Japón",
        "JO": "Jordania",
        "KZ": "Kazajistán",
        "KE": "Kenia",
        "KG": "Kirguizistán",
        "KI": "Kiribati",
        "KW": "Kuwait",
        "LA": "Laos",
        "LS": "Lesotho",
        "LV": "Letonia",
        "LB": "Líbano",
        "LR": "Liberia",
        "LY": "Libia",
        "LI": "Liechtenstein",
        "LT": "Lituania",
        "LU": "Luxemburgo",
        "MK": "Macedonia, Ex-República Yugoslava de",
        "MG": "Madagascar",
        "MY": "Malasia",
        "MW": "Malawi",
        "MV": "Maldivas",
        "ML": "Malí",
        "MT": "Malta",
        "MA": "Marruecos",
        "MQ": "Martinica",
        "MU": "Mauricio",
        "MR": "Mauritania",
        "YT": "Mayotte",
        "MX": "México",
        "FM": "Micronesia",
        "MD": "Moldavia",
        "MC": "Mónaco",
        "MN": "Mongolia",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MZ": "Mozambique",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NI": "Nicaragua",
        "NE": "Níger",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolk",
        "NO": "Noruega",
        "NC": "Nueva Caledonia",
        "NZ": "Nueva Zelanda",
        "OM": "Omán",
        "NL": "Holanda",
        "PA": "Panamá",
        "PG": "Papúa Nueva Guinea",
        "PK": "Paquistán",
        "PY": "Paraguay",
        "PE": "Perú",
        "PN": "Pitcairn",
        "PF": "Polinesia Francesa",
        "PL": "Polonia",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "GB": "Reino Unido",
        "CF": "República Centroafricana",
        "CZ": "República Checa",
        "ZA": "República de Sudáfrica",
        "DO": "República Dominicana",
        "SK": "República Eslovaca",
        "RE": "Reunión",
        "RW": "Ruanda",
        "RO": "Rumania",
        "RU": "Rusia",
        "EH": "Sahara Occidental",
        "KN": "Saint Kitts y Nevis",
        "WS": "Samoa",
        "AS": "Samoa Americana",
        "SM": "San Marino",
        "VC": "San Vicente y Granadinas",
        "SH": "Santa Helena",
        "LC": "Santa Lucía",
        "ST": "Santo Tomé y Príncipe",
        "SN": "Senegal",
        "RS": "Serbia",
        "SC": "Seychelles",
        "SL": "Sierra Leona",
        "SG": "Singapur",
        "SY": "Siria",
        "SO": "Somalia",
        "LK": "Sri Lanka",
        "PM": "St. Pierre y Miquelon",
        "SZ": "Suazilandia",
        "SD": "Sudán",
        "SE": "Suecia",
        "CH": "Suiza",
        "SR": "Surinam",
        "TH": "Tailandia",
        "TW": "Taiwán",
        "TZ": "Tanzania",
        "TJ": "Tayikistán",
        "TF": "Territorios franceses del Sur",
        "TL": "Timor Oriental",
        "TG": "Togo",
        "TO": "Tonga",
        "TT": "Trinidad y Tobago",
        "TN": "Túnez",
        "TM": "Turkmenistán",
        "TR": "Turquía",
        "TV": "Tuvalu",
        "UA": "Ucrania",
        "UG": "Uganda",
        "UY": "Uruguay",
        "UZ": "Uzbekistán",
        "VU": "Vanuatu",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "YE": "Yemen",
        "YU": "Yugoslavia",
        "ZM": "Zambia",
        "ZW": "Zimbabue"
    }
};