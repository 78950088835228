import React from 'react';
import { components } from 'react-select';
import { findIndex,  isEqual, map } from 'lodash';
import { grey, blueGrey, amber } from 'material-colors/dist/colors';
import { VariableSizeList as List } from 'react-window';
import scrollbarSize from '../libs/scrollbarSize';
import { zIndexReactSelect } from '@biuwer/core/src/theme/scss/modules/_z_index.scss?export';
// import zIndexSassVariables from '@biuwer/core/src/theme/scss/modules/_z_index.scss?export';
import { baseFontFamily } from '@biuwer/core/src/theme/scss/_var.scss?export';
// import * as sassVariables from '@biuwer/core/src/theme/scss/_var.scss?export';

// const baseFontFamily = sassVariables.baseFontFamily
// const zIndexReactSelect = zIndexSassVariables.zIndexReactSelect

/** Aux Functions **/

/**
 * Function that calculates a div height for a given text and styles
 * @param text (string) - Text to measure
 * @param styleSettings (object) - Object with style configuration needed
 */
const getTextHeight = (text, styleSettings) => {

    // Function that creates a div with certain styles and measures it to obtain it's height
    let div = document.createElement('div');

    div.innerHTML = text;
    div.style = styleSettings;
    div.style.fontFamily = styleSettings.fontFamily;
    div.style.fontWeight = styleSettings.fontWeight;
    div.style.fontSize = styleSettings.fontSize;
    div.style.width = styleSettings.width;
    div.style.maxWidth = styleSettings.width;
    div.style.borderLeft = styleSettings.borderLeft;
    div.style.borderRight = styleSettings.borderRight;
    div.style.borderTop = styleSettings.borderTop;
    div.style.padding = styleSettings.padding;
    div.style.paddingTop = styleSettings.paddingTop;
    div.style.paddingBottom = styleSettings.paddingBottom;
    div.style.minHeight = styleSettings.minHeight;
    div.style.display = styleSettings.display;
    div.style.alignItems = styleSettings.alignItems;
    div.style.borderBottom = styleSettings.borderBottom;
    div.style.wordBreak = styleSettings.wordBreak;
    div.style.flexWrap = styleSettings.flexWrap;

    div.style.position = 'absolute';
    div.style.top = '-9999px';
    div.style.left = '-9999px';

    document.body.appendChild(div);
    let size = div.offsetHeight || 34;
    document.body.removeChild(div);

    return size;
};

/**
 * Function that returns item size for a windowed list
 * @param index (number) - Row index of the list
 * @param value (string) - Text to measure
 * @param domId (string) - Id of the parent Select Dom element
 * @param isMulti (boolean) - Boolean indicating if the select is multi valued
 * @param scrollBarShown (boolean) - Boolean indicating if scroll bar is shown in select options menu
 */
const getItemSize = (index, value, domId, isMulti, scrollBarShown) => {

    // Dynamic height can only be calculated when providing domId, if not provided return default height
    if (domId) {
        let rect =  document.getElementById(domId);
        let rectStyle = rect ? window.getComputedStyle(rect) : null;

        // Take scroll bar into account when applying width value
        let width = rectStyle ? parseFloat(rectStyle.width.replace('px', '')) : 0;
        if (scrollBarShown) {
            width -= scrollbarSize();
        }

        let cellStyle = {};

        if (rectStyle) {
            cellStyle.fontFamily = rectStyle.fontFamily;
            cellStyle.fontWeight = rectStyle.fontWeight;
            cellStyle.fontSize = rectStyle.fontSize;
            cellStyle.width = `${width}px`;
            cellStyle.lineHeight = rectStyle.lineHeight;
            cellStyle.verticalAlign = rectStyle.verticalAlign;
            cellStyle.wordWrap = rectStyle.wordWrap;
            cellStyle.whiteSpace = rectStyle.whiteSpace;
            cellStyle.textAlign = rectStyle.textAlign;
            cellStyle.flex = rectStyle.flex;
            cellStyle.textOverflow = rectStyle.textOverflow;
            cellStyle.borderLeft = rectStyle.borderLeft;
            cellStyle.borderRight = rectStyle.borderRight;
            cellStyle.borderTop = rectStyle.borderTop;
            cellStyle.borderBottom = rectStyle.borderBottom;
        }

        // Apply custom option styles
        cellStyle.padding = '3px';
        cellStyle.paddingTop = !isMulti ? '8px' : '0px';
        cellStyle.paddingBottom = !isMulti ? '8px' : '0px';
        cellStyle.minHeight = '34px';
        cellStyle.height = '100%';
        cellStyle.display = 'flex';
        cellStyle.flexWrap = 'wrap';
        cellStyle.alignItems = 'center';
        cellStyle.borderBottom = `1px solid ${grey[300]}`;
        cellStyle.wordBreak = 'break-word';

        let itemSize = getTextHeight(value, cellStyle);
        return itemSize > 34 ? itemSize : 34;

    } else {
        return 34;
    }
};


/** Custom Components **/

/**
 * Renders a custom Option component with checkboxes.
 * @param props (object) - Internal react-select props. See https://react-select.com/props
 */
export const CheckOption = props => {

    // Help with performance issues. See https://github.com/JedWatson/react-select/issues/3128#issuecomment-521242192
    const { innerProps, isFocused, ...otherProps } = props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    const newProps = { innerProps: { ...otherInnerProps }, ...otherProps};

    return (
        <components.Option {...newProps}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={()=>{}}
                    disabled={props.isDisabled ? "disabled" : null}
                    style={{ margin: '10px' }}
                />
                {props.children}
            </div>
        </components.Option>
    );
};

/**
 * Renders a custom single Option component.
 * @param props (object) - Internal react-select props. See https://react-select.com/props
 */
export const SingleOption = props => {

    // Help with performance issues. See https://github.com/JedWatson/react-select/issues/3128#issuecomment-521242192
    const { innerProps, isFocused, ...otherProps } = props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    const newProps = { innerProps: { ...otherInnerProps }, ...otherProps};

    return (
        <components.Option {...newProps}>
            <span>
                {props.children}
            </span>
        </components.Option>
    );
};

/**
 * Renders a custom SingleValue component with value tooltip
 * @param props (object) - Internal react-select props. See https://react-select.com/props
 */
export const SingleValue = props => {
    return (
        <components.SingleValue {...props}>
            <span title={props.children}>
                {props.children}
            </span>
        </components.SingleValue>
    );
};

/**
 * Renders a custom Placeholder component with value tooltip
 * @param props (object) - Internal react-select props. See https://react-select.com/props
 */
export const Placeholder = props => {
    return (
        <components.Placeholder {...props}>
            <span title={props.children}>
                {props.children}
            </span>
        </components.Placeholder>
    );
};

/**
 * Renders a custom  MultiValue component with a limit of selections before changing message
 * @param props (object) - Internal react-select props. See https://react-select.com/props
 * @param maxVisibleSelectedOptions (number) - Max number of selected values to show before changing to placeholder message
 * @param selectedOptionsMaxPlaceholder (string) - Placeholder message shown when more than max visible options have been selected
 * @param optionLabel (string) - Object key in options array with the value shown in options menu
 * @param allValuesPlaceHolder (string) - String to show when all values are selected
 */
export const MultiValueControl = (props, maxVisibleSelectedOptions, selectedOptionsMaxPlaceholder, optionLabel, allValuesPlaceHolder) => {

    let index = findIndex(props.selectProps.value, (value) => { return isEqual(value, props.data )});
    if (props.selectProps.value.length === props.selectProps.options.length && index === 0) {
        return (
            <components.MultiValueContainer {...props}>
                <span title={map(props.getValue(), optionLabel)}>
                    {allValuesPlaceHolder || selectedOptionsMaxPlaceholder}
                </span>
            </components.MultiValueContainer>
        );
    }
    else if (props.selectProps.value.length > maxVisibleSelectedOptions && index === 0) {
        return (
            <components.MultiValueContainer {...props}>
                <span title={map(props.getValue(), optionLabel)}>
                    {selectedOptionsMaxPlaceholder}
                </span>
            </components.MultiValueContainer>
        );
    } else if (props.selectProps.value.length <= maxVisibleSelectedOptions &&  props.selectProps.value.length !== props.selectProps.options.length) {

        let separator = index !== props.selectProps.value.length-1 ? ', ' : '';
        return (
            <components.MultiValueContainer {...props}>
                <div className="mr10"
                     title={map(props.getValue(), optionLabel)}
                     style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 'calc(100% - 1px)' }}
                >
                    {`${props.children}${separator}`}
                </div>
            </components.MultiValueContainer>
        );
    } else {
        return null;
    }
};

/**
 * Renders a custom  MenuList component using react-window
 * @param props (object) - Internal react-select props. See https://react-select.com/props
 */
export const VirtualizedMenuList = props => {

    // Calculate total height
    let height = 0;
    if (props.children && props.children.length > 0) {
        if (props.children.length < 10) {
            props.children.map((value, index) => height += getItemSize(index, value.props.children, props.selectProps.id, props.isMulti));
        } else {
            height = 5000;
        }
    }

    // Add border pixel in height
    height = height + 1;
    if (height > props.maxHeight) {
        height = props.maxHeight;
    }

    return (
        <List
            height={height}
            itemCount={props.children.length || 0}
            itemSize={(index) =>
                getItemSize(
                    index,
                    props && props.children && props.children[index] && props.children[index].props && props.children[index].props.children ? props.children[index].props.children : '',
                    props && props.selectProps && props.selectProps.id && props.children.length < 5000 ? props.selectProps.id : null,
                    props.isMulti,
                    height + 1 > props.maxHeight
                )}
            style={{
                border: `1px solid ${grey[300]}`,
                borderTop: 'none',
                paddingTop: 0,
                paddingBottom: 0
            }}
        >
            {({ index, style }) =>
                <div style={style}>
                    {props.children[index]}
                </div>
            }
        </List>
    )
};

/**
 * Default components used in multi valued select
 * @param maxVisibleSelectedOptions (number) - Max number of selected values to show before changing to placeholder message
 * @param selectedOptionsMaxPlaceholder (string) - Placeholder message shown when more than max visible options have been selected
 * @param optionLabel (string) - Object key in options array with the value shown in options menu
 * @param allValuesPlaceHolder (string) - String to show when all values are selected
 */
export const biuwerMultiValueComponents = (maxVisibleSelectedOptions = 4, selectedOptionsMaxPlaceholder = 'Several values selected', optionLabel = 'name', allValuesPlaceHolder) => {
    return {
        Option: CheckOption,
        MultiValue: (props) => MultiValueControl(props, maxVisibleSelectedOptions, selectedOptionsMaxPlaceholder, optionLabel, allValuesPlaceHolder),
        Placeholder: Placeholder
    };
};

/**
 * Default components used in multi valued select with large amounts of data. Uses react-window.
 * @param maxVisibleSelectedOptions (number) - Max number of selected values to show before changing to placeholder message
 * @param selectedOptionsMaxPlaceholder (string) - Placeholder message shown when more than max visible options have been selected
 * @param optionLabel (string) - Object key in options array with the value shown in options menu
 * @param allValuesPlaceHolder (string) - String to show when all values are selected
 */
export const biuwerMultiValueWindowedComponents = (maxVisibleSelectedOptions = 4, selectedOptionsMaxPlaceholder = 'Several values selected', optionLabel = 'name', allValuesPlaceHolder = "All values") => {
    return {
        Option: CheckOption,
        MultiValue: (props) => MultiValueControl(props, maxVisibleSelectedOptions, selectedOptionsMaxPlaceholder, optionLabel, allValuesPlaceHolder),
        Placeholder: Placeholder,
        MenuList: VirtualizedMenuList
    };
};

/**
 * Default components used in single valued select.
 */
export const biuwerSingleValueComponents = {
    Option: SingleOption,
    SingleValue: SingleValue,
    Placeholder: Placeholder
};

/**
 * Default components used in single valued select with large amounts of data. Uses react-window.
 */
export const biuwerSingleValueWindowedComponents = {
    Option: SingleOption,
    SingleValue: SingleValue,
    Placeholder: Placeholder,
    MenuList: VirtualizedMenuList
};

/**
 * Default Biuwer styles
 */
const defaultHeight = 34;
export const defaultBiuwerStyles = {
    container: style => (
        {
            ...style,
            border: 'none',
            width: '100%'
        }
    ),
    control: (style, state) => (
        {
            ...style,
            height: defaultHeight,
            minHeight: defaultHeight,
            borderRadius: 0,
            borderColor: grey[300],
            borderWidth: 1,
            boxShadow: 'none',
            "&:hover": {
                borderColor: grey[300]
            }
        }
    ),
    indicatorsContainer: style => (
        { ...style,
            minHeight: defaultHeight,
            height: defaultHeight
        }
    ),
    placeholder: style => (
        {
            ...style,
            flexWrap: 'nowrap',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            paddingRight: 10
        }
    ),
    valueContainer: style => (
        {
            ...style,
            flexWrap: 'nowrap',
            border: 'none',
            minHeight: defaultHeight,
            height: defaultHeight
        }
    ),
    singleValue: style => (
        {
            ...style,
            paddingRight: 10
        }
    ),
    multiValue: style => (
        {
            ...style,
            backgroundColor: 'transparent'
        }
    ),
    multiValueLabel: style => (
        {
            ...style,
            marginRight: 10
        }
    ),
    menu: (style, state) => (
        {
            ...style,
            marginTop: 0,
            marginBottom: -1,
            borderRadius: 0,
            boxShadow: 'none',
            zIndex: zIndexReactSelect,
            borderTop: state.placement === 'top' ? `1px solid ${grey[300]}` : 'none'
        }
    ),
    menuList: (style) => (
        {
            ...style,
            border: `1px solid ${grey[300]}`,
            borderTop: 'none',
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: zIndexReactSelect
        }
    ),
    menuPortal: style => (
        {
            ...style,
            fontFamily: baseFontFamily,
            zIndex: zIndexReactSelect
        }
    ),
    option: (style, state) => (
        {
            ...style,
            padding: 3,
            paddingTop: !state.isMulti ? 8 : 0,
            paddingBottom: !state.isMulti ? 8 : 0,
            paddingLeft: !state.isMulti ? 8 : 0,
            minHeight: defaultHeight,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            borderBottom: findIndex(state.options, (option) => { return isEqual(option, state.data) }) === state.options.length -1 ? 'none' : `1px solid ${grey[300]}`,
            color: state.isSelected ? blueGrey[800] : style.color,
            backgroundColor: state.isFocused && !state.isSelected ? amber[500] : style.backgroundColor,
            cursor: 'pointer',
            wordBreak: 'break-word',
            "&:hover": {
                backgroundColor: !state.isSelected ? amber[500] : style.backgroundColor
            },
            zIndex: zIndexReactSelect
        }
    )
};

/**
 * Default Biuwer theme
 */
export const defaultBiuwerTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: grey[200],
        primary75: grey[100],
        primary50: grey[50],
        primary25: grey[50]
    }
});

export default {
    CheckOption,
    MultiValueControl,
    biuwerMultiValueComponents,
    biuwerMultiValueWindowedComponents,
    biuwerSingleValueComponents,
    biuwerSingleValueWindowedComponents,
    defaultBiuwerStyles,
    defaultBiuwerTheme
};