import { useDispatch } from "react-redux";

/*
 * Data card editor action types
 */

export const INITIALIZE_IDS = "INITIALIZE_IDS";
export const IDS_SET_CONTENT_FILTERS = 'IDS_SET_CONTENT_FILTERS';
export const IDS_CLEAN_CONTENT_FILTERS = 'IDS_CLEAN_CONTENT_FILTERS';
export const IDS_SET_CONTENT_SORT = 'IDS_SET_CONTENT_SORT';

// General data card editor actions
const initializeIDS = () => dispatch => dispatch({ type: INITIALIZE_IDS });
const setContentFilters = ({ cardId, pageId, filters }) => dispatch => dispatch({ type: IDS_SET_CONTENT_FILTERS, cardId, pageId, filters });
const cleanContentFilters = ({ contentId }) => dispatch => dispatch({ type: IDS_CLEAN_CONTENT_FILTERS, contentId });
const setContentSort = (cardId, sort) => dispatch => dispatch({ type: IDS_SET_CONTENT_SORT, cardId, sort });

export function useIDSActions() {

    const dispatch = useDispatch();

    return {
        initializeIDS: () => dispatch(initializeIDS()),
        setContentFilters: ({ cardId, pageId, filters }) => dispatch(setContentFilters({ cardId, pageId, filters })),
        cleanContentFilters: ({ contentId }) => dispatch(cleanContentFilters({ contentId })),
        setContentSort: (cardId, sort) => dispatch(setContentSort(cardId, sort)),
    };
}
